import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AimoRoutingService } from './features/cms-structure/routing/aimo-routing.service';
import { AimoHamburgerMenuService } from './features/layout/hamburger-menu/aimo-hamburger-menu.service';
import { AimoGtmService } from './features/service/gtm/aimo-gtm.service';
import { AimoWebextendService } from './features/service/webextend/aimo-webextend.service';

@Component({
    selector: 'aimo-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
    title: string = 'Aimo';
    isExpanded$: Observable<boolean>;

    constructor(
        private hamburgerMenuService: AimoHamburgerMenuService,
        protected aimoRoutingService: AimoRoutingService,
        protected gtmService: AimoGtmService,
        protected aimoWebextendService: AimoWebextendService,
        protected activatedRoute: ActivatedRoute,
    ) {
        this.isExpanded$ = this.hamburgerMenuService.isExpanded;
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    ngAfterViewInit() {
        const gtmUrlParam = this.activatedRoute.snapshot.queryParamMap.get('_gl');
        this.gtmService.addToDom(gtmUrlParam);
        this.aimoWebextendService.initWebextend();
    }
}

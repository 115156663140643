import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { RoutingService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AimoOrderEntry } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { GTMCalendarSource } from '../../../service/gtm/aimo-gtm.model';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { DateUtils } from '../../../shared/util/date-utils';
import { AimoClaimsDialogData } from '../../claims/claims-modal/aimo-claims-layout.config';

import { AimoSwitchDateHistoryDialogData } from './aimo-switch-cart-date-layout.config';

@Component({
    selector: 'aimo-switch-cart-date',
    templateUrl: './aimo-switch-cart-date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderHistorySwitchDateComponent implements OnDestroy {
    calendarSource: GTMCalendarSource;
    subscription: Subscription = new Subscription();
    selectedDate: string;
    entries: AimoOrderEntry[];
    allowPastDates: boolean;
    orderTemplateCode: string;
    createClaim: boolean;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected userService: AimoUserService,
    ) {
        this.subscription.add(
            this.launchDialogService.data$.subscribe((dialogData: AimoSwitchDateHistoryDialogData) => {
                this.allowPastDates = dialogData.allowPastDates;
                this.orderTemplateCode = dialogData.orderTemplateCode;
                this.createClaim = dialogData.createClaim;
                this.calendarSource = dialogData.calendarSource;
                this.entries = dialogData.cart?.dayGroupedEntries
                    ?.filter((e) => e.active)
                    .flatMap((e) => e.entries)
                    .map((e) => ({ entryNumber: e.entryNumber }));
            }),
        );
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.SWITCH_CART_DATE);
        this.launchDialogService.closeDialog('closed');
    }

    selectDate(date: string): void {
        this.selectedDate = date;
    }

    submit(): void {
        if (this.createClaim) {
            if (this.selectedDate) {
                this.openCreateClaim();
            }
        } else {
            if (this.orderTemplateCode) {
                this.activeCartService.orderTemplateImport(this.orderTemplateCode, this.selectedDate);
            } else {
                this.activeCartService.updateCartHeader({
                    requestedDeliveryDate: this.selectedDate,
                    entries: this.entries,
                });
            }
            const sub = this.actions$.subscribe((action) => {
                if (action.type === CartActions.LOAD_CART_SUCCESS) {
                    this.closeDialog();
                    this.routingService.go('/cart');
                    sub.unsubscribe();
                }
            });
        }
    }

    private openCreateClaim(): void {
        const date = this.convertDate(this.selectedDate);
        this.closeDialog();
        this.userService
            .getOrderHistoryDay(date)
            .pipe(take(1))
            .subscribe((orders) =>
                this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CLAIMS, undefined, {
                    day: orders.dayGroupedOrders[0],
                    editable: true,
                } as AimoClaimsDialogData),
            );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    @ViewChild('closeButton')
    closeButton: ElementRef;

    getTheFocusBack(event: Event): void {
        event.preventDefault();
        this.closeButton.nativeElement.focus();
    }

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    submitDisabled(): boolean {
        return this.createClaim === true && this.selectedDate === undefined;
    }
}

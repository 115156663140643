import { Injectable } from '@angular/core';
import { withdrawOn } from '@spartacus/core';
import { UserAccountConnector } from '@spartacus/user/account/core';
import { User } from '@spartacus/user/account/root';
import { Observable, Subject ,tap} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AimoUserAccountConnector extends UserAccountConnector {
    refreshUser$: Subject<void> = new Subject<void>();

    get(userId: string): Observable<User> {
        return this.adapter.load(userId)
            .pipe(
                withdrawOn(this.refreshUser$),
            );
    }
}

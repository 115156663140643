import { Injectable, OnDestroy } from '@angular/core';
import { ProductScope, RoutingService } from '@spartacus/core';
import { CurrentProductService, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { AimoProductSummaryDialogData } from '../../cms-components/product/product-summary/product-summary-layout.config';
import { AimoProduct } from '../../model/product.model';
import { AimoGTMProductAttributes, GTMEventCode } from '../gtm/aimo-gtm.model';

import { AimoProductService } from './aimo-product.service';

@Injectable({
    providedIn: 'root',
})
export class AimoCurrentProductService extends CurrentProductService implements OnDestroy {
    product$ = new BehaviorSubject<AimoProductLoading>(new AimoProductLoading(true, null));
    subscription: Subscription = new Subscription();

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected lRoutingService: RoutingService,
        protected lProductService: AimoProductService,
    ) {
        super(lRoutingService, lProductService);
        this.subscription.add(
            this.lRoutingService
                .getRouterState()
                .subscribe((state) =>
                    this.product$.next(new AimoProductLoading(false, state.state.params['productCode'])),
                ),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getProduct(scopes?: (ProductScope | string)[] | ProductScope | string): Observable<AimoProduct> {
        return this.getProduct2();
    }

    getProduct2(gtmProductAttributes?: AimoGTMProductAttributes): Observable<AimoProduct> {
        return this.product$.pipe(
            filter((wrapper) => wrapper && wrapper.code !== undefined),
            distinctUntilChanged(),
            switchMap((wrapper: AimoProductLoading) => {
                //    if (!wrapper.loading) {
                wrapper.loading = true;
                return this.lProductService.getAimoProduct(wrapper.code, gtmProductAttributes).pipe(
                    tap(() => (wrapper.loading = false)),
                    tap((product) =>
                        setTimeout(() => {
                            if (gtmProductAttributes) {
                                // make sure that this one is fired last
                                this.lProductService.fireSingleProductGtmEvent(
                                    product,
                                    GTMEventCode.ViewItem,
                                    gtmProductAttributes,
                                );
                            }
                        }, 200),
                    ),
                );
                //   }
                // return of({} as AimoProduct);
            }),
        );
    }

    public openProductModal(
        productCode: string,
        gtmProductAttributes: AimoGTMProductAttributes,
        scrollToId?: string,
        addToCartCallBack?: BehaviorSubject<number>,
        openModalAfterClose?: LAUNCH_CALLER,
        focusIdAfterClose?: string,
    ): void {
        this.launchDialogService.closeDialog(null);
        this.product$.next(new AimoProductLoading(false, productCode));
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.PRODUCT_SUMMARY, undefined, {
            addToCartCallBack: addToCartCallBack,
            openModalAfterClose: openModalAfterClose,
            product: this.getProduct2(gtmProductAttributes).pipe(
                tap((product) =>
                    this.lProductService.fireSingleProductGtmEvent(
                        product,
                        GTMEventCode.SelectItem,
                        gtmProductAttributes,
                        'similar-products-container' === scrollToId,
                    ),
                ),
            ),
            scrollToId: scrollToId,
            focusIdAfterClose: focusIdAfterClose,
            klevuParams: gtmProductAttributes?.klevuParams,
        } as AimoProductSummaryDialogData);
    }
}

class AimoProductLoading {
    constructor(
        public loading: boolean,
        public code: string,
    ) {}
}

import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalMessageService } from '@spartacus/core';
import { UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { UserProfileFacade } from '@spartacus/user/profile/root';

import { AimoUser } from '../../../../model/user.model';
import { AimoCustomFormValidators } from '../../../../shared/validators/aimo-custom-form-validators';

@Injectable({ providedIn: 'root' })
export class AimoUpdateProfileComponentService extends UpdateProfileComponentService {

    constructor(
        protected userProfile: UserProfileFacade,
        protected globalMessageService: GlobalMessageService,
    ) {
        super(userProfile, globalMessageService);
    }

    initForm(user: AimoUser): UntypedFormGroup {
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl(user.name, Validators.required),
            email: new UntypedFormControl(user.email, [Validators.required, AimoCustomFormValidators.emailValidator]),
            phone: new UntypedFormControl(user.phone, [
                Validators.required,
                AimoCustomFormValidators.phoneNumberValidator,
            ]),
            uid: new UntypedFormControl(user.uid),
            password: new UntypedFormControl(''),
            orderConfirmationsInEmail: new UntypedFormControl(user.orderConfirmationsInEmail),
        });
        return this.form;
    }
}

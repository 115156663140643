import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Address } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';

import { AimoUser } from '../../../model/user.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { SELECT_UNIT_SUCCESS } from '../../../service/user/aimo-user.action';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { openCloseSpinner } from '../../shared/utils/spinner/aimo-spinner-utils';

@Component({
    selector: 'aimo-cx-customer-selection-component',
    templateUrl: './aimo-customer-selection.component.html',
})
export class AimoCustomerSelectionComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    currentUser$: Observable<AimoUser>;
    selectedShipToAddress: Address;
    visible = false;

    constructor(
        protected userService: AimoUserService,
        protected activeCartService: AimoActiveCartService,
        protected cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        protected actions$: Actions,
    ) {}

    ngOnInit(): void {
        this.currentUser$ = this.userService.get();
        this.subscription.add(
            this.activeCartService.getActive().subscribe((cart) => {
                this.selectedShipToAddress = cart.deliveryAddress;
                this.cdr.detectChanges();
            }),
        );
        this.subscription.add(
            this.actions$.subscribe((action) => {
                if (action.type === SELECT_UNIT_SUCCESS) {
                    this.visible = false;
                    this.cdr.detectChanges();
                    this.document.body.click(); //force popup close
                    //  this.winRef.location.reload();
                    openCloseSpinner(this.document, false);
                }
            }),
        );
    }

    selectUnit(uid: string, user: AimoUser): void {
        if (uid !== user.unit.uid) {
            this.userService.selectUnit(uid, false);
        }
        this.toggleVisible();
    }

    toggleVisible(): void {
        this.visible = !this.visible;
        if (this.visible) {
            // focus to customer searchbox
            setTimeout(() => {
                // this will make the execution after the above boolean has changed
                this.setFocus(0);
                this.document.getElementById('customer_search')?.focus();
            }, 0);
        }
    }

    // clickout logic
    @HostListener('document:click', ['$event'])
    clickout(event): void {
        const parentElement = (<HTMLElement>event.target)?.closest('cx-page-slot.SelectUnits');
        if (!parentElement) {
            this.closeDropdown();
        }
    }

    closeDropdown(): void {
        this.visible = false;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    nextFocus(event: KeyboardEvent, index: number, size: number): void {
        event.preventDefault();
        if (index < size - 1) {
            this.setFocus(index + 1);
        }
    }

    previousFocus(event: KeyboardEvent, index: number): void {
        event.preventDefault();
        if (index > 0) {
            this.setFocus(index - 1);
        }
    }

    private setFocus(index: number): void {
        this.document.getElementById('customer_select_' + index)?.focus();
    }
}

<ng-container *ngIf="result?.pagination && form">
    <div class="results" id="results" role="dialog">
        <div class="row no-gutters sticky-result-top">
            <div class="col-lg-5 col-6 text-bold">
                <div class="row no-gutters">
                    <div class="col-10">
                        {{ 'aimo.cart.item.product' | cxTranslate }}
                    </div>
                    <div class="col-2"></div>
                </div>
            </div>
            <div class="col-lg-7 col-6">
                <div class="row no-gutters align-items-center">
                    <div class="col-2 pr-0 pr-lg-2 pr-lg-0">
                        <ng-container *ngIf="isLoggedInUser$ | async">
                            <div class="text-bold">{{ 'aimo.cart.item.basePrice' | cxTranslate }}</div>
                            <div>{{ 'aimo.cart.item.notax' | cxTranslate }}</div>
                        </ng-container>
                    </div>
                    <div class="col-2 pr-0 pr-lg-2 pr-lg-0 mr-lg-0">
                        <ng-container *ngIf="isLoggedInUser$ | async">
                            <div class="text-bold">{{ 'aimo.cart.item.comparisonPrice' | cxTranslate }}</div>
                            <div>{{ 'aimo.cart.item.notax' | cxTranslate }}</div>
                        </ng-container>
                    </div>
                    <div class="pr-0 pr-lg-2 pr-lg-0 text-bold" [ngClass]="isPikatukku() ? 'col-4' : 'col-2'">
                        {{ 'aimo.cart.item.availability' | cxTranslate }}
                    </div>
                    <div class="col-2 pr-0 pr-lg-2 pr-lg-0 text-bold" [ngClass]="isPikatukku() ? 'd-none' : ''">
                        {{ 'aimo.product.closingTime' | cxTranslate }}
                    </div>
                    <div class="col-12 col-lg-4 text-bold">
                        {{ 'aimo.cart.item.quantity' | cxTranslate }}
                    </div>
                </div>
            </div>
        </div>
        <div
            class="sticky-result-content"
            [ngClass]="result.pagination.fullAssortmentTotalResults ? 'assortment-total-results' : ''">
            <div
                class="content-scroll"
                [ngClass]="isSmallerScrollArea() ? 'content-scroll-smaller' : ''"
                #resultContainer
                infinite-scroll
                [infiniteScrollContainer]="resultContainer"
                [infiniteScrollDistance]="2"
                [infiniteScrollDisabled]="stopScrolling()"
                (scrolled)="scrollResults()">
                <ul
                    class="products"
                    *ngIf="result?.products"
                    attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}"
                    role="listbox">
                    <li
                        class="suggestive-item"
                        id="suggestive-item-{{ idx }}"
                        *ngFor="let product of result.products; index as idx">
                        <div
                            role="option"
                            (keydown.arrowup)="focusPreviousChild($any($event))"
                            (keydown.arrowdown)="focusNextChild($any($event))"
                            (keydown.enter)="close($any($event), true)"
                            (keydown.escape)="close($any($event), true)"
                            (keydown.tab)="focusNextChild($any($event))"
                            (blur)="close($any($event))"
                            class="row no-gutters align-items-center">
                            <div class="name col-lg-5 col-12">
                                <div class="row no-gutters align-items-center">
                                    <div class="col-2 pr-2 position-static">
                                        <div
                                            class="cx-product-image-container cursor-pointer"
                                            role="button"
                                            [tabIndex]="0">
                                            <aimo-product-tag [product]="product" />
                                            <cx-media
                                                [id]="isCartSearchBox + '_suggestive_product_image_' + idx"
                                                (click)="
                                                    openProductModal(
                                                        product,
                                                        idx,
                                                        isCartSearchBox + '_suggestive_product_image_' + idx
                                                    )
                                                "
                                                class="cx-product-image"
                                                [container]="product.images?.PRIMARY"
                                                format="thumbnail"
                                                role="presentation"></cx-media>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-10">
                                        <div
                                            class="product-name cursor-pointer"
                                            [id]="isCartSearchBox + '_suggestive_product_name_' + idx"
                                            (click)="
                                                openProductModal(
                                                    product,
                                                    idx,
                                                    isCartSearchBox + '_suggestive_product_name_' + idx
                                                )
                                            "
                                            [innerHTML]="product.nameHtml"></div>
                                        <div class="pt-1">
                                            <aimo-product-code
                                                [klass]="'text-default'"
                                                [product]="product"></aimo-product-code>

                                            <div class="pl-2 d-inline-block d-lg-none product-actions">
                                                <i
                                                    *ngIf="product.favorite"
                                                    class="aimo-icon icon-sm icon-inline icon-dark-grey icon-star-filled"
                                                    triggers="mouseenter:mouseleave"
                                                    placement="bottom"
                                                    container="body"
                                                    [ngbPopover]="'aimo.favorite.added' | cxTranslate"></i>

                                                <i
                                                    class="aimo-icon icon-sm icon-file-text icon-inline icon-dark-grey"
                                                    *ngIf="product.inOrderTemplate"
                                                    triggers="mouseenter:mouseleave"
                                                    placement="bottom"
                                                    container="body"
                                                    [ngbPopover]="'aimo.product.inTemplate' | cxTranslate"></i>

                                                <i
                                                    class="aimo-icon icon-sm icon-clock-counter-clockwise icon-inline icon-dark-grey"
                                                    *ngIf="product.lastPurchased"
                                                    triggers="mouseenter:mouseleave click"
                                                    placement="bottom"
                                                    container="body"
                                                    [ngbPopover]="
                                                        'aimo.product.lastPurchased'
                                                            | cxTranslate
                                                                : {
                                                                      date:
                                                                          (convertDate(product.lastPurchased)
                                                                          | cxDate: ('aimo.dateFormat' | cxTranslate)),
                                                                  }
                                                    "></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 d-none d-lg-block product-actions">
                                        <i
                                            *ngIf="product.favorite"
                                            class="aimo-icon icon-sm icon-inline icon-dark-grey icon-star-filled"
                                            triggers="mouseenter:mouseleave"
                                            placement="bottom"
                                            container="body"
                                            [ngbPopover]="'aimo.favorite.added' | cxTranslate"></i>

                                        <i
                                            class="aimo-icon icon-sm icon-file-text icon-inline icon-dark-grey"
                                            *ngIf="product.inOrderTemplate"
                                            triggers="mouseenter:mouseleave"
                                            placement="bottom"
                                            container="body"
                                            [ngbPopover]="'aimo.product.inTemplate' | cxTranslate"></i>

                                        <!--suppress TypeScriptUnresolvedReference -->
                                        <i
                                            class="aimo-icon icon-sm icon-clock-counter-clockwise icon-inline icon-dark-grey"
                                            *ngIf="product.lastPurchased"
                                            triggers="mouseenter:mouseleave click"
                                            placement="bottom"
                                            container="body"
                                            [ngbPopover]="
                                                'aimo.product.lastPurchased'
                                                    | cxTranslate
                                                        : {
                                                              date:
                                                                  (convertDate(product.lastPurchased)
                                                                  | cxDate: ('aimo.dateFormat' | cxTranslate)),
                                                          }
                                            "></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-7 col-12 pt-2 pt-lg-0 mt-1 mt-lg-0">
                                <div class="row no-gutters align-items-center">
                                    <div class="price col-lg-2 col-6">
                                        <ng-container *ngIf="isLoggedInUser$ | async">
                                            <div
                                                class="ml-1 mr-1"
                                                [ngClass]="
                                                    product.strikeThroughPrice
                                                        ? 'promo-price'
                                                        : product.myAssortment
                                                          ? 'my-assortment-price'
                                                          : ''
                                                ">
                                                <i
                                                    *ngIf="product.myAssortment"
                                                    placement="bottom"
                                                    container="body"
                                                    triggers="mouseenter:mouseleave"
                                                    [ngbPopover]="'aimo.product.myAssortment' | cxTranslate"
                                                    class="aimo-icon icon-inline icon-sm icon-house-line-bold icon-petrol-blue"
                                                    [ngClass]="product.strikeThroughPrice ? 'icon-red' : ''"></i>
                                                <span class="d-inline-block mr-1 price-value text-bold">
                                                    {{ product.price?.formattedValue }}
                                                </span>
                                                <span class="d-inline-block mr-1">
                                                    / {{ product.unit?.name | lowercase }}
                                                </span>
                                                <span class="d-inline-block d-lg-none text-default">
                                                    {{ 'aimo.cart.item.notax' | cxTranslate }}
                                                </span>
                                            </div>

                                            <div
                                                class="d-block d-lg-none pt-1 ml-1 mr-1"
                                                *ngIf="product.comparisonPrice">
                                                <span class="d-inline-block mr-1">
                                                    {{ product.comparisonPrice.formattedValue }}
                                                </span>
                                                <span class="d-inline-block mr-1">
                                                    / {{ product.comparisonUnit.name | lowercase }}
                                                </span>
                                                <span class="d-inline-block d-lg-none">
                                                    {{ 'aimo.cart.item.notax' | cxTranslate }}
                                                </span>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="col-2 d-none d-lg-block">
                                        <ng-container *ngIf="isLoggedInUser$ | async">
                                            <div class="ml-1 mr-1" *ngIf="product.comparisonPrice">
                                                <span class="d-inline-block mr-1">
                                                    {{ product.comparisonPrice.formattedValue }}
                                                </span>
                                                <span class="d-inline-block mr-1">
                                                    / {{ product.comparisonUnit.name | lowercase }}
                                                </span>
                                                <span class="d-inline-block d-lg-none">
                                                    {{ 'aimo.cart.item.notax' | cxTranslate }}
                                                </span>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div
                                        class="d-none d-lg-block"
                                        [ngClass]="isPikatukku() ? 'col-5 col-lg-4' : 'col-2'">
                                        <div class="cx-stock">
                                            <aimo-product-availability
                                                [suggestiveMode]="true"
                                                [stock]="product.stock"
                                                *ngIf="!isPikatukku()"></aimo-product-availability>
                                            <ng-container *ngIf="isPikatukku()">
                                                <div *ngFor="let ptstock of product.pikatukkuStock" class="cx-stock">
                                                    <aimo-product-availability
                                                        [suggestiveMode]="true"
                                                        [printStockName]="true"
                                                        [stock]="ptstock">
                                                    </aimo-product-availability>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <button
                                            class="btn-link"
                                            *ngIf="
                                                product.stock.stockLevelStatus === 'outOfStock' &&
                                                product.similarProducts
                                            "
                                            [id]="isCartSearchBox + '_suggestive_product_similar_' + idx"
                                            (click)="
                                                openProductModal(
                                                    product,
                                                    idx,
                                                    isCartSearchBox + '_suggestive_product_similar_' + idx,
                                                    'similar-products-container'
                                                )
                                            ">
                                            <span class="text-underline">{{
                                                'aimo.product.lookSimilarProductsShort' | cxTranslate
                                            }}</span>
                                        </button>
                                    </div>

                                    <div class="col-2" [ngClass]="isPikatukku() ? 'd-none' : 'd-none d-lg-block'">
                                        <ng-container *ngIf="product.stock.closingTime">
                                            <i
                                                placement="bottom"
                                                container="body"
                                                triggers="mouseenter:mouseleave"
                                                [ngbPopover]="'aimo.product.closingTime' | cxTranslate"
                                                class="aimo-icon icon-sm icon-clock icon-inline icon-dark-grey"></i>
                                            <span class="ml-1">{{ product.stock.closingTime | cxDate: 'HH:mm' }}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-6 col-lg-4">
                                        <div class="row no-gutters align-items-center">
                                            <div
                                                class="col-9 col-sm-5 col-lg-8 order-last order-lg-first text-right text-lg-left">
                                                <aimo-item-counter
                                                    *ngIf="
                                                        (!product.pikatukkuWebShopProduct && isPikatukku()) ||
                                                        product.purchasable ||
                                                        orderTemplate
                                                    "
                                                    [readonly]="!product.purchasable && !orderTemplate && !baseOrder"
                                                    [ngbPopover]="
                                                        !product.purchasable
                                                            ? ('addToCart.notAvailableToOrder' | cxTranslate)
                                                            : ''
                                                    "
                                                    triggers="mouseenter:mouseleave"
                                                    popoverClass="popover-default popover-hide-mobile"
                                                    [id]="'suggestive_result_' + idx"
                                                    class="cart-item-counter d-inline-flex"
                                                    [showSpinner]="false"
                                                    [step]="product.allowedLotSize"
                                                    [min]="0"
                                                    [max]="getMaxQuantity(product)"
                                                    [doNotUpdateCart]="true"
                                                    [control]="getQuantityControl(product)"
                                                    [unitName]="product.unit.name"
                                                    [product]="product"
                                                    [gtmProductAttributes]="createGtmAttributes(idx)"
                                                    [cartCounter]="true"
                                                    (afterChange)="changeQuantity($event, product)"></aimo-item-counter>
                                                <span class="web-shop-product" *ngIf="product.pikatukkuWebShopProduct">
                                                    {{ 'aimo.product.shopProduct' | cxTranslate }}
                                                </span>
                                            </div>
                                            <div
                                                class="col-3 col-lg-4 offset-sm-4 offset-lg-0 text-right text-lg-left pl-lg-2 pr-1 pr-lg-0">
                                                <span
                                                    *ngIf="
                                                        product.sizeInBaseUnits !== 1 &&
                                                        calculateSizeInBaseUnits(product) > 0
                                                    ">
                                                    {{ calculateSizeInBaseUnits(product) }}
                                                    {{ product.baseUnit.name | lowercase }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-block d-lg-none pt-3">
                                        <div class="row no-gutters">
                                            <div class="col-6">
                                                <div class="cx-stock">
                                                    <aimo-product-availability
                                                        [suggestiveMode]="true"
                                                        [stock]="product.stock"
                                                        *ngIf="!isPikatukku()"></aimo-product-availability>
                                                    <ng-container *ngIf="isPikatukku()">
                                                        <div
                                                            *ngFor="let ptstock of product.pikatukkuStock"
                                                            class="cx-stock">
                                                            <aimo-product-availability
                                                                [suggestiveMode]="true"
                                                                [printStockName]="true"
                                                                [stock]="ptstock">
                                                            </aimo-product-availability>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <div class="col-6 text-right">
                                                <button
                                                    class="btn-link"
                                                    *ngIf="
                                                        product.stock.stockLevelStatus === 'outOfStock' &&
                                                        product.similarProducts
                                                    "
                                                    [id]="isCartSearchBox + '_suggestive_product_similar_mobile_' + idx"
                                                    (click)="
                                                        openProductModal(
                                                            product,
                                                            idx,
                                                            isCartSearchBox +
                                                                '_suggestive_product_similar_mobile_' +
                                                                idx,
                                                            'similar-products-container'
                                                        )
                                                    ">
                                                    <span class="text-underline">{{
                                                        'aimo.product.lookSimilarProductsShort' | cxTranslate
                                                    }}</span>
                                                </button>
                                                <ng-container *ngIf="product.stock.closingTime">
                                                    <i
                                                        placement="bottom"
                                                        container="body"
                                                        triggers="mouseenter:mouseleave"
                                                        [ngbPopover]="'aimo.product.closingTime' | cxTranslate"
                                                        class="aimo-icon icon-sm icon-clock icon-inline icon-dark-grey mb-1"></i>
                                                    <span class="ml-1">
                                                        {{
                                                            'productDetails.closeAt'
                                                                | cxTranslate
                                                                    : {
                                                                          time:
                                                                              (convertDate(product.stock?.closingTime)
                                                                              | cxDate: 'HH:mm'),
                                                                      }
                                                        }}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="row no-gutters sticky-result-bottom"
            [ngClass]="[
                result.pagination.fullAssortmentTotalResults ? 'assortment-total-results' : '',
                isSmallerScrollArea() ? 'more-padding' : '',
            ]">
            <div class="col-12">
                <span id="initialDescription" class="cx-visually-hidden">
                    {{ 'searchBox.initialDescription' | cxTranslate }}
                </span>
                <div
                    *ngIf="result?.suggestions?.length || result?.products?.length"
                    aria-live="assertive"
                    class="cx-visually-hidden">
                    {{ 'searchBox.suggestionsResult' | cxTranslate: { count: result.suggestions?.length } }}
                    {{ 'searchBox.productsResult' | cxTranslate: { count: result.products?.length } }}
                    {{ 'searchBox.initialDescription' | cxTranslate }}
                </div>
            </div>
            <div
                class="col-lg-auto col-12"
                [ngClass]="result.pagination.fullAssortmentTotalResults ? 'mb-2 mb-lg-0' : 'd-none d-lg-block'">
                {{ 'aimo.product.resultsFound' | cxTranslate: { results: result.pagination.totalResults } }}
                <ng-container *ngIf="result.pagination.fullAssortmentTotalResults">
                    (<!-- empty space -->{{
                        'aimo.product.fullAssortmentTotalResultsFound'
                            | cxTranslate: { results: result.pagination.fullAssortmentTotalResults }
                    }}
                    <button class="link text-underline" (click)="searchWithFullAssortment($event)">
                        {{ 'aimo.product.showFullAssortmentResults' | cxTranslate }}</button
                    ><!-- empty space -->)
                </ng-container>
                <button
                    class="link text-underline"
                    (click)="navigateToKeyword(result.keywordRedirectUrl)"
                    *ngIf="result.keywordRedirectUrl">
                    {{ 'aimo.product.navigateToKeyword' | cxTranslate }}
                </button>
            </div>
            <div class="col-lg-auto col-12 ml-lg-auto">
                <button class="btn btn-secondary btn-sm" (click)="showAll()">
                    {{ 'aimo.product.allResults' | cxTranslate }}
                </button>
                <button
                    class="btn btn-primary btn-sm ml-lg-2"
                    (click)="addAllProducts()"
                    *ngIf="isLoggedInUser$ | async">
                    {{
                        (cartId
                            ? baseOrder
                                ? 'aimo.product.addToBaseOrder'
                                : 'aimo.product.addToTemplate'
                            : 'aimo.product.addToCart'
                        ) | cxTranslate
                    }}
                </button>
            </div>
        </div>
    </div>
</ng-container>

import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';
import { AimoCart, AimoDayGroupedEntries } from '../../../model/cart.model';
import { AimoGTMProductAttributes, GTMCartType, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoOrderTemplateDialogData } from '../../order-template/order-template-modal/aimo-order-template-layout.config';
import { hideDiv } from '../../shared/utils/spinner/aimo-spinner-utils';

@Component({
    selector: 'aimo-cart-item-list',
    templateUrl: './aimo-cart-item-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoCartItemListComponent implements AfterViewInit {
    @Input()
    cart: AimoCart;

    @Input()
    dayGroupedEntries: AimoDayGroupedEntries;

    @Input()
    alreadyOrderedEntriesOpen = {};

    @Output() toggleList = new EventEmitter<boolean>();
    @Output() toggleAlreadyOrderedList = new EventEmitter<boolean>();

    @Input() showHideList = false;
    @Input() showHideAlreadyOrderedList = false;

    @Input() paymentErrorStatus: string;

    @ViewChild('mobileAlreadyOrdered') mobileAlreadyOrdered: ElementRef<HTMLElement>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        protected aimoRoutingService: AimoRoutingService,
        protected launchDialogService: LaunchDialogService,
    ) {}

    ngAfterViewInit(): void {
        // Toggle already ordered orders open for mobile if we have openOrders url parameter
        if (!this.showHideAlreadyOrderedList && this.mobileAlreadyOrdered?.nativeElement != null) {
            setTimeout(() => {
                if (Object.keys(this.alreadyOrderedEntriesOpen).length != 0) {
                    this.toggleMobileAlreadyOrderedList();
                }
            }, 100);
        }
    }

    toggleMobileList(): void {
        this.toggleList.emit(!this.showHideList);
    }

    toggleMobileAlreadyOrderedList(): void {
        this.toggleAlreadyOrderedList.emit(!this.showHideAlreadyOrderedList);
    }

    isOpen(orderNumber: string): boolean {
        return this.alreadyOrderedEntriesOpen[orderNumber] === true;
    }

    toggleAlreadyOrderedEntries(orderNumber: string): void {
        this.alreadyOrderedEntriesOpen[orderNumber] = !this.isOpen(orderNumber);
    }

    toggleMobileListAndScrollTo(id: string): void {
        if (!this.showHideList) {
            this.toggleMobileList();
            // wait a moment to get elements visible for scroll
            setTimeout(() => {
                this.scrollTo(id);
            }, 100);
        } else {
            this.scrollTo(id);
        }
    }

    scrollTo(id: string): void {
        const productElement = this.document.querySelector('#' + id);
        if (productElement) {
            productElement.scrollIntoView({ block: 'nearest' });
        } else {
            this.document.querySelector('aimo-cart-item-list').scrollIntoView({ block: 'nearest' });
        }
    }

    hideMessage(div: HTMLElement): void {
        hideDiv(div);
    }

    public openExcelImportModal(): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.EXCEL_IMPORT, undefined);
    }

    importFromOrderTemplate(sourceId: string): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, {
            importFrom: true,
            focusIdAfterClose: sourceId,
        } as AimoOrderTemplateDialogData);
    }

    createGtmAttributes(idx: number): AimoGTMProductAttributes {
        return {
            index: idx,
            item_list_id: GTMItemListId.cart_items,
            cart_type: GTMCartType.cart,
        } as AimoGTMProductAttributes;
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    refreshPage(): void {
        this.document.location.reload();
    }
}

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Actions } from '@ngrx/effects';
import { RoutingService, TranslationService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AimoCartList } from '../../model/cart.model';
import { AimoFacet, AimoTemplateFacet } from '../../model/product.model';
import { AimoActiveCartService } from '../../service/cart/aimo-active-cart.service';
import { AimoGtmService } from '../../service/gtm/aimo-gtm.service';
import { AimoUserService } from '../../service/user/aimo-user.service';
import { AimoNgbDateParserFormatter } from '../shared/datepicker/aimo-datepicker-parser-formatter';
import { openCloseSpinner } from '../shared/utils/spinner/aimo-spinner-utils';

@Component({
    selector: 'aimo-order-templates',
    templateUrl: './aimo-order-templates.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AimoOrderTemplatesComponent implements OnInit, OnDestroy {
    cartList$ = new BehaviorSubject<AimoCartList>({ carts: [], facets: [] });
    searchForm: FormGroup;
    subscriptions: Subscription = new Subscription();
    selectedFacet: AimoTemplateFacet = {
        selected: true,
        code: 'all-facets',
    };

    hideNormalOrderTemplatesFromBasicUsers: boolean;
    customerAdmin: boolean;
    searchInput = new BehaviorSubject('');

    // eslint-disable-next-line
    breadcrumbs$: Observable<any[]>;

    templateId$: Observable<string>;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected userService: AimoUserService,
        protected activeCartService: AimoActiveCartService,
        protected ngbDateParserFormatter: AimoNgbDateParserFormatter,
        protected activatedRoute: ActivatedRoute,
        protected routingService: RoutingService,
        protected cdr: ChangeDetectorRef,
        private lTranslation: TranslationService,
        protected actions$: Actions,
        protected gtmService: AimoGtmService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    ngOnInit(): void {
        // eslint-disable-next-line
        this.breadcrumbs$ = this.lTranslation.translate('aimo.ordertemplates.title').pipe(
            filter(Boolean),
            map((translation) => [{ label: translation, link: '/ordertemplates' }]),
        );

        this.templateId$ = this.routingService.getRouterState().pipe(
            map((routingData) => {
                const id = routingData.state.context.id?.replace('/ordertemplates', '').replace('/', '');
                if (id == '') {
                    return undefined;
                }
                return id;
            }),
            distinctUntilChanged(),
        );
        this.setUserValues();
        this.initForm();
    }

    private setUserValues(): void {
        this.subscriptions.add(
            this.userService
                .get()
                .pipe(filter((user) => user !== undefined))
                .subscribe((user) => {
                    this.hideNormalOrderTemplatesFromBasicUsers = user.isHideNormalOrderTemplatesFromBasicUsers;
                    this.customerAdmin = user.customerAdmin;
                    this.initForm();
                    this.cdr.detectChanges();
                }),
        );
    }

    private initForm(): void {
        const searchTerm = this.activatedRoute.snapshot.queryParams?.searchTerm;
        this.searchForm = new FormGroup({
            search: new FormControl(searchTerm),
            from: new FormControl(),
            to: new FormControl(),
            hideNormalOrderTemplatesFromBasicUsers: new FormControl(this.hideNormalOrderTemplatesFromBasicUsers),
        });
        this.fetchTemplates();
        this.subscriptions.add(
            this.launchDialogService.dialogClose.subscribe((next) => {
                // Fetch templates on dialog close
                if (next) {
                    this.fetchTemplates();
                    this.cdr.detectChanges();
                }
            }),
        );

        this.subscriptions.add(
            this.templateId$.subscribe(() => {
                this.fetchTemplates();
            }),
        );

        this.subscriptions.add(
            this.searchInput.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(() => this.fetchTemplates()),
        );
    }

    toggleFacet(facet: AimoFacet): void {
        if (this.selectedFacet?.code === facet.code) {
            // Deselect if clicking selected facet
            this.selectedFacet = undefined;
        } else {
            this.selectedFacet = facet;
        }
        this.fetchTemplates();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openCreateTemplateModal(): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, { createNew: true });
    }

    search(): void {
        this.searchInput.next(this.searchForm.controls['search'].value);
    }

    fetchTemplates(): void {
        this.activeCartService
            .getOrderTemplates(
                this.selectedFacet?.code !== 'all-facets' ? this.selectedFacet?.code : undefined,
                this.searchForm.controls['search'].value,
            )
            .forEach((next) => {
                this.cartList$.next(next);
                openCloseSpinner(this.document, false);
            });
    }

    convertDate(d: string): NgbDate {
        return d ? this.ngbDateParserFormatter.parse(new Date(d)) : null;
    }

    getFacetHits(facet: AimoFacet): number {
        return facet.values.filter((a) => a.code === 'hits')[0]?.count;
    }

    setHideNormalOrderTemplatesFromBasicUsers(): void {
        this.userService.setHideNormalOrderTemplatesFromBasicUsers(
            this.searchForm.controls.hideNormalOrderTemplatesFromBasicUsers.value,
        );
    }

    publicTemplatesVisible(): boolean {
        return this.customerAdmin !== undefined && (this.customerAdmin || !this.hideNormalOrderTemplatesFromBasicUsers);
    }

    pushGtmEvent(event: string): void {
        this.gtmService.pushEvent(event);
    }
}

import { ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoCMSServiceNotificationComponent } from '../../../model/cms.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoMessageService } from '../../../service/util/aimo-message-service';

const MESSAGE_ID = 'service-notification';

@Component({
    selector: 'aimo-service-notification-component',
    templateUrl: './aimo-service-notification.component.html',
})
export class AimoServiceNotificationComponent {
    message$: Observable<AimoCMSServiceNotificationComponent>;
    customerBlocked$: Observable<boolean>;
    isLoggedInUser$: Observable<boolean>;

    constructor(
        protected component: CmsComponentData<AimoCMSServiceNotificationComponent>,
        protected messageService: AimoMessageService,
        protected cdr: ChangeDetectorRef,
        protected auth: AuthService,
        protected cartService: AimoActiveCartService,
    ) {
        this.message$ = this.component.data$;
        this.isLoggedInUser$ = this.auth.isUserLoggedIn();
        this.customerBlocked$ = this.cartService
            .getActive()
            .pipe(map((cart) => cart.messages.find((m) => m.position === 'SHOP_HEADER') !== undefined));
    }

    hideMessage(message: AimoCMSServiceNotificationComponent): void {
        this.messageService.hideMessage(MESSAGE_ID + this.getTimeParam(message));
        this.cdr.detectChanges();
    }

    isMessageHidden(message: AimoCMSServiceNotificationComponent): boolean {
        return this.messageService.isMessageHidden(MESSAGE_ID + this.getTimeParam(message));
    }

    getTimeParam(message: AimoCMSServiceNotificationComponent): string {
        return '' + message.modifiedTime; // hide message only for current day
    }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ForgotPasswordComponent } from '@spartacus/user/profile/components';
import { Subscription } from 'rxjs';

import { AimoForgotPasswordComponentService } from './aimo-forgot-password-component.service';

@Component({
    selector: 'aimo-cx-forgot-password',
    templateUrl: './aimo-forgot-password.component.html',
})
export class AimoForgotPasswordComponent extends ForgotPasswordComponent implements OnInit, OnDestroy {
    isSuccess: boolean;

    private subscription = new Subscription();

    constructor(
        protected service: AimoForgotPasswordComponentService,
        protected cdr: ChangeDetectorRef,
    ) {
        super(service);
        this.form = this.service.form;
        this.isUpdating$ = this.service.isUpdating$;
    }

    ngOnInit(): void {
        this.subscription.add(
            this.service.isSuccess$.subscribe((value) => {
                this.isSuccess = value;
                this.cdr.detectChanges();
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    onSubmit(): void {
        this.service.requestEmail();
    }

    finished(): void {
        this.isSuccess = false;
        this.service.clear();
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { MediaModule, SearchBoxModule } from '@spartacus/storefront';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AimoItemCounterModule } from '../../../shared/item-counter/aimo-item-counter.module';
import { AimoProductListModule } from '../../product/product-list/aimo-product-list.module';
import { AimoSpinnerModule } from '../../shared/spinner/aimo-spinner.module';

import { AimoSearchBoxResultComponent } from './aimo-search-box-result.component';
import { AimoSearchBoxComponent } from './aimo-search-box.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MediaModule,
        UrlModule,
        I18nModule,
        ConfigModule.withConfig({
            cmsComponents: {
                SearchBoxComponent: {
                    component: AimoSearchBoxComponent,
                },
            },
        } as CmsConfig),
        SearchBoxModule,
        AimoItemCounterModule,
        InfiniteScrollModule,
        AimoProductListModule,
        AimoSpinnerModule,
        NgbTooltipModule,
        NgbPopoverModule,
    ],
    declarations: [AimoSearchBoxComponent, AimoSearchBoxResultComponent],
    exports: [AimoSearchBoxComponent, AimoSearchBoxResultComponent],
})
export class AimoSearchBoxModule extends SearchBoxModule {}

<ng-container *ngIf="banner; else bannerComponent">
    <div class="aimo-small-banner">
        <ng-container *ngTemplateOutlet="bannerContent; context: { banner: banner, isCarousel: true, isSmall: true }">
        </ng-container>
    </div>
</ng-container>

<ng-template #bannerComponent>
    <ng-container *ngIf="componentData$ | async as banner">
        <ng-container *ngIf="banner.bannerType === 'HERO'">
            <!-- Hero banner background stretches full width and content is inside container -->
            <div class="aimo-hero-banner">
                <div
                    class="aimo-banner-background"
                    [ngClass]="{
                        'bg-summer': banner.bannerColor === 'SUMMER',
                        'bg-yellow': banner.bannerColor === 'YELLOW',
                        'bg-purple': banner.bannerColor === 'PURPLE',
                        'bg-petrol': banner.bannerColor === 'PETROL',
                        'bg-autumn': banner.bannerColor === 'AUTUMN',
                        'bg-summer-light': banner.bannerColor === 'SUMMER_LIGHT',
                    }">
                    <div class="container">
                        <ng-container
                            *ngTemplateOutlet="
                                bannerContent;
                                context: { banner: banner, isCarousel: false, isSmall: false }
                            "></ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="banner.bannerType === 'LARGE'">
            <!-- Large banner background and content are inside container -->
            <div class="aimo-large-banner">
                <div class="container">
                    <div
                        class="aimo-banner-background"
                        [ngClass]="{
                            'bg-summer': banner.bannerColor === 'SUMMER',
                            'bg-yellow': banner.bannerColor === 'YELLOW',
                            'bg-purple': banner.bannerColor === 'PURPLE',
                            'bg-petrol': banner.bannerColor === 'PETROL',
                            'bg-autumn': banner.bannerColor === 'AUTUMN',
                            'bg-summer-light': banner.bannerColor === 'SUMMER_LIGHT',
                        }">
                        <ng-container
                            *ngTemplateOutlet="
                                bannerContent;
                                context: { banner: banner, isCarousel: false, isSmall: false }
                            "></ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="banner.bannerType === 'SMALL'">
            <!-- Small banner is inside carousel and does not have background -->
            <div class="aimo-small-banner">
                <ng-container
                    *ngTemplateOutlet="
                        bannerContent;
                        context: { banner: banner, isCarousel: false, isSmall: true }
                    "></ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #bannerMedia let-banner="banner">
    <youtube-player *ngIf="banner.youtubeId" videoId="{{ banner.youtubeId }}"></youtube-player>
    <cx-media *ngIf="banner.media && !banner.youtubeId" [container]="banner.media"></cx-media>
</ng-template>

<ng-template #bannerContent let-banner="banner" let-isCarousel="isCarousel" let-isSmall="isSmall">
    <div class="row no-gutters">
        <div
            [ngClass]="{
                'col-md-6 col-12': !isSmall,
                'col-12': isSmall,
                'order-md-2': !isSmall && banner.bannerPosition === 'TEXT_LEFT',
                'order-md-1': !isSmall && banner.bannerPosition !== 'TEXT_LEFT',
            }">
            <ng-container *ngIf="!banner.headText && !banner.text && !banner.buttonName && banner.urlLink">
                <a
                    *ngIf="banner.urlLink && isExternal(banner)"
                    (click)="buttonClicked(banner)"
                    [href]="getSafeUrl(banner.urlLink)"
                    [target]="getTarget(banner)">
                    <ng-container *ngTemplateOutlet="bannerMedia; context: { banner: banner }"></ng-container>
                </a>
                <a
                    *ngIf="banner.urlLink && !isExternal(banner)"
                    (click)="buttonClicked(banner)"
                    [routerLink]="banner.urlLink"
                    [fragment]="banner.urlLinkFragment">
                    <ng-container *ngTemplateOutlet="bannerMedia; context: { banner: banner }"></ng-container>
                </a>
            </ng-container>
            <ng-container *ngIf="banner.headText || banner.text || banner.buttonName || !banner.urlLink">
                <ng-container *ngTemplateOutlet="bannerMedia; context: { banner: banner }"></ng-container>
            </ng-container>
        </div>
        <div
            [ngClass]="{
                'col-md-6 col-12 my-auto': !isSmall,
                'col-12': isSmall,
                'order-md-1': !isSmall && banner.bannerPosition === 'TEXT_LEFT',
                'order-md-2': !isSmall && banner.bannerPosition !== 'TEXT_LEFT',
            }">
            <div class="banner-content" *ngIf="banner.headText || banner.text || banner.buttonName">
                <h2
                    *ngIf="banner.headText"
                    class="banner-header"
                    [ngClass]="{
                        h1: !isSmall,
                        h3: isSmall,
                    }"
                    [innerHTML]="banner.headText"></h2>
                <div *ngIf="banner.text" class="banner-text" [innerHTML]="banner.text"></div>
                <a
                    *ngIf="banner.buttonName && isExternal(banner)"
                    class="btn"
                    [ngClass]="{
                        'btn-sm': isSmall,
                        'btn-sm-down': !isSmall,
                        'btn-highlighted': banner.bannerColor === 'PETROL',
                        'btn-primary': banner.bannerColor !== 'PETROL',
                    }"
                    (click)="buttonClicked(banner)"
                    [innerHTML]="banner.buttonName"
                    [href]="getSafeUrl(banner.urlLink)"
                    [target]="getTarget(banner)">
                </a>
                <a
                    *ngIf="banner.buttonName && !isExternal(banner)"
                    class="btn"
                    [ngClass]="{
                        'btn-sm': isSmall,
                        'btn-sm-down': !isSmall,
                        'btn-highlighted': banner.bannerColor === 'PETROL',
                        'btn-primary': banner.bannerColor !== 'PETROL',
                    }"
                    (click)="buttonClicked(banner)"
                    [innerHTML]="banner.buttonName"
                    [routerLink]="banner.urlLink"
                    [fragment]="banner.urlLinkFragment">
                </a>
            </div>
        </div>
    </div>
</ng-template>

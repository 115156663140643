import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WindowRef } from '@spartacus/core';
import { CurrentProductService, LaunchDialogService } from '@spartacus/storefront';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';
import { AimoCart, AimoOrderEntry } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGTMProductAttributes } from '../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../service/product/aimo-current-product.service';
import { DateUtils } from '../../../shared/util/date-utils';

@Component({
    selector: 'aimo-order-template-item',
    templateUrl: './aimo-order-template-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderTemplateItemComponent implements OnInit {
    @Input() cart: AimoCart;
    @Input() entry: AimoOrderEntry;
    @Input() gtmProductAttributes?: AimoGTMProductAttributes;
    @Input() idSuffix: number;
    @Input() baseOrder = false;
    @Input() alreadyAddedToCart: number;
    @Input() printViewWithTable: boolean = false;
    addToCartForm: UntypedFormGroup;

    constructor(
        protected winRef: WindowRef,
        protected currentProductService: CurrentProductService,
        protected activeCartService: AimoActiveCartService,
        protected launchDialogService: LaunchDialogService,
        protected aimoRoutingService: AimoRoutingService,
    ) {}

    ngOnInit(): void {
        this.addToCartForm = new UntypedFormGroup({
            quantity: new UntypedFormControl(this.entry.quantity, { updateOn: 'blur' }),
        });
    }

    openProductModal(sourceId: string): void {
        (this.currentProductService as AimoCurrentProductService).openProductModal(
            this.entry.product.code,
            this.gtmProductAttributes,
            null,
            null,
            null,
            sourceId,
        );
    }

    changeQuantity(): void {
        const quantity = this.addToCartForm.get('quantity')?.value;
        this.entry.quantity = quantity;
        this.activeCartService.updateAimoEntry(
            {
                entryNumber: this.entry.entryNumber,
                product: { code: this.entry.product.code },
                quantity,
            },
            this.cart.code,
            this.gtmProductAttributes,
        );
    }

    deleteEntry(entry: AimoOrderEntry): void {
        this.activeCartService.removeAimoEntry(entry, this.cart.code, this.gtmProductAttributes);
    }

    convertDate(d: string): Date {
        const dd = DateUtils.convertDate(d);
        return dd;
    }

    addToCart(entry: AimoOrderEntry): void {
        this.activeCartService.addAimoEntry(entry.product.code, entry.quantity, this.gtmProductAttributes);
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    isProductRemoved(): boolean {
        return !this.entry.productExists || this.entry.product.stock.salesBlockedFromNowUntilForever;
    }
}

import { Injectable } from '@angular/core';
import { Command, CommandService, UserIdService } from '@spartacus/core';
import { UserPasswordService } from '@spartacus/user/profile/core';
import { Observable } from 'rxjs';

import { AimoUserProfileConnector } from './aimo-user-profile.connector';

@Injectable({
    providedIn: 'root',
})
export class AimoUserPasswordService extends UserPasswordService /*implements AimoUserPasswordFacade*/ {
    protected setPasswordCommand: Command<{
        token: string;
        password: string;
    }>;

    constructor(
        protected userProfileConnector: AimoUserProfileConnector,
        protected userIdService: UserIdService,
        protected command: CommandService,
    ) {
        super(userProfileConnector, userIdService, command);
        this.setPasswordCommand = this.command.create((payload) =>
            this.userProfileConnector.setPassword(payload.token, payload.password),
        );
    }

    setPassword(token: string, password: string): Observable<unknown> {
        return this.setPasswordCommand.execute({ token, password });
    }
}

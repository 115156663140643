import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AimoCategory, AimoCategoryHierarchy } from '../../model/product.model';
import { AimoAssortmentService } from '../product/aimo-assortment.service';

@Injectable({
    providedIn: 'root',
})
export class AimoCategoryAdapter {
    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected assortmentService: AimoAssortmentService,
    ) {}

    getCategoryById(userId: string, categoryId: string): Observable<AimoCategory> {
        return this.getEndpoint('category', userId, categoryId).pipe(
            switchMap((url) => this.http.get<AimoCategoryHierarchy>(url)),
        );
    }

    getCategoryPathById(userId: string, categoryId: string): Observable<string> {
        return this.getEndpoint('categoryPath', userId, categoryId).pipe(
            switchMap((url) => this.http.get(url, { responseType: 'text' })),
        );
    }

    getCategoryHierarchyById(userId: string, categoryId: string): Observable<AimoCategoryHierarchy> {
        return this.getEndpoint('categoryHierarchy', userId, categoryId).pipe(
            switchMap((url) => this.http.get<AimoCategoryHierarchy>(url)),
        );
    }

    protected getEndpoint(pointId: string, userId: string, categoryId: string): Observable<string> {
        return this.assortmentService.isOnlyMyAssortment().pipe(
            map((onlyMyAssortment) =>
                this.occEndpoints.buildUrl(pointId, {
                    urlParams: {
                        userId,
                        categoryId,
                    },
                    queryParams: {
                        onlyMyAssortment,
                    },
                }),
            ),
        );
    }
}

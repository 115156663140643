import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {AimoDeliveryDateEffects} from './aimo-deliverydate.effect';

@NgModule({
    imports: [
        EffectsModule.forFeature([AimoDeliveryDateEffects]),
    ],
})
export class AimoDeliverydateModule {}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveCartService, getCartIdByUserId, StateWithMultiCart } from '@spartacus/cart/base/core';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import {
    CxDatePipe,
    GlobalMessageService,
    GlobalMessageType,
    TranslationService,
    UserIdService,
    WindowRef,
} from '@spartacus/core';
import { Translatable, TranslatableParams } from '@spartacus/core/src/i18n/translatable';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, delay, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { openCloseSpinner } from '../../cms-components/shared/utils/spinner/aimo-spinner-utils';
import {
    AimoBaseOrder,
    AimoBaseOrderList,
    AimoCart,
    AimoCartList,
    AimoOrderEntry,
    AimoRouteCalendar,
} from '../../model/cart.model';
import { FileExportWrapper } from '../../model/misc.model';
import { AimoOrderHistoryRequest } from '../../model/order.model';
import { AimoGtmCalendar, AimoGtmProducts, AimoPlaceOrderClicked } from '../gtm/aimo-gtm.action';
import {
    AimoGTMProduct,
    AimoGTMProductAttributes,
    GTMCalendarSource,
    GTMCartType,
    GTMEventCode,
    GTMItemListId,
} from '../gtm/aimo-gtm.model';
import { AimoDeliveryDateService } from '../routecalendar/aimo-delivery-date.service';
import { AimoUserOrderConnector } from '../user/aimo-user-order.connector';

import { AimoCartConnector } from './aimo-cart.connector';
import { AimoMultiCartService } from './aimo-multi-cart.service';

@Injectable({
    providedIn: 'root',
})
export class AimoActiveCartService extends ActiveCartService {
    constructor(
        protected store: Store<StateWithMultiCart>,
        protected multiCartFacade: MultiCartFacade,
        protected userIdService: UserIdService,
        protected multiCartService: AimoMultiCartService,
        protected cartConnector: AimoCartConnector,
        protected userOrderConnector: AimoUserOrderConnector,
        protected globalMessage: GlobalMessageService,
        private translation: TranslationService,
        protected deliveryDateService: AimoDeliveryDateService,
        protected datePipe: CxDatePipe,
        windowRef: WindowRef,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(multiCartFacade, userIdService, windowRef);
        this.startClosingTimeMessageUpdater();
    }

    requireLoadedCart(forGuestMerge?: boolean): Observable<AimoCart> {
        return super.requireLoadedCart(forGuestMerge);
    }

    getOrderTemplates(visibility: string, search: string, onlyEditable?: boolean): Observable<AimoCartList> {
        return this.userIdService
            .getUserId()
            .pipe(
                switchMap((userId) =>
                    this.multiCartService.getOrderTemplates(userId, visibility, search, onlyEditable),
                ),
            );
    }

    getOrderTemplate(code: string): Observable<AimoCart> {
        return this.deliveryDateService.getDeliveryDate().pipe(
            filter(Boolean),
            withLatestFrom(this.userIdService.takeUserId()),
            switchMap(([date, userId]) => this.multiCartService.getOrderTemplate(userId, code, date)),
            catchError((err) => {
                openCloseSpinner(this.document, false);
                // eslint-disable-next-line
                console.error('error fetching template ' + code, err);
                return of(null);
            }),
        );
    }

    createOrderTemplate(
        visibility: string,
        name: string,
        oldCartId?: string,
        orderHistoryDay?: string,
    ): Observable<AimoCart> {
        return this.userIdService
            .takeUserId()
            .pipe(
                switchMap((userId) =>
                    this.multiCartService
                        .createOrderTemplate(userId, visibility, name, oldCartId, orderHistoryDay)
                        .pipe(
                            tap((cart) =>
                                cart.messages?.forEach((mes) =>
                                    this.globalMessage.add(mes.message, GlobalMessageType.MSG_TYPE_INFO, 10000),
                                ),
                            ),
                        ),
                ),
            );
    }

    editOrderTemplate(template: AimoCart): Observable<AimoCart> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.multiCartService.editOrderTemplate(userId, template.code, template)));
    }

    createClaim(date: string, remark: string, contactType: string, entries: AimoOrderEntry[]): Observable<AimoCart> {
        return this.multiCartService.createClaim('current', date, remark, contactType, entries).pipe(take(1));
    }

    getClaims(startDate?: string, endDate?: string, detailsView?: boolean): Observable<AimoCartList> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.cartConnector.getClaims(userId, startDate, endDate, detailsView)));
    }

    getPurchaseReport(params: AimoOrderHistoryRequest): Observable<AimoCart> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.cartConnector.getPurchaseReport(userId, params)));
    }

    getPurchaseReportExcel(nameKey: string, params: AimoOrderHistoryRequest): void {
        const sub = this.userIdService
            .takeUserId()
            .pipe(withLatestFrom(this.translation.translate(nameKey)))
            .pipe(switchMap(([userId, name]) => this.cartConnector.getPurchaseReportExcel(name, userId, params)))
            .subscribe((response) => {
                AimoActiveCartService.exportAsBinary(response);
                sub?.unsubscribe();
            });
    }

    getRouteCalendar(claimsMode?: boolean): Observable<AimoRouteCalendar> {
        return this.getActive().pipe(
            switchMap((cart) => this.cartConnector.getRouteCalendar(cart.user.uid, cart.code, claimsMode)),
        );
    }

    getActive(): Observable<AimoCart> {
        return super.getActive().pipe(filter((cart) => cart.code !== undefined)) as Observable<AimoCart>;
    }

    exportCartToExcel(historyDay?: string, cartId?: string): void {
        const sub = this.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.takeUserId()))
            .pipe(
                switchMap(([cart, userId]) =>
                    this.cartConnector.exportCartToExcel(
                        userId,
                        cartId ? cartId : getCartIdByUserId(cart, userId),
                        historyDay,
                    ),
                ),
            )
            .subscribe((response) => {
                AimoActiveCartService.exportAsBinary(response);
                sub?.unsubscribe();
            });
    }

    downloadOrderDocument(invoiceId: string, fileName: string, deliveryNote: boolean): void {
        openCloseSpinner(this.document, true);
        const sub = this.userIdService
            .takeUserId()
            .pipe(
                switchMap((userId) =>
                    this.cartConnector.downloadOrderDocument(userId, invoiceId, fileName, deliveryNote),
                ),
            )
            .subscribe((response) => {
                openCloseSpinner(this.document, false);
                AimoActiveCartService.exportAsBinary(response);
                sub?.unsubscribe();
            });
    }

    exportOrderToExcel(orderNumber: string): void {
        const sub = this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.userOrderConnector.exportOrderToExcel(userId, orderNumber)))
            .subscribe((response) => {
                AimoActiveCartService.exportAsBinary(response);
                sub?.unsubscribe();
            });
    }

    public static exportAsBinary(response: FileExportWrapper): void {
        if (response) {
            const bytes = new Uint8Array(response.data);
            saveAs(
                new Blob([bytes], {
                    type: 'application/octet-stream',
                }),
                response.name,
            );
        }
    }

    updateRequestedDate(date: string, calendarSource: GTMCalendarSource): void {
        this.updateCartHeader({ requestedDeliveryDate: date } as AimoCart, calendarSource);
    }

    updateCartHeader(cart: AimoCart, calendarSource?: GTMCalendarSource, resetExternalItems?: boolean): void {
        this.requireLoadedCart()
            .subscribe((cartState) => {
                this.multiCartService.updateCartHeader(
                    cart.user?.uid ?? 'current',
                    cartState.code,
                    cart,
                    calendarSource,
                    resetExternalItems,
                );
            })
            .unsubscribe();
    }

    deleteCart(cartCode?: string, userId?: string): void {
        if (cartCode) {
            this.multiCartService.deleteCart(cartCode, userId);
        } else {
            this.requireLoadedCart()
                .pipe(withLatestFrom(this.userIdService.takeUserId()))
                .subscribe(([cart, userId]) => {
                    this.multiCartService.deleteCart(getCartIdByUserId(cart, userId), userId);
                })
                .unsubscribe();
        }
    }

    addAimoEntry(
        productCode: string,
        quantity: number,
        gtmProductAttributes: AimoGTMProductAttributes,
        cartCode?: string,
    ): void {
        this.getActiveCartId()
            .pipe(withLatestFrom(this.userIdService.getUserId()))
            .pipe(take(1))
            .subscribe(([cartId, userId]) => {
                this.multiCartService.addAimoEntry(
                    userId,
                    cartCode ? cartCode : this.getNonBlankCartId(cartId),
                    {
                        product: { code: productCode },
                        quantity: quantity,
                        gtmItemListId: gtmProductAttributes?.item_list_id,
                    } as AimoOrderEntry,
                    gtmProductAttributes,
                );
            });
    }

    addAimoEntries(entries: AimoOrderEntry[], cartId?: string, origin?: GTMCartType, searchTerm?: string): void {
        if (entries) {
            this.requireLoadedCart()
                .pipe(withLatestFrom(this.userIdService.getUserId()))
                .pipe(take(1))
                .subscribe(([cart, userId]) => {
                    this.multiCartService.addAimoEntries(
                        userId,
                        // eslint-disable-next-line
                        cartId ? cartId : getCartIdByUserId(cart, userId),
                        entries,
                        origin,
                        searchTerm,
                    );
                });
        }
    }

    updateAimoEntry(entry: AimoOrderEntry, cartCode?: string, gtmProductAttributes?: AimoGTMProductAttributes): void {
        this.getActiveCartId()
            .pipe(withLatestFrom(this.userIdService.getUserId()))
            .pipe(take(1))
            .subscribe(([cartId, userId]) => {
                this.multiCartService.updateAimoEntry(
                    userId,
                    cartCode ? cartCode : this.getNonBlankCartId(cartId),
                    entry,
                    gtmProductAttributes,
                );
            });
    }

    getNonBlankCartId(cartId: string): string {
        return cartId && cartId !== '' ? cartId : 'current';
    }

    removeAimoEntry(entry: AimoOrderEntry, cartCode?: string, gtmProductAttributes?: AimoGTMProductAttributes): void {
        this.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.getUserId()))
            .pipe(take(1))
            .subscribe(([cart, userId]) => {
                this.multiCartService.updateAimoEntry(
                    userId,
                    cartCode ? cartCode : getCartIdByUserId(cart, userId),
                    {
                        ...entry,
                        quantity: 0,
                    },
                    gtmProductAttributes,
                );
            });
    }

    excelImport(data: string): void {
        this.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.takeUserId()))
            .pipe(take(1))
            .subscribe(([cart, userId]) => {
                this.multiCartService.excelImport(userId, getCartIdByUserId(cart, userId), data);
            })
            .unsubscribe();
    }

    orderTemplateImport(fromId: string, selectedDate?: string): void {
        this.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.takeUserId()))
            .pipe(take(1))
            .subscribe(([cart, userId]) => {
                this.multiCartService.orderTemplateImport(
                    userId,
                    getCartIdByUserId(cart, userId),
                    fromId,
                    selectedDate,
                );
            })
            .unsubscribe();
    }

    addManyProducts(data: AimoCart): void {
        this.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.takeUserId()))
            .pipe(take(1))
            .subscribe(([cart, userId]) => {
                this.multiCartService.addManyProducts(userId, getCartIdByUserId(cart, userId), data);
            })
            .unsubscribe();
    }

    triggerGTMOpenCalendar(source: GTMCalendarSource): void {
        if (source === GTMCalendarSource.cart) {
            this.getActive()
                .subscribe((cart) => {
                    this.store.dispatch(new AimoGtmCalendar(cart, GTMEventCode.OpenCartCalendar, source));
                })
                .unsubscribe();
        }
    }

    triggerGTMCartPageVisit(): void {
        this.getActive()
            .subscribe((cart) => {
                let index = 0;
                this.store.dispatch(
                    new AimoGtmProducts(
                        cart.dayGroupedEntries
                            .filter((d) => d.active)
                            .flatMap((d) => d.entries)
                            .map(
                                (e) =>
                                    ({
                                        ...e.product,
                                        quantity: e.quantity,
                                        discount: e.discount,
                                        price: e.totalPrice,
                                        gtmProductAttributes: {
                                            index: index++,
                                            item_list_id: GTMItemListId.cart,
                                        },
                                    }) as AimoGTMProduct,
                            ),
                        GTMEventCode.ViewCart,
                        cart.totalPrice?.value ?? 0,
                    ),
                );
            })
            .unsubscribe();
    }

    approveBaseOrder(baseOrderId: string, approve: boolean): Observable<void> {
        return this.userIdService
            .takeUserId()
            .pipe(take(1))
            .pipe(mergeMap((userId) => this.cartConnector.approveBaseOrder(userId, baseOrderId, approve)));
    }

    saveBaseOrder(baseOrder: AimoBaseOrder, blockAdded?: boolean): Observable<AimoBaseOrder> {
        return this.userIdService
            .takeUserId()
            .pipe(take(1))
            .pipe(mergeMap((userId) => this.cartConnector.saveBaseOrder(userId, baseOrder, blockAdded)));
    }

    getBaseOrders(search?: string): Observable<AimoBaseOrderList> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.cartConnector.getBaseOrders(userId, search)));
    }

    getBaseOrder(code?: string): Observable<AimoBaseOrder> {
        return this.deliveryDateService.getDeliveryDate().pipe(
            filter((deliveryDate) => deliveryDate !== undefined),
            withLatestFrom(this.userIdService.takeUserId()),
            switchMap(([date, userId]) => this.cartConnector.getBaseOrder(userId, code, date)),
            catchError((err) => {
                openCloseSpinner(this.document, false);
                // eslint-disable-next-line
                console.error('error fetching baseOrder ' + code, err);
                return of(null);
            }),
        );
    }

    placeOrderClicked(cart: AimoCart): void {
        this.store.dispatch(new AimoPlaceOrderClicked(cart));
    }

    startClosingTimeMessageUpdater(): void {
        setInterval(() => {
            this.getClosingTimeMessages()
                .subscribe((mes) => this.closingTimeMessage$.next(mes))
                .unsubscribe();
        }, 1000);
    }

    closingTimeMessage$: BehaviorSubject<ClosingTimeMessage> = new BehaviorSubject<ClosingTimeMessage>(undefined);

    getClosingTimeMessages(): Observable<ClosingTimeMessage> {
        return this.getActive().pipe(
            map((cart) => {
                let closingTimeMessage = undefined;
                const closingEntries = (cart as AimoCart).dayGroupedEntries
                    ?.filter((g) => g.active)
                    .flatMap((g) => g.entries)
                    .filter((e) => e.closingTimeWarningDate?.getTime() <= new Date().getTime());
                if (closingEntries && closingEntries.length > 0) {
                    const warningTime = closingEntries[0].closingTimeWarningDate;
                    const closingTime = closingEntries[0].closingTime;
                    const timeLeft = Math.round(
                        (closingEntries[0].closingTime.getTime() - new Date().getTime()) / 60000,
                    );
                    if (timeLeft >= 0 && warningTime && closingTime) {
                        closingTimeMessage = {
                            lines: closingEntries.length,
                            time: closingTime,
                            timeLeft: timeLeft,
                            message: {
                                key: 'aimo.cart.closingTimeWarningHeader',
                                params: {
                                    lines: closingEntries.length,
                                    time: this.datePipe.transform(closingTime, 'HH:mm'),
                                    timeLeft: timeLeft,
                                } as TranslatableParams,
                            } as Translatable,
                        };
                    } else {
                        this.closingTimeMessage$
                            .asObservable()
                            .pipe(
                                take(1),
                                filter((mes) => mes !== undefined),
                                delay(1000),
                            )
                            .subscribe(() => this.reloadActiveCart());
                        closingTimeMessage = undefined;
                    }
                }
                return closingTimeMessage;
            }),
        );
    }
}

export class ClosingTimeMessage {
    constructor(
        public lines: number,
        public time: Date,
        public timeLeft: number,
        public message: Translatable,
    ) {}
}

import { Injectable } from '@angular/core';
import { UserIdService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, switchMap, take } from 'rxjs/operators';

import { AimoCategory, AimoCategoryHierarchy } from '../../model/product.model';

import { AimoCategoryConnector } from './aimo-category.connector';

@Injectable({
    providedIn: 'root',
})
export class AimoCategoryService {
    constructor(
        protected categoryConnector: AimoCategoryConnector,
        protected userIdService: UserIdService,
    ) {}

    getCategoryById(categoryId: string): Observable<AimoCategory> {
        return this.userIdService.takeUserId().pipe(
            distinctUntilChanged(),
            take(1),
            switchMap((userId) => this.categoryConnector.getCategoryById(userId, categoryId)),
            catchError(() => of(undefined)),
        );
    }

    getCategoryPathById(categoryId: string): Observable<string> {
        return this.userIdService.takeUserId().pipe(
            distinctUntilChanged(),
            take(1),
            switchMap((userId) => this.categoryConnector.getCategoryPathById(userId, categoryId)),
            catchError(() => of(undefined)),
        );
    }

    getCategoryHierarchyById(categoryId: string): Observable<AimoCategoryHierarchy> {
        return this.userIdService.takeUserId().pipe(
            distinctUntilChanged(),
            take(1),
            concatMap((userId) => this.categoryConnector.getCategoryHierarchyById(userId, categoryId)),
            catchError(() => of(undefined)),
        );
    }
}

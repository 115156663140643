import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    AuthConfigService,
    AuthHttpHeaderService,
    AuthInterceptor,
    AuthService,
    RoutingService,
    StateWithClientAuth,
} from '@spartacus/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { openCloseSpinner } from '../../cms-components/shared/utils/spinner/aimo-spinner-utils';
import { AimoAuthRedirectService } from '../../service/auth/aimo-auth-redirect.service';
import { LoginFail } from '../../service/auth/login-logout-fail.action';
import {DOCUMENT} from "@angular/common";

@Injectable({ providedIn: 'root' })
export class ErrorsHttpInterceptor extends AuthInterceptor {
    constructor(
        protected authHttpHeaderService: AuthHttpHeaderService,
        protected authConfigService: AuthConfigService,
        public router: Router,
        protected auth: AuthService,
        protected routingService: RoutingService,
        protected authRedirectService: AimoAuthRedirectService,
        protected store: Store<StateWithClientAuth>,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(authHttpHeaderService, authConfigService);
    }

    intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.authHttpHeaderService.shouldCatchError(httpRequest);
        const shouldAddAuthorizationHeader = this.authHttpHeaderService.shouldAddAuthorizationHeader(httpRequest);

        const token$ = shouldAddAuthorizationHeader
            ? // emits sync, unless there is refresh or logout in progress, in which case it emits async
              this.authHttpHeaderService.getStableToken().pipe(take(1))
            : of(undefined);
        const requestAndToken$ = token$.pipe(
            map((token) => ({
                token,
                request: this.authHttpHeaderService.alterRequest(httpRequest, token),
            })),
        );

        return requestAndToken$.pipe(
            switchMap(({ request, token }) =>
                next.handle(request).pipe(
                    catchError((errResponse: unknown) => {
                        if (errResponse instanceof HttpErrorResponse) {
                            switch (errResponse.status) {
                                case 403: // cannot login to current store
                                    this.auth
                                        .coreLogout()
                                        .then(() => {
                                            this.authRedirectService.setRedirectUrl('/login');
                                            openCloseSpinner(this.document, false);
                                            this.store.dispatch(new LoginFail());
                                        })
                                        // eslint-disable-next-line no-console
                                        .catch((ex) => console.error(ex));
                                    break;
                            }
                        }
                        return throwError(errResponse);
                    }),
                ),
            ),
        );
    }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthService, GlobalMessageService, GlobalMessageType, RoutingService, WindowRef } from '@spartacus/core';
import { LoginFormComponentService } from '@spartacus/user/account/components';

import { openCloseSpinner } from '../../shared/utils/spinner/aimo-spinner-utils';

@Injectable()
export class AimoLoginFormComponentService extends LoginFormComponentService {
    constructor(
        protected auth: AuthService,
        protected globalMessage: GlobalMessageService,
        protected winRef: WindowRef,
        protected routingService: RoutingService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(auth, globalMessage, winRef);
    }

    protected onSuccess(isLoggedIn: boolean): void {
        if (isLoggedIn) {
            // We want to remove error messages on successful login (primary the bad username/password combination)
            this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
            // this.form.reset();
            this.routingService.go('/');
            openCloseSpinner(this.document, false);
        }
        this.busy$.next(false);
    }
}

<div *ngIf="getAimoProduct() | async as product" [ngClass]="{ 'modal-dialog': modal }">
    <div class="product-content">
        <div #modalContent [ngClass]="{ 'modal-content': modal }">
            <div class="container noPrint" [ngClass]="{ 'sticky-modal-header': modal }">
                <div class="row no-gutters noPrint" [ngClass]="{ 'modal-header': modal }">
                    <div [ngClass]="modal ? 'col-md-10 col-8' : 'col-12'">
                        <aimo-breadcrumb
                            [isProductBreadcrumb]="true"
                            [productTitle]="product.name"
                            [breadCrumbs]="getBreadcrumbs(product)"
                            [showBackLink]="!modal">
                        </aimo-breadcrumb>
                    </div>
                    <ng-container *ngIf="modal">
                        <div class="modal-action col-auto">
                            <button
                                [tabIndex]="9999"
                                class="btn-link open-product-page mr-3 noPrint"
                                [attr.aria-label]="'aimo.product.open.product' | cxTranslate"
                                (keydown.tab)="getTheFocusBack($event)">
                                <a #productLink [routerLink]="product.url" target="_blank">
                                    <i class="aimo-icon icon-md icon-inline icon-arrow-square-out"></i>
                                </a>
                            </button>
                            <button
                                #closeButton
                                class="btn-link"
                                [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                                <i class="aimo-icon icon-md icon-inline icon-x" (click)="closeDialog()"></i>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="container">
                <div class="row no-gutters" [ngClass]="{ 'modal-body': modal }">
                    <div class="onlyPrint mb-4">
                        <cx-page-slot position="SiteLogo"></cx-page-slot>
                    </div>
                    <div class="col-12 summary-grid">
                        <div class="header">
                            <div class="mb-2">
                                <aimo-product-tag [product]="product" />
                                <div
                                    class="float-right d-inline-block d-md-none noPrint"
                                    *ngIf="product.icons?.length > 0">
                                    <i
                                        *ngFor="let icon of product.icons"
                                        class="aimo-icon icon-md icon-inline ml-2 {{ icon }}"></i>
                                </div>
                            </div>

                            <h1 class="product-name">{{ product.name }}</h1>

                            <div class="text-dark-grey product-code">
                                <i
                                    *ngIf="product.favorite"
                                    class="aimo-icon icon-inline icon-dark-grey icon-sm icon-inline mr-3 icon-star-filled"
                                    triggers="mouseenter:mouseleave"
                                    placement="bottom"
                                    container="body"
                                    [ngbPopover]="'aimo.favorite.added' | cxTranslate"></i>

                                <i
                                    *ngIf="product.frozenProduct"
                                    class="aimo-icon icon-inline icon-dark-grey icon-sm icon-inline mr-3 icon-snow-flake"
                                    triggers="mouseenter:mouseleave"
                                    popoverClass="popover-default"
                                    container="body"
                                    role="img"
                                    [attr.aria-label]="'aimo.product.frozenProduct' | cxTranslate"
                                    [ngbPopover]="'aimo.product.frozenProduct' | cxTranslate"></i>

                                <span class="mr-3 ml-0">{{ product.code }} </span>

                                <span class="mr-3" *ngIf="product.lotSizeInfo">{{ product.lotSizeInfo }}</span>

                                <span class="mr-3">{{ 'productDetails.ean' | cxTranslate: { ean: product.ean } }}</span>

                                <span class="mr-3 d-inline-block" *ngIf="product.lastPurchased">
                                    <span>{{ 'aimo.product.lastPurchased' | cxTranslate }}</span>
                                    <span class="text-left-space">{{
                                        product.lastPurchased | cxDate: ('aimo.dateFormat' | cxTranslate)
                                    }}</span>
                                </span>
                            </div>
                        </div>

                        <div class="content text-center">
                            <div class="text-right pb-3 d-none d-md-block print" *ngIf="product.icons.length > 0">
                                <i
                                    *ngFor="let icon of product.icons"
                                    class="aimo-icon icon-inline ml-2 {{ icon }}"
                                    [ngClass]="icon === 'rainforest' ? 'icon-lg' : 'icon-lg2'"></i>
                            </div>
                            <aimo-product-images [modal]="modal" [product]="product"></aimo-product-images>
                        </div>

                        <div class="footer">
                            <div class="mb-2">
                                <div
                                    class="d-inline-block mr-3 mb-3 web-shop-product"
                                    *ngIf="product.pikatukkuWebShopProduct">
                                    {{ 'aimo.product.shopProduct' | cxTranslate }}
                                </div>
                                <div class="cx-stock d-inline-block mr-3">
                                    <aimo-product-availability [stock]="product.stock" *ngIf="!isPikatukku()">
                                    </aimo-product-availability>
                                    <ng-container *ngIf="isPikatukku()">
                                        <div *ngFor="let ptstock of product.pikatukkuStock" class="cx-stock">
                                            <aimo-product-availability
                                                [suggestiveMode]="true"
                                                [printStockName]="true"
                                                [stock]="ptstock">
                                            </aimo-product-availability>
                                        </div>
                                    </ng-container>
                                </div>

                                <div
                                    class="product-closing-time d-inline-block mr-3"
                                    *ngIf="product.stock?.closingTime">
                                    <i class="aimo-icon icon-inline icon-dark-grey icon-clock icon-sm"></i>
                                    <span>{{
                                        'productDetails.closeAt'
                                            | cxTranslate
                                                : { time: (convertDate(product.stock?.closingTime) | cxDate: 'HH:mm') }
                                    }}</span>
                                </div>
                                <button
                                    *ngIf="product.pikatukkuStock.length > 0 && !isPikatukku()"
                                    class="product-wholesales-availability btn-link d-inline-block noPrint"
                                    placement="top"
                                    container="body"
                                    [ngbPopover]="pikatukkuStock"
                                    popoverClass="popover-stock-tooltip">
                                    <i class="aimo-icon icon-inline icon-dark-grey icon-storefront icon-sm"></i>
                                    <span>{{ 'productDetails.availabilty.wholesales' | cxTranslate }}</span>
                                </button>
                            </div>

                            <div class="messages" *ngIf="product.stock.salesBlockStart">
                                <div class="message message-WARNING">
                                    <i class="left-side aimo-icon icon-inline icon-WARNING icon-red"></i>
                                    <span class="ml-2" *ngIf="!product.stock.salesBlockEnd">{{
                                        'aimo.product.salesBlockProductClosing'
                                            | cxTranslate
                                                : {
                                                      date:
                                                          product.stock.salesBlockStart
                                                          | cxDate: ('aimo.dateFormat' | cxTranslate),
                                                  }
                                    }}</span>
                                    <span class="ml-2" *ngIf="product.stock.salesBlockEnd"
                                        >{{
                                            'aimo.product.salesBlockSpan'
                                                | cxTranslate
                                                    : {
                                                          from:
                                                              product.stock.salesBlockStart
                                                              | cxDate: ('aimo.dateFormat' | cxTranslate),
                                                          to:
                                                              product.stock.salesBlockEnd
                                                              | cxDate: ('aimo.dateFormat' | cxTranslate),
                                                      }
                                        }}
                                    </span>
                                </div>
                            </div>

                            <ng-template #pikatukkuStock>
                                <div *ngFor="let ptstock of product.pikatukkuStock" class="cx-stock">
                                    <aimo-product-availability
                                        [suggestiveMode]="true"
                                        [printStockName]="true"
                                        [stock]="ptstock">
                                    </aimo-product-availability>
                                </div>
                            </ng-template>

                            <div class="price row no-gutters" *ngIf="product.price">
                                <div class="col-auto pr-lg-0 pr-md-5 mb-md-3 mb-0 mb-lg-0">
                                    <div class="active-price mb-1">
                                        <i
                                            *ngIf="product.myAssortment"
                                            placement="right"
                                            container="body"
                                            triggers="mouseenter:mouseleave"
                                            [ngbPopover]="'aimo.product.myAssortment' | cxTranslate"
                                            class="aimo-icon icon-inline icon-md icon-house-line"
                                            [ngClass]="
                                                product.strikeThroughPrice ? 'icon-red' : 'icon-petrol-blue'
                                            "></i>
                                        <span
                                            class="text-bold align-baseline"
                                            [ngClass]="
                                                product.strikeThroughPrice
                                                    ? 'promo-price'
                                                    : product.myAssortment
                                                      ? 'my-assortment-price'
                                                      : ''
                                            ">
                                            <span class="price-value">{{ product.price?.formattedValue }}</span>
                                            / {{ product.unit?.name }}
                                        </span>
                                        <span
                                            class="text-strike text-dark-grey align-baseline ml-2"
                                            *ngIf="product.strikeThroughPrice">
                                            {{ product.strikeThroughPrice.formattedValue }}
                                        </span>
                                    </div>

                                    <div class="comparison-price" *ngIf="product.comparisonPrice">
                                        {{ product.comparisonPrice.formattedValue }}
                                        / {{ product.comparisonUnit?.name }}
                                    </div>

                                    <div class="comparison-price">
                                        {{ product.priceWithTax?.formattedValue }}
                                        / {{ product.unit?.name }}
                                        {{ 'aimo.product.vat' | cxTranslate: { amount: product.taxPercentage } }}
                                    </div>
                                    <div
                                        class="mt-4"
                                        *ngIf="
                                            product.stock.stockLevelStatus === 'outOfStock' && product.similarProducts
                                        ">
                                        <button
                                            class="btn btn-secondary btn-sm"
                                            (click)="scrollTo('similar-products-container')">
                                            {{ 'aimo.product.lookSimilarProducts' | cxTranslate }}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-auto add-to-cart pl-lg-5 pl-md-0 pl-2 ml-auto ml-md-0 noPrint">
                                    <aimo-cx-add-to-cart
                                        *ngIf="
                                            product.purchasable &&
                                            !(
                                                product.stock.stockLevelStatus === 'outOfStock' &&
                                                product.similarProducts
                                            )
                                        "
                                        class="ml-2 ml-md-0 ml-lg-2 d-inline float-right add-product-details"
                                        [product]="product"
                                        [gtmProductAttributes]="createGtmAttributes(0, productSummary)"
                                        [showQuantity]="true"
                                        [isPLP]="modal ? true : false"
                                        [options]="{
                                            displayAddToCart: true,
                                            addToCartString: '',
                                        }"
                                        (afterChange)="onProductChange($event)">
                                    </aimo-cx-add-to-cart>
                                </div>
                            </div>

                            <div class="pt-4 product-description">
                                <ng-container *ngIf="truncate(product.description)">
                                    <ng-container *ngIf="!showDescription">
                                        <span class="noPrint">{{ truncate(product.description) }}</span>
                                        <span class="onlyPrint">{{ product.description }}</span>
                                        <button
                                            class="link text-underline noPrint"
                                            [attr.aria-label]="
                                                'aimo.product.arialLabel.showMoreDescription' | cxTranslate
                                            "
                                            (click)="toggleDescription()">
                                            {{ 'aimo.product.showMoreDescription' | cxTranslate }}
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="showDescription">
                                        {{ product.description }}
                                        <button
                                            class="link text-underline"
                                            [attr.aria-label]="
                                                'aimo.product.arialLabel.showLessDescription' | cxTranslate
                                            "
                                            (click)="toggleDescription()">
                                            {{ 'aimo.product.showLessDescription' | cxTranslate }}
                                        </button>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!truncate(product.description)">
                                    {{ product.description }}
                                </ng-container>
                            </div>

                            <div *ngIf="getClassification(product, 'allergens')?.length > 0">
                                <h5>{{ getClassificationName(product, 'allergens') }}</h5>
                                {{ getClassificationValueList(product, 'allergens') }}
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="user$ | async as user">
                        <div class="product-actions noPrint">
                            <div class="row">
                                <button
                                    *ngIf="loggedUser$ | async"
                                    (click)="toggleFavorite(product)"
                                    class="d-inline col-md-auto col-6 btn-link">
                                    <i
                                        class="aimo-icon icon-sm icon-inline"
                                        [ngClass]="{
                                            'icon-star-filled': product.favorite,
                                            'icon-star': !product.favorite,
                                        }"></i>
                                    <span>{{
                                        (product.favorite ? 'aimo.favorite.remove' : 'aimo.favorite.add') | cxTranslate
                                    }}</span>
                                </button>
                                <button
                                    *ngIf="
                                        !isPikatukku() &&
                                        (user.customerAdmin || !user.isHideNormalOrderTemplatesFromBasicUsers)
                                    "
                                    [id]="'product_summary_add_to_order_template'"
                                    class="d-inline col-md-auto col-6 btn-link"
                                    (click)="addToOrderTemplate(product, 'product_summary_add_to_order_template')">
                                    <i class="aimo-icon icon-file-plus icon-sm icon-inline"></i>
                                    <span>{{ 'aimo.product.addToTemplate' | cxTranslate }}</span>
                                </button>
                                <button
                                    [id]="'product_summary_add_comparison'"
                                    class="d-none d-lg-inline col-md-auto col-6 btn-link"
                                    (click)="addToComparison(product)">
                                    <i class="aimo-icon icon-arrow-left-right icon-sm icon-inline"></i>
                                    <span>{{ 'aimo.product.actions.comparison' | cxTranslate }}</span>
                                </button>
                                <button
                                    [id]="'product_summary_print'"
                                    class="d-inline col-md-auto col-6 btn-link"
                                    (click)="print()">
                                    <i class="aimo-icon icon-printer icon-sm icon-inline"></i>
                                    <span>{{ 'aimo.product.actions.print' | cxTranslate }}</span>
                                </button>
                                <button
                                    [id]="'product_summary_copy_url'"
                                    class="d-inline col-md-auto col-6 btn-link"
                                    [aimoCopyClipboard]="getFullUrl()">
                                    <i class="aimo-icon icon-link icon-sm icon-inline"></i>
                                    <span>{{ 'aimo.product.actions.copyLink' | cxTranslate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-container noPrint" *ngIf="product.productReferences[SIMILAR_PRODUCTS]?.length > 0">
                <a class="anchor" id="similar-products-container">{{ 'aimo.product.similarProducts' | cxTranslate }}</a>
                <aimo-cx-carousel
                    class="carousel-products"
                    role="region"
                    tabindex="-1"
                    itemWidth="10px"
                    [hideIndicators]="true"
                    [items]="getSimilarProducts(product)"
                    [title]="'aimo.product.similarProducts' | cxTranslate"
                    [template]="carouselItem"
                    [idSuffix]="'similar_product'"
                    [carouselType]="alternative">
                </aimo-cx-carousel>
            </div>

            <div class="carousel-container noPrint" *ngIf="product.productReferences[CROSSSELING_PRODUCTS]?.length > 0">
                <aimo-cx-carousel
                    class="carousel-products"
                    role="region"
                    tabindex="-1"
                    itemWidth="10px"
                    [hideIndicators]="true"
                    [items]="getCrossSellingProducts(product)"
                    [title]="'aimo.product.crossSelling' | cxTranslate"
                    [template]="carouselItem"
                    [idSuffix]="'crossselling_product'"
                    [carouselType]="crossSelling">
                </aimo-cx-carousel>
            </div>

            <div class="container">
                <div class="row no-gutters" [ngClass]="{ 'modal-body': modal }">
                    <div class="data-table col-12">
                        <div class="row">
                            <div class="col-md-6 col-12 table-left pr-md-4 order-md-1">
                                <h3 class="mb-0">{{ 'productDetails.nutritional.content' | cxTranslate }}</h3>
                                <div class="table-responsive" #nutritionalContent>
                                    <table class="table mt-4" *ngIf="getClassificationClass(product, 'nutritionFact')">
                                        <thead>
                                            <tr class="no-border">
                                                <th class="text-bold table-divider bg-petrol-light">
                                                    {{ getClassificationClass(product, 'nutritionFact').name }}
                                                </th>
                                                <th class="text-bold table-divider bg-petrol-light text-right">
                                                    / {{ product.nutritionalContentBasisQuantity }}
                                                    {{ product.nutritionalContentBasisQuantityUnit?.name }}
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngIf="getClassificationName(product, 'ENER-E14')">
                                                <td class="table-data">
                                                    {{ getClassificationName(product, 'ENER-E14') }}
                                                </td>
                                                <td class="text-right table-data">
                                                    {{ getClassificationValueWithUnit(product, 'ENER-E14', true) }}
                                                    / {{ getClassificationValueWithUnit(product, 'ENER-KJO', true) }}
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let nutitionName of nutritionFactNames">
                                                <ng-container
                                                    [ngTemplateOutletContext]="{
                                                        product: product,
                                                        name: nutitionName,
                                                    }"
                                                    [ngTemplateOutlet]="nutritionFact"></ng-container>
                                            </ng-container>
                                        </tbody>
                                    </table>

                                    <table class="table mt-4" *ngIf="getClassificationClass(product, 'vitamin')">
                                        <thead>
                                            <tr class="no-border">
                                                <th class="text-bold table-divider bg-petrol-light">
                                                    {{ getClassificationClass(product, 'vitamin').name }}
                                                </th>
                                                <th class="text-bold table-divider bg-petrol-light text-right">
                                                    / {{ product.nutritionalContentBasisQuantity }}
                                                    {{ product.nutritionalContentBasisQuantityUnit?.name }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let vitaminName of vitaminNames">
                                                <ng-container
                                                    [ngTemplateOutletContext]="{
                                                        product: product,
                                                        name: vitaminName,
                                                    }"
                                                    [ngTemplateOutlet]="nutritionFact"></ng-container>
                                            </ng-container>
                                        </tbody>
                                    </table>

                                    <table class="table mt-4" *ngIf="getClassificationClass(product, 'mineral')">
                                        <thead>
                                            <tr class="no-border">
                                                <th class="text-bold table-divider bg-petrol-light">
                                                    {{ getClassificationClass(product, 'mineral').name }}
                                                </th>
                                                <th class="text-bold table-divider bg-petrol-light text-right">
                                                    / {{ product.nutritionalContentBasisQuantity }}
                                                    {{ product.nutritionalContentBasisQuantityUnit?.name }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let mineralName of mineralNames">
                                                <ng-container
                                                    [ngTemplateOutletContext]="{
                                                        product: product,
                                                        name: mineralName,
                                                    }"
                                                    [ngTemplateOutlet]="nutritionFact"></ng-container>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="col-md-6 col-12 order-md-2 order-first table-right">
                                <h3>{{ 'productDetails.specification' | cxTranslate }}</h3>
                                <ng-container *ngIf="product.keyIngredients">
                                    <div class="text-bold mt-4">{{ 'aimo.product.keyIngredients' | cxTranslate }}</div>
                                    {{ product.keyIngredients }}
                                </ng-container>

                                <ng-container *ngIf="product.manualKeyIngredients">
                                    <div class="text-bold mt-4">
                                        {{ 'aimo.product.manualKeyIngredients' | cxTranslate }}
                                    </div>
                                    {{ product.manualKeyIngredients }}
                                </ng-container>

                                <ng-container *ngIf="getNutritionalContent(product, 'allergen')">
                                    <div class="text-bold mt-4">
                                        {{ getNutritionalContent(product, 'allergen').name }}
                                    </div>
                                    {{ getClassificationNameList(product, 'allergen') }}
                                </ng-container>

                                <ng-container *ngIf="getNutritionalContent(product, 'nutritionalClaim')">
                                    <div class="text-bold mt-4">
                                        {{ getNutritionalContent(product, 'nutritionalClaim').name }}
                                    </div>
                                    {{ getClassificationNameList(product, 'nutritionalClaim') }}
                                </ng-container>

                                <ng-container *ngIf="product.preparationInstructions">
                                    <div class="text-bold mt-4">
                                        {{ 'aimo.product.preparationInstructions' | cxTranslate }}
                                    </div>
                                    {{ product.preparationInstructions }}
                                </ng-container>

                                <ng-container *ngIf="product.usageInstructions">
                                    <div class="text-bold mt-4">
                                        {{ 'aimo.product.usageInstructions' | cxTranslate }}
                                    </div>
                                    {{ product.usageInstructions }}
                                </ng-container>

                                <ng-container *ngIf="product.storageInstructions">
                                    <div class="text-bold mt-4">
                                        {{ 'aimo.product.storageInstructions' | cxTranslate }}
                                    </div>
                                    {{ product.storageInstructions }}
                                </ng-container>

                                <ng-container *ngIf="product.safetyData?.length > 0">
                                    <div class="text-bold mt-4">
                                        {{ 'aimo.product.safetyData' | cxTranslate }}
                                    </div>
                                    <div>
                                        <button class="btn-link text-left" (click)="safetyDataExport(product)">
                                            <i class="aimo-icon icon-simple-download icon-sm icon-inline ml-1"></i>
                                            <span>{{ 'aimo.product.safetyDataDownload' | cxTranslate }}</span>
                                        </button>
                                    </div>
                                </ng-container>

                                <div *ngIf="product.countriesOfOrigin">
                                    <div class="text-bold mt-4">{{ 'aimo.product.countryOfOrigin' | cxTranslate }}</div>
                                    <div *ngFor="let country of product.countriesOfOrigin">
                                        {{ country.name }}
                                    </div>
                                </div>

                                <ng-container *ngIf="product.vendor?.name">
                                    <div class="text-bold mt-4">{{ 'aimo.product.vendor' | cxTranslate }}</div>
                                    {{ product.vendor?.name }}
                                </ng-container>

                                <ng-container *ngIf="product.fishingInformation?.length > 0">
                                    <div *ngFor="let fishInfo of product.fishingInformation">
                                        <div class="text-bold mt-4">
                                            {{ 'aimo.product.fishingInformation' | cxTranslate }}
                                            {{ fishInfo.species }} ({{ fishInfo.speciesLatinName }}):
                                        </div>
                                        <span *ngFor="let catchInformation of fishInfo.catchInformation">
                                            <span class="text-bold mt-4">
                                                {{ 'aimo.product.productionMethod' | cxTranslate }}
                                            </span>
                                            {{ catchInformation.productionMethod }},
                                            <span class="text-bold mt-4">
                                                {{ 'aimo.product.catchMethod' | cxTranslate }}
                                            </span>
                                            {{ catchInformation.catchMethod }},
                                            <span class="text-bold mt-4">
                                                {{ 'aimo.product.catchArea' | cxTranslate }}
                                            </span>
                                            {{ catchInformation.catchArea }}
                                        </span>
                                    </div>
                                </ng-container>

                                <div>
                                    <div class="text-bold mt-4">{{ 'aimo.product.unitConversions' | cxTranslate }}</div>
                                    <div *ngFor="let uc of product.unitConversions">
                                        <div>
                                            1 {{ uc.unit.name }} = {{ uc.conversionToBaseUnit }}
                                            {{ product.baseUnit.name }}
                                            <ng-container *ngIf="uc.ean">(EAN {{ uc.ean }})</ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #nutritionFact let-name="name" let-product="product" let-intend="intend">
    <tr *ngIf="getClassificationName(product, name)">
        <td class="table-data" [ngClass]="{ intend: intend }">
            {{ getClassificationName(product, name) }}
        </td>
        <td class="table-data text-right">
            {{ getClassificationValueWithUnit(product, name) }}
        </td>
    </tr>
</ng-template>

<ng-template #carouselItem let-item="item" let-index="index" let-idSuffix="idSuffix" let-gtmListId="gtmListId">
    <aimo-product-grid-item
        [product]="item"
        [idSuffix]="idSuffix + '_' + index"
        [gtmProductAttributes]="createGtmAttributes(index, gtmListId)"
        [openProductPageFromLink]="!modal">
    </aimo-product-grid-item>
</ng-template>

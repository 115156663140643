import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

import { AimoGenericLinkModule } from '../../../shared/generic-link/aimo-generic-link.module';
import { SharedModule } from '../../../shared/shared.module';
import { AimoNavigationUiModule } from '../navigation-ui/aimo-navigation-ui.module';

import { AimoMainNavigationComponent } from './aimo-main-navigation.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AimoGenericLinkModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                AimoMainNavigationComponent: {
                    component: AimoMainNavigationComponent,
                },
            },
        }),
        AimoNavigationUiModule,
        SharedModule,
        I18nModule,
    ],
    declarations: [AimoMainNavigationComponent],
    exports: [AimoMainNavigationComponent],
})
export class AimoMainNavigationModule {}

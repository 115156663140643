import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@spartacus/core';
import { ActiveFacetsComponent, FacetService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoFacet, AimoFacetValue, AimoProductSearchPage } from '../../../../model/product.model';
import { AimoAssortmentService } from '../../../../service/product/aimo-assortment.service';

@Component({
    selector: 'aimo-active-facets',
    templateUrl: './aimo-active-facets.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AimoActiveFacetsComponent extends ActiveFacetsComponent {
    private _productSearchPage: AimoProductSearchPage;
    activeFacetList$: Observable<Breadcrumb[]>;
    // eslint-disable-next-line
    clearUrlParams: { query: any };

    constructor(
        protected facetService: FacetService,
        protected route: ActivatedRoute,
        protected assortmentService: AimoAssortmentService,
    ) {
        super(facetService);

        this.clearUrlParams = {
            query: this.route.snapshot.queryParams.query?.replace(
                this.route.snapshot.queryParams.query?.substring(
                    this.route.snapshot.queryParams.query?.indexOf(
                        ':',
                        this.route.snapshot.queryParams.query?.indexOf(':') + 1,
                    ),
                ),
                '',
            ), // clear everything after second :
        };
    }

    @Input()
    set productSearchPage(value: AimoProductSearchPage) {
        this._productSearchPage = value;

        this.activeFacetList$ = this.facetList$.pipe(
            map((fl) => {
                const breadCrumbs = fl.facets
                    .filter(
                        (facet) =>
                            (facet as AimoFacet).code !== 'category' && (facet as AimoFacet).code !== 'allCategories',
                    )
                    .flatMap((facet) =>
                        facet.values
                            .filter((value) => value.selected)
                            .flatMap(
                                (value) =>
                                    ({
                                        facetCode: (facet as AimoFacet).code,
                                        facetName: facet.name,
                                        facetValueCode: (value as AimoFacetValue).code,
                                        facetValueName: value.name,
                                        removeQuery: value.query,
                                    }) as Breadcrumb,
                            ),
                    );
                if (this._productSearchPage.products.length === 0) {
                    return breadCrumbs.concat(
                        ...this._productSearchPage.breadcrumbs
                            ?.filter((af) => af.facetValueCode !== '0' && af.facetCode !== 'category')
                            .filter(
                                (af) =>
                                    breadCrumbs.find(
                                        (bc) =>
                                            af.facetValueCode === bc.facetValueCode && af.facetCode === bc.facetCode,
                                    ) === undefined,
                            ),
                    );
                }
                return breadCrumbs;
            }),
        );
    }

    isMyAssortmentSelected(): Observable<boolean> {
        return this.assortmentService.isOnlyMyAssortment();
    }

    removeMyAssortment(): void {
        this.assortmentService.setOnlyMyAssortment(false);
    }
}

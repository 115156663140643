import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AimoResetPasswordComponentService } from './aimo-reset-password-component.service';

@Component({
    selector: 'aimo-reset-password',
    templateUrl: './aimo-reset-password.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line
    host: {class: 'user-form'},
})
export class AimoResetPasswordComponent implements OnInit, OnDestroy {
    form: FormGroup;
    isUpdating$: Observable<boolean>;

    subscription: Subscription = new Subscription();

    token: string;

    constructor(
        protected service: AimoResetPasswordComponentService,
        protected globalMessage: GlobalMessageService,
    ) {}

    ngOnInit(): void {
        this.form = this.service.form;
        this.isUpdating$ = this.service.isUpdating$;
        this.subscription.add(
            this.service.resetToken$.pipe(take(1)).subscribe((tokenParam) => {
                if (tokenParam === undefined) {
                    this.globalMessage.add(
                        { key: 'resetPassword.token.invalid' },
                        GlobalMessageType.MSG_TYPE_ERROR,
                        5000,
                    );
                } else {
                    this.token = tokenParam;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onSubmit(token: string): void {
        this.service.resetPassword(token);
    }
}

// eslint-disable-next-line
import { ElementRef } from '@angular/core';

export function openCloseSpinner(doc: Document, show: boolean): void {
    const node = doc?.getElementById('aimo-spinner');
    if (node && node.classList) {
        if (show) {
            node.classList.add('d-block');
        } else {
            node.classList.remove('d-block');
        }
    }
}

export function openCloseInnerSpinner(show: boolean, divElement?: ElementRef): void {
    if (show) {
        divElement?.nativeElement?.classList?.remove('d-none');
    } else {
        divElement?.nativeElement?.classList?.add('d-none');
    }
}

export function isOpenSpinner(divElement?: ElementRef): boolean {
    return !divElement?.nativeElement?.classList?.contains('d-none');
}

export function hideDiv(div: HTMLElement): void {
    div.classList.add('d-none');
}

export function toggleSearchOption($event: MouseEvent, searchClass?: string): void {
    const innerSearch = ($event.target as HTMLElement).closest(searchClass);
    innerSearch?.classList.toggle('cx-wide-option');
}

export function hideSearchOptions($event?: MouseEvent, searchClass?: string): void {
    const searchOptions = this.document.querySelectorAll(searchClass);
    searchOptions.forEach((arrayElement) => {
        arrayElement.classList.remove('cx-wide-option');
    });

    if ($event) {
        const inputSearch = ($event.target as HTMLElement).closest(searchClass);
        this.inputRange.toggleRangeContent(null, inputSearch);
    }
}

export function toggleFunctions(showHide: HTMLElement, closestClass: string, elementsClass: string): void {
    const functionElements = showHide.closest(closestClass).querySelectorAll(elementsClass);
    functionElements.forEach((el) => {
        if (el.classList.contains('d-md-inline-block') && !el.classList.contains('no-mobile')) {
            el.classList.toggle('d-none');
        }
    });
    showHide.classList.add('d-none');
}

export function addClassIfDoesNotExist(htmlElement: Element, className: string): void {
    if (!htmlElement.classList.contains(className)) {
        htmlElement.classList.add(className);
    }
}

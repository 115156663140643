import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { RoutingConfig, UrlParsingService } from '@spartacus/core';

import { AimoRoutingConfig } from '../../model/cms.model';

@Injectable({ providedIn: 'root' })
export class AimoProtectedRoutesService {
    constructor(
        protected config: RoutingConfig,
        protected urlParsingService: UrlParsingService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    /**
     * Splits the url by slashes
     */
    protected getSegments(url: string): string[] {
        return (url || '').split('/');
    }

    protected get routingConfig(): AimoRoutingConfig['routing'] {
        return this.config && this.config.routing;
    }

    /**
     * Returns 'protected' property (boolean) from routing config
     *
     * @returns boolean
     */
    public get shouldProtect(): boolean {
        return !!this.routingConfig?.protected;
    }

    /**
     * Tells if the url is protected
     */
    isUrlProtected(urlSegments: string[]): boolean {
        if (this.isPikatukku() && urlSegments.filter((s) => s === 'p').length === 1) {
            // product details
            return false;
        }
        return this.shouldProtect && !this.matchAnyPath(urlSegments, this.getNonProtectedPaths());
    }

    /**
     * Tells whether the url matches at least one of the paths
     */
    protected matchAnyPath(urlSegments: string[], pathsSegments: string[][]): boolean {
        return pathsSegments.some((pathSegments) => this.matchPath(urlSegments, pathSegments));
    }

    /**
     * Tells whether the url matches the path
     */
    protected matchPath(urlSegments: string[], pathSegments: string[]): boolean {
        return this.urlParsingService.matchPath(urlSegments, pathSegments);
    }

    protected getNonProtectedPaths(): string[][] {
        const isPikatukku = this.isPikatukku();
        return Object.values(this.routingConfig?.routes ?? {})
            .reduce<string[]>(
                (acc, routeConfig) =>
                    routeConfig.paths &&
                    (routeConfig.protected === false || (routeConfig.allowPikatukku && isPikatukku)) &&
                    routeConfig.paths.length
                        ? acc.concat(routeConfig?.paths ?? [])
                        : acc,
                [],
            )
            .map((path) => this.getSegments(path));
    }

    private isPikatukku(): boolean {
        return this.document.location.host.indexOf('pikatukku') >= 0;
    }
}

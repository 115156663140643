import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { LanguageService, RoutingService, TranslationService } from '@spartacus/core';
import { CurrentProductService, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AimoCart } from '../../../model/cart.model';
import { AimoProduct } from '../../../model/product.model';
import { AimoB2BUnitOption } from '../../../model/user.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGTMProductAttributes, GTMCartType, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../service/product/aimo-current-product.service';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { AimoSpinnerService } from '../../shared/spinner/aimo-spinner.service';

import { AimoOrderTemplateDialogData, CART_VISIBILITY } from './aimo-order-template-layout.config';

const CREATE_NEW_TEMPLATE_CODE = 'new_template';

@Component({
    selector: 'aimo-order-template-modal',
    templateUrl: './aimo-order-template-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderTemplateModalComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    createNew: boolean;
    oldCartId: string;
    importFrom: boolean;
    orderHistoryDay: string;
    edit: AimoCart;
    openProductModalAfterClose: boolean;
    focusIdAfterClose: string;
    product: AimoProduct;
    form: FormGroup;
    templates$: Observable<AimoCart[]>;
    visibilities: VisibilityObject[];
    searchUnits: string;
    availableUnits: AimoB2BUnitOption[];
    submitted = false;
    isAllUnitsSelected = false;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
        protected eRef: ElementRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected cdr: ChangeDetectorRef,
        protected currentProductService: CurrentProductService,
        protected languageService: LanguageService,
        protected translation: TranslationService,
        @Inject(DOCUMENT) private document: Document,
        protected userService: AimoUserService,
        protected spinnerService: AimoSpinnerService,
    ) {
        this.launchDialogService.data$
            .subscribe((dialogData: AimoOrderTemplateDialogData) => {
                this.createNew = dialogData.createNew;
                this.product = dialogData.addToTemplateProduct;
                this.oldCartId = dialogData.oldCartId;
                this.importFrom = dialogData.importFrom;
                this.orderHistoryDay = dialogData.orderHistoryDay;
                this.openProductModalAfterClose = dialogData.openProductModalAfterClose;
                this.focusIdAfterClose = dialogData.focusIdAfterClose;
                this.edit = dialogData.edit;
            })
            .unsubscribe();

        this.userService
            .get()
            .subscribe((user) => {
                this.visibilities = [{ id: CART_VISIBILITY.PUBLIC }];
                if (user.customerAdmin) {
                    this.availableUnits = user.allAvailableUnits;
                    this.visibilities.push({ id: CART_VISIBILITY.CENTRAL });
                }
                if (!this.edit || user.uid === this.edit.user.uid) {
                    this.visibilities.push({ id: CART_VISIBILITY.PRIVATE });
                }
            })
            .unsubscribe();
    }

    ngOnDestroy(): void {
        this.spinnerService.setTitle(null);
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.templates$ = this.translation.translate('aimo.ordertemplate.templateCode.createNew').pipe(
            switchMap((emptyName) =>
                this.activeCartService.getOrderTemplates(undefined, undefined, !this.importFrom).pipe(
                    map((cartList) => cartList.carts),
                    map((templates) =>
                        [
                            this.importFrom
                                ? null
                                : {
                                      name: emptyName,
                                      code: CREATE_NEW_TEMPLATE_CODE,
                                  },
                            ...templates.map((template) => ({
                                ...template,
                                name: template.name + ' \u0028' + template.totalItems + '\u0029',
                                visibility: template.visibility,
                            })),
                        ].filter((v) => v !== null),
                    ),
                ),
            ),
        );
        this.initForm();
    }

    private initForm(): void {
        const controls = {};
        if (this.importFrom && this.edit === undefined) {
            controls['templateCode'] = new FormControl(null, [Validators.required]);
        } else {
            if (this.showCreateNewTemplate()) {
                controls['templateName'] = new FormControl(this.edit ? this.edit.name : null);
                controls['templateVisibility'] = new FormControl(
                    this.edit ? this.edit.visibility : CART_VISIBILITY.PRIVATE,
                );

                this.availableUnits?.forEach(
                    (unit) =>
                        (controls['unit_' + unit.uid] = new FormControl(
                            this.edit ? this.edit.assignedUnits?.find((a) => a.uid === unit.uid) !== undefined : false,
                        )),
                );
            }
            if (!this.createNew && !this.edit) {
                controls['templateCode'] = new FormControl(null, [Validators.required]);
                controls['quantity'] = new FormControl(1, [Validators.required]);
            }
        }
        this.form = new FormGroup(controls);
    }

    showCreateNewTemplate(): boolean {
        return (
            this.edit !== undefined ||
            (!this.importFrom &&
                (this.createNew ||
                    this.form === undefined ||
                    this.form.controls['templateCode'].value === CREATE_NEW_TEMPLATE_CODE))
        );
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.ORDER_TEMPLATE);
        this.launchDialogService.closeDialog('closed');
        if (this.openProductModalAfterClose) {
            this.openProductModal();
        }
        if (this.focusIdAfterClose && this.document.getElementById(this.focusIdAfterClose)) {
            this.document.getElementById(this.focusIdAfterClose).focus();
        }
    }

    submit(): void {
        if (this.isValid() && !this.submitted) {
            this.submitted = true;
            if (this.importFrom) {
                this.activeCartService.orderTemplateImport(this.form.controls['templateCode'].value);
                this.closeDialog();
            } else if (this.showCreateNewTemplate()) {
                if (this.edit) {
                    this.submitEdit(true);
                } else {
                    this.submitCreate();
                }
            } else {
                this.addTemplateEntry(this.form.controls['templateCode'].value);
                this.openProductModalAfterClose = false;
                this.closeDialog();
            }
        }
    }

    private submitCreate(): void {
        this.subscription.add(
            this.activeCartService
                .createOrderTemplate(
                    this.form.controls['templateVisibility'].value,
                    this.form.controls['templateName'].value,
                    this.oldCartId,
                    this.orderHistoryDay,
                )
                .subscribe((template) => {
                    if (this.product) {
                        this.addTemplateEntry(template.code);
                    }
                    this.openProductModalAfterClose = false;
                    if (this.getAssignedUnitsFromForm()?.length > 0) {
                        this.edit = template;
                        this.submitEdit(false, '/ordertemplates/' + template.code);
                    } else {
                        this.closeDialog();
                        this.routingService.go('/ordertemplates/' + template.code);
                    }
                }),
        );
    }

    private submitEdit(reload: boolean, redirectPath?: string): void {
        this.spinnerService.setTitle('aimo.ordertemplates.savetitle');
        this.subscription.add(
            this.activeCartService
                .editOrderTemplate({
                    code: this.edit.code,
                    visibility: this.form.controls['templateVisibility'].value,
                    name: this.form.controls['templateName'].value,
                    assignedUnits: this.getAssignedUnitsFromForm(),
                })
                .subscribe(() => {
                    this.closeDialog();
                    if (redirectPath) {
                        this.routingService.go(redirectPath);
                    }
                }),
        );
    }

    createGtmAttributes(): AimoGTMProductAttributes {
        return {
            item_list_id: GTMItemListId.order_template,
            cart_type: GTMCartType.orderTemplate,
        } as AimoGTMProductAttributes;
    }

    private addTemplateEntry(templateCode: string): void {
        this.activeCartService.addAimoEntry(
            this.product.code,
            this.form.controls['quantity'].value,
            this.createGtmAttributes(),
            templateCode,
        );
    }

    isValid(): boolean {
        return (
            this.form.valid &&
            (!this.showCreateNewTemplate() ||
                this.importFrom ||
                (this.form.controls['templateVisibility'].value && this.form.controls['templateName'].value))
        );
    }

    private getAssignedUnitsFromForm(): AimoB2BUnitOption[] {
        return this.availableUnits
            ?.filter((u) => this.form.controls['unit_' + u.uid].value)
            .map((u) => ({ uid: u.uid }) as AimoB2BUnitOption);
    }

    openProductModal(): void {
        (this.currentProductService as AimoCurrentProductService).openProductModal(
            this.product.code,
            this.createGtmAttributes(),
            null,
            null,
            null,
            this.focusIdAfterClose,
        );
    }

    @ViewChild('closeButton')
    closeButton: ElementRef;

    getTheFocusBack(event: Event): void {
        event.preventDefault();
        this.closeButton.nativeElement.focus();
    }

    isCentralSelected(): boolean {
        return this.form.controls.templateVisibility?.value === CART_VISIBILITY.CENTRAL && this.showCreateNewTemplate();
    }

    changeSearch(search: string): void {
        this.searchUnits = search ? search : '';
        this.cdr.detectChanges();
    }

    toggleSelectAllUnits(): void {
        Object.keys(this.form.controls)
            .filter((key) => key.indexOf('unit_') === 0)
            .forEach((key) => this.form.controls[key].setValue(!this.isAllUnitsSelected));
    }
}

interface VisibilityObject {
    id: CART_VISIBILITY;
}

<div class="contact-item" *ngIf="data$ | async as data">
    <a class="anchor" *ngIf="data.anchorId" [id]="data.anchorId">{{ data.title }}</a>
    <h2>{{ data.title }}</h2>
    <p [innerHTML]="data.text | safeHtml">{{ data.text }}</p>
    <div class="contact-item-data">
        <div class="row" *ngIf="data.address">
            <div class="col-12 col-md-3">
                <div class="label">{{ 'aimo.contact.address' | cxTranslate }}</div>
            </div>
            <div class="col-12 col-md-9">
                <div [innerHTML]="data.address">{{ data.address }}</div>
                <div class="mt-2" *ngIf="data.addressLink && data.addressUrl">
                    <a [href]="data.addressUrl" target="_blank">{{ data.addressLink }}</a>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="data.serviceHours">
            <div class="col-12 col-md-3">
                <div class="label">{{ 'aimo.contact.serviceHours' | cxTranslate }}</div>
            </div>
            <div class="col-12 col-md-9">
                <div [innerHTML]="data.serviceHours">{{ data.serviceHours }}</div>
            </div>
        </div>
        <div class="row" *ngIf="data.email">
            <div class="col-12 col-md-3">
                <div class="label">{{ 'aimo.contact.email' | cxTranslate }}</div>
            </div>
            <div class="col-12 col-md-9">
                <div [innerHTML]="data.email">{{ data.email }}</div>
            </div>
        </div>
        <div class="row" *ngIf="data.phone">
            <div class="col-12 col-md-3">
                <div class="label">{{ 'aimo.contact.phone' | cxTranslate }}</div>
            </div>
            <div class="col-12 col-md-9">
                <div [innerHTML]="data.phone">{{ data.phone }}</div>
            </div>
        </div>
        <div class="row" *ngIf="isPikatukku() && openingHours$ | async as openingHours">
            <div class="col-12 col-md-3">
                <div class="label">{{ 'aimo.contact.openingHours' | cxTranslate }}</div>
            </div>
            <div class="col-12 col-md-9">
                <ng-container *ngFor="let hour of openingHours">
                    <ng-container *ngIf="data.title.toLowerCase().indexOf(hour.location.toLowerCase()) >= 0">
                        <div *ngFor="let day of hour.days">
                            <b>{{ 'aimo.calendar.weekday.short.' + day.dayOfWeek | cxTranslate }}</b
                            >:
                            <span *ngIf="!day.closed">{{ day.opening_hour }}-{{ day.closing_hour }}</span>
                            <span *ngIf="day.closed">{{ 'aimo.pikatukku.closed' | cxTranslate }}</span>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="mt-2" *ngIf="data.openingHoursException && data.openingHoursExceptionUrl">
                    <a [href]="data.openingHoursExceptionUrl" target="_blank">{{ data.openingHoursException }}</a>
                </div>
            </div>
        </div>
        <div class="contact-item-map" *ngIf="data.googleMaps">
            <iframe
                [src]="data.googleMaps | safeUrl"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</div>

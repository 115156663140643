import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { AuthService, CmsSearchBoxComponent, RoutingService, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';
import { AimoHamburgerMenuService } from '../../../layout/hamburger-menu/aimo-hamburger-menu.service';
import { AimoCart, AimoOrderEntry } from '../../../model/cart.model';
import { AimoProduct, AimoSearchBoxConfig, AimoSearchResults } from '../../../model/product.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGTMProductAttributes, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoAssortmentService } from '../../../service/product/aimo-assortment.service';
import { AimoCurrentProductService } from '../../../service/product/aimo-current-product.service';
import { AimoSearchBoxComponentService } from '../../../service/product/search/aimo-search-box-component.service';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { ProductEvent } from '../../../shared/item-counter/aimo-item-counter.component';
import { DateUtils } from '../../../shared/util/date-utils';
import { AimoSpinnerService } from '../../shared/spinner/aimo-spinner.service';

import { AimoSearchBoxComponent } from './aimo-search-box.component';

@Component({
    selector: 'aimo-cx-searchbox-result',
    templateUrl: './aimo-search-box-result.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoSearchBoxResultComponent extends AimoSearchBoxComponent {
    result: AimoSearchResults;
    form: FormGroup;

    @ViewChild('resultContainer')
    resultContainer: ElementRef;

    @Output() onScroll = new EventEmitter<ScrollEvent>();
    @Output() onShowAll = new EventEmitter<void>();
    @Output() closeSearchResult = new EventEmitter<void>();

    @Input() searchInputValue = '';
    previousSearchInputValue = undefined;
    @Input() isCartSearchBox = false;

    // external cart id e.g. order template id
    @Input() cartId = undefined;
    @Input() baseOrder = false;

    isLoggedInUser$: Observable<boolean>;

    @Input()
    set setCart(cart: AimoCart) {
        this.cart = cart;
        this.initForm(this.result);
    }

    @Input()
    set setResult(inputResult: AimoSearchResults) {
        const result = inputResult
            ? ({ ...inputResult, products: [...inputResult.products?.map((p) => ({ ...p }))] } as AimoSearchResults)
            : undefined;
        if (
            result &&
            result.products &&
            result.pagination &&
            (this.result?.pagination?.currentPage !== result?.pagination?.currentPage ||
                this.previousSearchInputValue !== this.searchInputValue ||
                result.pagination.currentPage === 0)
        ) {
            // prevent duplicate rendering
            if (result.pagination.currentPage === 0) {
                this.result = result;
            } else {
                this.result = {
                    //scrolling will append the product list
                    ...result,
                    products: this.result?.products?.concat(result.products),
                };
            }
            this.result = { ...result, products: this.result.products?.map((p) => ({ ...p })) };
            this.initForm(this.result);
        } else if (this.searchInputValue.indexOf(this.previousSearchInputValue) < 0) {
            this.result = { ...this.result, products: [] };
        }
        this.previousSearchInputValue = this.searchInputValue;
        this.cdr.detectChanges();
    }

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected aimoUserService: AimoUserService,
        protected searchBoxComponentService: AimoSearchBoxComponentService,
        protected componentData: CmsComponentData<CmsSearchBoxComponent>,
        protected routingService: RoutingService,
        protected eRef: ElementRef,
        protected cdr: ChangeDetectorRef,
        protected currentProductService: AimoCurrentProductService,
        protected spinnerService: AimoSpinnerService,
        protected actions$: Actions,
        protected assortmentService: AimoAssortmentService,
        protected aimoRoutingService: AimoRoutingService,
        protected hamburgerMenuService: AimoHamburgerMenuService,
        protected renderer: Renderer2,
        protected auth: AuthService,
        protected winRef: WindowRef,
        @Inject(DOCUMENT) protected document: Document,
    ) {
        super(
            activeCartService,
            aimoUserService,
            searchBoxComponentService,
            componentData,
            routingService,
            eRef,
            spinnerService,
            cdr,
            actions$,
            aimoRoutingService,
            hamburgerMenuService,
            renderer,
            winRef,
            document,
        );
        this.isLoggedInUser$ = this.auth.isUserLoggedIn();
    }

    private initForm(result: AimoSearchResults): void {
        const controls = {};
        result.products?.forEach((product) => {
            const qty =
                product.tempQuantity ??
                this.cart?.dayGroupedEntries
                    ?.filter((d) => d.active)
                    ?.flatMap((d) => d.entries)
                    ?.filter((e) => e.product.code === product.code)
                    .map((e) => e.quantity)
                    .find((e) => e) ??
                '';
            controls[product.code] = new UntypedFormControl(qty);
        });
        this.form = new FormGroup(controls);

        result.products
            ?.filter((product) => product.tempQuantity !== undefined)
            .forEach((product) => this.form.controls[product.code].markAsDirty());
    }

    getQuantityControl(product: AimoProduct): UntypedFormControl {
        return this.form.controls[product.code] as UntypedFormControl;
    }

    changeQuantity(event$: ProductEvent, product: AimoProduct): void {
        product.tempQuantity = event$.value;
        if (event$.buttonClicked) {
            //   this.activeCartService.addEntry(event$.product.code, event$.value);
        } else if (event$.enterPressed) {
            this.addAllProducts();
        }
    }

    addAllProducts(): void {
        const entries: AimoOrderEntry[] = Object.keys(this.form.controls)
            .filter((key) => this.form.controls[key].value !== '' && this.form.controls[key].dirty)
            .map((key) => {
                const product = this.result.products.find((p) => p.code === key);
                return {
                    // unit: product.unit,
                    //  basePrice: product.price,
                    //  comparisonPrice: product.comparisonPrice,
                    //  comparisonUnit: product.comparisonUnit,
                    product: { code: product.code },
                    quantity: parseFloat(this.form.controls[key].value),
                    gtmItemListId: this.determineListId(),
                } as AimoOrderEntry;
            })
            .filter((p) => p.quantity > 0);

        this.activeCartService.addAimoEntries(entries, this.cartId, this.determineOrigin(), this.searchInputValue);

        this.closeSearchResult.emit();
    }

    toggleFavorite(product: AimoProduct): void {
        this.aimoUserService.toggleFavorite(product);
        product.favorite = !product.favorite;
        this.cdr.detectChanges();
    }

    scrollResults(): void {
        this.onScroll.emit({
            toPage: this.result.pagination.currentPage + 1,
            lastFocusedElementId: (this.config as AimoSearchBoxConfig)?.lastFocusedElementId,
        } as ScrollEvent);
        this.config = { ...this.config, lastFocusedElementId: undefined } as AimoSearchBoxConfig;
    }

    showAll(): void {
        this.onShowAll.emit();
    }

    stopScrolling(): boolean {
        return this.result.pagination.currentPage + 1 === this.result.pagination.totalPages;
    }

    openProductModal(product: AimoProduct, idx: number, sourceId: string, scrollToId?: string): void {
        this.currentProductService.openProductModal(
            product.code,
            this.createGtmAttributes(idx),
            scrollToId,
            null,
            null,
            sourceId,
        );
        if (this.isMobile) {
            this.closeSearchResult.emit();
        }
    }

    calculateSizeInBaseUnits(product: AimoProduct): number {
        return parseFloat(this.getQuantityControl(product).value) * product.sizeInBaseUnits;
    }

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    createGtmAttributes(idx: number): AimoGTMProductAttributes {
        return {
            index: idx,
            item_list_id: this.isCartSearchBox ? GTMItemListId.cart_suggestive_search : GTMItemListId.suggestive_search,
        } as AimoGTMProductAttributes;
    }

    getMaxQuantity(product: AimoProduct): number {
        return this.cartId
            ? 999999
            : product.stock.futureStockLevel
              ? product.stock.futureStockLevel
              : product.stock.stockLevel;
    }

    searchWithFullAssortment(event: UIEvent): void {
        this.assortmentService.setOnlyMyAssortment(false);
        this.routingService.go('/search/' + this.searchInputValue);
        this.close(event);
    }

    navigateToKeyword(url: string): void {
        this.document.location.href = url;
    }

    isSmallerScrollArea(): boolean {
        const notification = <HTMLElement>this.document.body.querySelector('.Notification');
        return !this.isCartSearchBox && notification.offsetHeight > 0;
    }
}

export interface ScrollEvent {
    toPage: number;
    lastFocusedElementId: string;
}

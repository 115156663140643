import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Optional } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CmsSearchBoxComponent, RoutingService, WindowRef } from '@spartacus/core';
import { CmsComponentData, SearchBoxComponent, SearchBoxComponentService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { B2BUnitOption } from '../../../model/user.model';
import { SELECT_UNIT_SUCCESS } from '../../../service/user/aimo-user.action';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { openCloseSpinner } from '../../shared/utils/spinner/aimo-spinner-utils';

const HAS_SEARCH_RESULT_CLASS = 'has-b2bunit-searchbox-results';

@Component({
    selector: 'aimo-cx-customer-search-component',
    templateUrl: './aimo-customer-search.component.html',
})
export class AimoCustomerSearchComponent extends SearchBoxComponent {
    searchResult$: Observable<B2BUnitOption[]>;

    constructor(
        protected userService: AimoUserService,
        protected searchBoxComponentService: SearchBoxComponentService,
        @Optional()
        protected componentData: CmsComponentData<CmsSearchBoxComponent>,
        @Inject(DOCUMENT) private document: Document,
        protected routingService: RoutingService,
        protected eRef: ElementRef,
        protected actions$: Actions,
        protected winRef: WindowRef,
    ) {
        super(searchBoxComponentService, componentData, winRef, routingService);
    }

    clearAndClose(event: UIEvent): void {
        this.searchInput.nativeElement.classList.add('d-none');
        this.close(event, true);
        this.clear(this.searchInput.nativeElement);
        this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, false);
    }

    onlyClear(): void {
        this.searchInput.nativeElement.value = '';
        this.clearResults();
    }

    searchB2BUnits(query: string): void {
        this.searchResult$ = this.userService.searchUnits(query).pipe(
            tap((result) => {
                this.open();
                this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, result.length > 0);
            }),
        );
    }

    open(): void {
        super.open();
    }

    clearResults(): void {
        this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, false);
    }

    toggleBodyClass(className: string, add?: boolean): void {
        if (add === undefined) {
            this.document.body.classList.toggle(className);
        } else {
            add ? this.document.body.classList.add(className) : this.document.body.classList.remove(className);
        }
    }

    selectUnit(event: UIEvent, uid: string): void {
        this.userService.selectUnit(uid, true);

        const sub = this.actions$.subscribe((action) => {
            if (action.type === SELECT_UNIT_SUCCESS) {
                this.clearAndClose(event);
                this.document.body.click(); //force popup close
                //  this.winRef.location.reload();
                openCloseSpinner(this.document, false);
                sub.unsubscribe();
            }
        });
    }

    @HostListener('document:click', ['$event'])
    clickOut(event): void {
        if (!this.eRef?.nativeElement.contains(event.target)) {
            this.onlyClear();
        }
    }

    nextFocus(event: KeyboardEvent, index: number, size: number): void {
        event.preventDefault();
        if (index < size - 1) {
            this.setFocus(index + 1);
        }
    }

    previousFocus(event: KeyboardEvent, index: number): void {
        event.preventDefault();
        if (index > 0) {
            this.setFocus(index - 1);
        } else {
            this.document.getElementById('customer_search')?.focus();
        }
    }

    private setFocus(index: number): void {
        this.document.getElementById('customer_search_' + index)?.focus();
    }
}

import {Injectable} from '@angular/core';
import {LoginEvent, LogoutEvent, Query, QueryService, RoutingService, UserIdService} from '@spartacus/core';
import {UserAccountConnector, UserAccountService} from '@spartacus/user/account/core';
import {UserAccountChangedEvent} from '@spartacus/user/account/root';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {AimoUser} from '../../model/user.model';
import {AimoUserAccountConnector} from '../../shared/connectors/aimo-user-account.connector';

@Injectable({
    providedIn: 'root',
})
export class AimoUserAccountService extends UserAccountService {

    constructor(
        userAccountConnector: UserAccountConnector,
        userIdService: UserIdService,
        query: QueryService,
        protected routingService: RoutingService,
        protected aimoUserAccountConnector: AimoUserAccountConnector,
    ) {
        super(userAccountConnector, userIdService, query);
        this.userQuery = this.query.create(
            () =>
                this.userIdService.takeUserId(true)
                    .pipe(switchMap((userId) =>
                        this.aimoUserAccountConnector.get(userId))
                    ),
            {
                reloadOn: [UserAccountChangedEvent],
                resetOn: [LoginEvent, LogoutEvent],
            },
        );
    }

    get(): Observable<AimoUser | undefined> {
        return this.userQuery.get();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import {
    AuthActions,
    ConverterService,
    Occ,
    OccEndpointsService,
    OccProductSearchAdapter,
    PRODUCT_SEARCH_PAGE_NORMALIZER,
    PRODUCT_SUGGESTION_NORMALIZER,
    ProductSearchAdapter,
    ProductSearchPage,
    UserIdService,
    withdrawOn,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, pluck, switchMap } from 'rxjs/operators';

import { AimoSearchConfig } from '../../../model/misc.model';
import { AimoDeliveryDateService } from '../../routecalendar/aimo-delivery-date.service';
import { AimoUserService } from '../../user/aimo-user.service';
import { AimoAssortmentService } from '../aimo-assortment.service';
import { AimoRecentProductsService } from '../aimo-recent-proucts.service';

import Suggestion = Occ.Suggestion;

@Injectable({
    providedIn: 'root',
})
export class AimoOccProductSearchAdapter extends OccProductSearchAdapter implements ProductSearchAdapter {
    private contextChange$: Observable<never>;

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected converter: ConverterService,
        protected userService: AimoUserService,
        protected userIdService: UserIdService,
        protected deliveryDateService: AimoDeliveryDateService,
        protected assortmentService: AimoAssortmentService,
        protected actions$: Actions,
        protected recentProductsService: AimoRecentProductsService,
    ) {
        super(http, occEndpoints, converter);
        this.contextChange$ = this.actions$.pipe(ofType(AuthActions.LOGOUT));
    }

    search(query: string, searchConfig: AimoSearchConfig): Observable<ProductSearchPage> {
        return this.getSearchEndpoint2(query, searchConfig, 'productSearch').pipe(
            mergeMap((url) => {
                return this.http.get(url).pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER));
            }),
            catchError((err) => {
                // eslint-disable-next-line
                console.error(err);
                return of();
            }),
        );
    }

    excelExportProducts(query: string, searchConfig: AimoSearchConfig): Observable<ArrayBuffer> {
        return this.getSearchEndpoint2(query, searchConfig, 'excelExportProducts').pipe(
            mergeMap((url) =>
                // eslint-disable-next-line
                this.http.get<ArrayBuffer>(url, { responseType: 'arraybuffer' as any }),
            ),
        );
    }

    safetyDataExport(code: string): Observable<ArrayBuffer> {
        const url = this.occEndpoints.buildUrl('safetyDataExport', {
            urlParams: { code },
        });
        return this.http.get<ArrayBuffer>(url, { responseType: 'arraybuffer' as any });
    }

    loadSuggestions(term: string, pageSize: number = 3): Observable<Suggestion[]> {
        return this.getSuggestionEndpoint2(term, pageSize.toString()).pipe(
            map((url) => this.http.get<Occ.SuggestionList>(url)),
            pluck('suggestions'),
            map((suggestions) => suggestions ?? []),
            this.converter.pipeableMany(PRODUCT_SUGGESTION_NORMALIZER),
        );
    }

    protected getSearchEndpoint2(query: string, searchConfig: AimoSearchConfig, endPoint: string): Observable<string> {
        let previousDate: string;
        let previousMyAssortment: boolean;
        return this.assortmentService.isOnlyMyAssortment().pipe(
            switchMap((onlyMyAssortment) =>
                this.deliveryDateService.getDeliveryDate().pipe(
                    filter(Boolean),
                    map((requestedDeliveryDate) => {
                        const resetSearch =
                            (previousDate && previousDate !== requestedDeliveryDate) || // reset search paging on deliverydate change
                            (previousMyAssortment && previousMyAssortment !== onlyMyAssortment); // reset search paging on assortment switch
                        const url = this.occEndpoints.buildUrl(endPoint, {
                            queryParams: {
                                query,
                                ...searchConfig,
                                resetSearch,
                                requestedDeliveryDate,
                                onlyMyAssortment,
                                klevuBannerId: searchConfig.klevuBannerId,
                                recentProducts: this.recentProductsService.get(30),
                            },
                        });
                        previousDate = requestedDeliveryDate;
                        previousMyAssortment = onlyMyAssortment;
                        return url;
                    }),
                ),
            ),
            withdrawOn(this.contextChange$),
        );
    }

    protected getSuggestionEndpoint2(term: string, max: string): Observable<string> {
        return this.assortmentService.isOnlyMyAssortment().pipe(
            switchMap((onlyMyAssortment) =>
                this.deliveryDateService.getDeliveryDate().pipe(
                    filter(Boolean),
                    map((requestedDeliveryDate) =>
                        this.occEndpoints.buildUrl('productSuggestions', {
                            queryParams: { term, max, requestedDeliveryDate, onlyMyAssortment },
                        }),
                    ),
                ),
            ),
            withdrawOn(this.contextChange$),
        );
    }
}

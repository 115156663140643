<ng-container *ngIf="getCart() | async as cart">
    <ng-container *ngIf="cart.paymentUrl === undefined; else prePayment">
        <h1 class="noPrint">{{ 'aimo.cart.title' | cxTranslate }}</h1>
        <div class="cart-dates-wrapper" *ngIf="!isPikatukku()">
            <div class="cart-dates">
                <div
                    class="cart-date-item cursor-pointer"
                    [ngClass]="{
                        active: group.active,
                        noPrint: !group.active,
                        'has-item-amount': group.entries.length > 0,
                    }"
                    *ngFor="let group of cart.dayGroupedEntries"
                    (click)="selectDate(group.date)">
                    {{ group.date | cxDate: ('aimo.dateFormat' | cxTranslate) }}
                    <div class="ml-2 d-inline-block cart-date-item-amount" *ngIf="group.modifiedEntries > 0">
                        {{ group.modifiedEntries }}
                    </div>
                </div>
            </div>
        </div>

        <div class="messages">
            <div class="message message-WARNING" *ngIf="cart.exceptionDateSelected || cart.expressDateSelected">
                <i class="aimo-icon icon-inline icon-WARNING mr-2"></i>
                <div class="d-inline">
                    <span
                        [innerHTML]="
                            (cart.expressDateSelected ? 'aimo.calendar.expressDate' : 'aimo.calendar.exceptionDate')
                                | cxTranslate
                        "></span>
                </div>
            </div>

            <div *ngIf="closingTimeMessage$ | async as closingTimeMessage">
                <ng-container *ngIf="false">
                    <i class="aimo-icon icon-inline icon-WARNING mr-2"></i>
                    <div class="d-inline">
                        <span
                            [innerHTML]="
                                'aimo.cart.closingTimeWarningHeader'
                                    | cxTranslate
                                        : {
                                              lines: closingTimeMessage.lines,
                                              time:
                                                  (closingTimeMessage.time | cxDate: ('aimo.timeFormat' | cxTranslate)),
                                              timeLeft: closingTimeMessage.timeLeft,
                                          }
                            "></span>
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="(orderNumbers$ | async) === undefined">
            <div class="header row" [ngClass]="{ 'pb-3': cart.user.tukkumyyja }">
                <div class="col-6 col-md-4 col-lg-2 header-data">
                    <div class="label">{{ 'aimo.cart.header.customer' | cxTranslate }}</div>
                    <div
                        id="customer_id"
                        [ngClass]="{ 'credit-card-user': cart.user.tukkumyyja && !cart.unit.allowInvoices }">
                        <span>{{ cart.unit?.uid }}</span>
                        <i
                            class="aimo-icon icon-credit-card icon-sm icon-red icon-inline ml-1"
                            *ngIf="cart.user.tukkumyyja && !cart.unit.allowInvoices"></i>
                    </div>
                    <div>{{ cart.unit?.name }}</div>
                </div>

                <div class="col-6 col-md-4 col-lg-2 header-data">
                    <div class="label">
                        {{
                            (isPikatukku() ? 'aimo.cart.header.pickupAddress' : 'aimo.cart.header.address')
                                | cxTranslate
                        }}
                    </div>
                    <div id="customer_company" *ngIf="isPikatukku()">{{ cart.deliveryAddress?.companyName }}</div>
                    <div id="customer_address">{{ cart.deliveryAddress?.line1 }}</div>
                    <div>{{ cart.deliveryAddress?.postalCode }}, {{ cart.deliveryAddress?.town }}</div>
                    <div class="btn-link" *ngIf="cart.user.tukkumyyja" (click)="openRouteInfoModal(cart)">
                        {{ 'aimo.cart.openRouteInfoModal' | cxTranslate }}
                    </div>
                </div>

                <div class="col-6 col-md-4 col-lg-2 header-data" *ngIf="cart.user.tukkumyyja">
                    <div class="label">{{ 'aimo.cart.header.telephone' | cxTranslate }}</div>
                    <div id="phone_number" *ngIf="cart.unit?.addresses.length > 0">
                        {{ cart.unit?.addresses[0].phone }}
                    </div>
                </div>

                <div class="col-6 col-md-4 col-lg-2 header-data">
                    <div class="label">{{ 'aimo.cart.header.paymentMode' | cxTranslate }}</div>
                    <div id="payment_mode">{{ cart.paymentMode?.name }}</div>
                </div>

                <div class="col-6 col-md-4 col-lg-2 header-data" *ngIf="isPikatukku()">
                    <div>
                        <aimo-cx-input-select
                            [bindLabel]="'label'"
                            [bindValue]="'id'"
                            [required]="true"
                            [items]="cart.allowedPickupSlots"
                            [selectedValue]="cart.pickupSlotId"
                            [placeholder]="'aimo.cart.header.pickupSlotPlaceHolder'"
                            [label]="'aimo.cart.header.pickupSlot'"
                            (afterChange)="updatePickupSlot($event, cart)"
                            idName="allowedPickupSlots">
                        </aimo-cx-input-select>
                    </div>
                </div>

                <div class="col-6 col-md-4 col-lg-2 header-data">
                    <label for="purchaseOrderNumber">{{ 'aimo.cart.header.poNumber' | cxTranslate }}</label>
                    <div>
                        <aimo-cx-input
                            [idName]="'purchaseOrderNumber'"
                            (afterMouseOut)="updatePurchaseOrderNumber($event, cart)"
                            (afterChange)="updatePurchaseOrderNumber($event, cart)"
                            [inputValue]="cart.purchaseOrderNumber"
                            [placeholder]="'aimo.cart.purchaseOrderNumber.placeholder'"
                            [maxlength]="20"
                            idName="purchaseOrderNumber">
                        </aimo-cx-input>
                    </div>
                </div>

                <div class="col-6 col-md-4 col-lg-2 header-data" *ngIf="cart.user.tukkumyyja">
                    <label for="deliveryInstructions">{{
                        'aimo.cart.header.deliveryInstructions' | cxTranslate
                    }}</label>
                    <div>
                        <aimo-cx-input
                            [idName]="'deliveryInstructions'"
                            [isTextArea]="true"
                            [inputClass]="'input-sm'"
                            (afterChange)="updateDeliveryInstructions($event, cart)"
                            [inputValue]="cart.deliveryInstructions"
                            [placeholder]="'aimo.cart.purchaseOrderNumber.placeholder'"
                            idName="deliveryInstructions">
                        </aimo-cx-input>
                    </div>
                </div>
            </div>

            <div id="cartDetailsWrapper" class="cart-details-wrapper">
                <div class="cart-details-header noPrint">
                    <h2 class="d-none d-md-block mb-0">{{ 'aimo.cart.content.shop' | cxTranslate }}</h2>
                    <h4 class="d-block d-md-none mb-0 text-white">{{ 'aimo.cart.content.shop' | cxTranslate }}</h4>
                </div>
                <div class="cart-functions-wrapper bg-petrol-light p-2 noPrint">
                    <div class="cart-searchbox">
                        <aimo-cx-searchbox
                            [cartSearch]="true"
                            [cart]="cart"
                            id="cartSearchBox"
                            [isCartSearchBox]="true"></aimo-cx-searchbox>
                    </div>
                    <div class="cart-functions">
                        <button
                            [id]="'open_excel_import_modal'"
                            class="btn-link d-none d-md-inline-block order-md-first order-4"
                            (click)="openExcelImportModal(); pushGtmEvent('cart-functions-from-excel')">
                            <i class="aimo-icon icon-clipboard-text icon-inline"></i>
                            <span>{{ 'aimo.cart.import.cart' | cxTranslate }}</span>
                        </button>

                        <button
                            *ngIf="!isPikatukku()"
                            [id]="'import_from_template'"
                            class="btn-link order-md-1 order-4 d-none d-md-inline-block"
                            (click)="
                                importFromOrderTemplate('import_from_template');
                                pushGtmEvent('cart-functions-import-from-template')
                            ">
                            <i class="aimo-icon icon-file-text icon-inline"></i>
                            <span>{{ 'aimo.cart.import.orders' | cxTranslate }}</span>
                        </button>

                        <ng-container *ngIf="user$ | async as user">
                            <button
                                *ngIf="
                                    !isPikatukku() &&
                                    (user.customerAdmin || !user.isHideNormalOrderTemplatesFromBasicUsers)
                                "
                                [id]="'add_to_order_template'"
                                (click)="
                                    addToOrderTemplate(cart, false, 'add_to_order_template');
                                    pushGtmEvent('cart-functions-add-to-order-template')
                                "
                                class="btn-link order-md-3 order-5 d-none d-md-inline-block"
                                [ngClass]="hasEntries(cart.dayGroupedEntries) ? '' : 'disabled'">
                                <i class="aimo-icon icon-file-plus icon-inline"></i>
                                <span>{{ 'aimo.cart.new.orderTemplate' | cxTranslate }}</span>
                            </button>
                        </ng-container>

                        <button
                            [id]="'to_excel'"
                            class="btn-link d-none order-5 d-md-inline-block d-sm-none no-mobile"
                            (click)="exportCartToExcel(); pushGtmEvent('cart-functions-to-excel')"
                            [ngClass]="hasEntries(cart.dayGroupedEntries) ? '' : 'disabled'">
                            <i class="aimo-icon icon-file-arrow-down icon-inline"></i>
                            <span>{{ 'aimo.cart.export.excel' | cxTranslate }}</span>
                        </button>

                        <button
                            [id]="'print'"
                            class="btn-link d-none order-6 d-md-inline-block d-sm-none no-mobile"
                            (click)="pushGtmEvent('cart-functions-print'); print()"
                            [ngClass]="hasEntries(cart.dayGroupedEntries) ? '' : 'disabled'">
                            <i class="aimo-icon icon-printer icon-inline"></i>
                            <span>{{ 'aimo.cart.print' | cxTranslate }}</span>
                        </button>

                        <button
                            *ngIf="!isPikatukku()"
                            [id]="'switch_date'"
                            class="btn-link order-first order-md-6"
                            (click)="switchDate(cart); pushGtmEvent('cart-functions-switch-date')"
                            [ngClass]="switchDateEnabled(cart.dayGroupedEntries) ? '' : 'disabled'">
                            <i class="aimo-icon icon-calendar-blank icon-inline"></i>
                            <span>{{ 'aimo.cart.switchDate' | cxTranslate }}</span>
                        </button>

                        <button
                            [id]="'clear_cart'"
                            class="btn-link order-2 d-none d-md-inline-block order-md-last"
                            (click)="clearCart(); pushGtmEvent('cart-functions-clear-cart')"
                            [ngClass]="hasEntries(cart.dayGroupedEntries) ? '' : 'disabled'">
                            <i class="aimo-icon icon-trash icon-inline"></i>
                            <span>{{ 'aimo.cart.empty.cart' | cxTranslate }}</span>
                        </button>

                        <button
                            #moreFunctionsOption
                            [id]="'more_functions'"
                            class="btn-link d-md-none order-last"
                            (click)="showMoreFunctions(moreFunctionsOption)">
                            <i class="aimo-icon icon-dots-three-vertical icon-inline"></i>
                            <span>{{ 'aimo.cart.more.functions' | cxTranslate }}</span>
                        </button>
                    </div>
                </div>
                <div class="pt-md-4 pt-3">
                    <ng-container *ngFor="let group of cart.dayGroupedEntries">
                        <aimo-cart-item-list
                            [paymentErrorStatus]="paymentErrorStatus"
                            [cart]="cart"
                            [dayGroupedEntries]="group"
                            (toggleList)="toggleCartItemList($event)"
                            (toggleAlreadyOrderedList)="toggleAlreadyOrderedCartItemList($event)"
                            [showHideList]="listOpen | async"
                            [alreadyOrderedEntriesOpen]="alreadyOrderedEntriesOpen"
                            [showHideAlreadyOrderedList]="listAlreadyOrderedOpen | async"
                            *ngIf="group.active"></aimo-cart-item-list>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="orderNumbers$ | async as orderNumbers">
            <div class="row pt-5" id="order-confirmation">
                <div class="col-12 mb-3">
                    <h2 class="mb-0">{{ 'aimo.orderconfirmation.thankyou' | cxTranslate }}</h2>
                </div>
                <div class="col-12 messages mb-5">
                    <ng-container
                        *ngTemplateOutlet="
                            thankYouText;
                            context: {
                                text1: 'aimo.order.successful1.create.',
                                text2: isPikatukku()
                                    ? 'aimo.order.successful2.create.pikatukku'
                                    : 'aimo.order.successful2.create.toimitustukku',
                                orderData: orderNumbers,
                                numbers: [orderNumbers.createOrderNumber],
                            }
                        "></ng-container>

                    <ng-container
                        *ngTemplateOutlet="
                            thankYouText;
                            context: {
                                text1: 'aimo.order.successful1.change.',
                                text2: 'aimo.order.successful2.change',
                                orderData: orderNumbers,
                                numbers: orderNumbers.changeOrderNumbers,
                            }
                        "></ng-container>

                    <div class="rate-us mt-5">
                        <h5 class="d-inline-block">{{ 'aimo.orderconfirmation.rateus' | cxTranslate }}</h5>
                        <a
                            href="{{ 'aimo.orderconfirmation.rateus1link' | cxTranslate }}{{
                                getQualtricsParams(cart)
                            }}"
                            target="_blank">
                            <i
                                id="rate-us-star-1"
                                class="aimo-icon icon-sm icon-inline icon-star"
                                (click)="starClick($event, 1)"
                                (mouseover)="starFocus($event, 1)"
                                (mouseout)="starBlur()"></i>
                        </a>
                        <a
                            href="{{ 'aimo.orderconfirmation.rateus2link' | cxTranslate }}{{
                                getQualtricsParams(cart)
                            }}"
                            target="_blank">
                            <i
                                id="rate-us-star-2"
                                class="aimo-icon icon-sm icon-inline icon-star"
                                (click)="starClick($event, 2)"
                                (mouseover)="starFocus($event, 2)"
                                (mouseout)="starBlur()"></i>
                        </a>
                        <a
                            href="{{ 'aimo.orderconfirmation.rateus3link' | cxTranslate }}{{
                                getQualtricsParams(cart)
                            }}"
                            target="_blank">
                            <i
                                id="rate-us-star-3"
                                class="aimo-icon icon-sm icon-inline icon-star"
                                (click)="starClick($event, 3)"
                                (mouseover)="starFocus($event, 3)"
                                (mouseout)="starBlur()"></i>
                        </a>
                        <a
                            href="{{ 'aimo.orderconfirmation.rateus4link' | cxTranslate }}{{
                                getQualtricsParams(cart)
                            }}"
                            target="_blank">
                            <i
                                id="rate-us-star-4"
                                class="aimo-icon icon-sm icon-inline icon-star"
                                (click)="starClick($event, 4)"
                                (mouseover)="starFocus($event, 4)"
                                (mouseout)="starBlur()"></i>
                        </a>
                        <a
                            href="{{ 'aimo.orderconfirmation.rateus5link' | cxTranslate }}{{
                                getQualtricsParams(cart)
                            }}"
                            target="_blank">
                            <i
                                id="rate-us-star-5"
                                class="aimo-icon icon-sm icon-inline icon-star"
                                (click)="starClick($event, 5)"
                                (mouseover)="starFocus($event, 5)"
                                (mouseout)="starBlur()"></i>
                        </a>
                    </div>
                </div>
            </div>

            <ng-template
                #thankYouText
                let-text1="text1"
                let-text2="text2"
                let-numbers="numbers"
                let-orderData="orderData">
                <div class="message message-INFO" *ngIf="numbers[0]?.length > 0">
                    <i class="aimo-icon icon-petrol-blue icon-INFO mr-2 icon-inline"></i>

                    <div class="d-inline">
                        {{ text1 + (numbers.length > 1 ? 'plural' : 'singular') | cxTranslate }}

                        <span *ngFor="let orderNumber of numbers; let i = index">
                            <a
                                id="{{ orderNumber }}"
                                class="text-bold text-default text-underline cursor-pointer d-inline"
                                [routerLink]="'/my-account/orders'"
                                (click)="goToOrder(orderNumber)"
                                >{{ orderNumber }}</a
                            >
                            <span *ngIf="i < numbers.length - 1">, </span>
                        </span>
                        <!-- Using innerHTML don't add space between <a> and <span> so need to add it manually before text inside span -->
                        <span
                            class="d-inline"
                            [innerHTML]="
                                ' ' +
                                (text2
                                    | cxTranslate
                                        : {
                                              date:
                                                  ((orderData.date ? orderData.date : cart.requestedDeliveryDate)
                                                  | cxDate: ('aimo.dateFormat' | cxTranslate)),
                                              time:
                                                  orderData.time ??
                                                  (orderData.time | cxDate: ('aimo.timeFormat' | cxTranslate)),
                                          }) +
                                ' ' +
                                (cart.user.orderConfirmationsInEmail
                                    ? ('aimo.order.successful3'
                                      | cxTranslate
                                          : {
                                                email: cart.user.email,
                                            })
                                    : '')
                            "></span>
                    </div>
                </div>
            </ng-template>

            <div class="row">
                <div class="col-12 col-md-6 col-lg-3" *ngIf="!isPikatukku()">
                    <div class="order-info-box">
                        <h5>{{ 'aimo.order.createAnother' | cxTranslate }}</h5>
                        <p>{{ 'aimo.order.createAnotherDescription' | cxTranslate }}</p>
                        <button class="btn btn-secondary btn-block" (click)="toggleCalendar()">
                            <i class="aimo-icon icon-inline icon-calendar-blank mr-2 mb-1"></i>
                            {{ 'aimo.order.pickDate' | cxTranslate }}
                        </button>
                        <aimo-cx-delivery-date-component
                            [calendarSource]="calendarSource"
                            [modal]="true"
                            [autofocus]="true"
                            (dayClicked)="selectCalendarDay($event)"
                            *ngIf="calendarVisible">
                        </aimo-cx-delivery-date-component>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3" *ngIf="!isPikatukku()">
                    <div class="order-info-box">
                        <h5>{{ 'aimo.order.modifyOrder' | cxTranslate }}</h5>
                        <p>{{ 'aimo.order.modifyOrderDescription' | cxTranslate }}</p>
                        <button class="btn btn-secondary btn-block" (click)="backToCart()">
                            <i class="aimo-icon icon-inline icon-clipboard-text mr-2 mb-1"></i>
                            {{ 'aimo.order.orderDetails' | cxTranslate }}
                        </button>
                    </div>
                </div>

                <ng-container *ngIf="user$ | async as user">
                    <div
                        class="col-12 col-md-6 col-lg-3"
                        *ngIf="
                            !isPikatukku() && (user.customerAdmin || !user.isHideNormalOrderTemplatesFromBasicUsers)
                        ">
                        <div class="order-info-box">
                            <h5>{{ 'aimo.order.createTemplate' | cxTranslate }}</h5>
                            <p>{{ 'aimo.order.createTemplateDescription' | cxTranslate }}</p>
                            <button class="btn btn-secondary btn-block" (click)="addToOrderTemplate(cart, true)">
                                <i class="aimo-icon icon-inline icon-file-text mr-2 mb-1"></i>
                                {{ 'aimo.order.createTemplate' | cxTranslate }}
                            </button>
                        </div>
                    </div>
                </ng-container>

                <div class="col-12 col-md-6 col-lg-3">
                    <!-- Hidden on rel 1.1
                    <div class="order-info-box">
                        <h5>{{ 'aimo.order.createBaseOrder' | cxTranslate }}</h5>
                        <p>{{ 'aimo.order.createBaseOrderDescription' | cxTranslate }}</p>
                        <button class="btn btn-secondary btn-block">
                            <i class="aimo-icon icon-inline icon-arrow-counter-clockwise mr-2 mb-1"></i>
                            {{ 'aimo.order.baseorder' | cxTranslate }}
                        </button>
                    </div>
                    -->
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #prePayment>
        <div class="prepayment-modal">
            <h1>{{ 'aimo.cart.forwardingToPayment' | cxTranslate }} <span class="loading"></span></h1>
        </div>
        <form ngNoForm #paymentForm action="{{ cart.paymentUrl }}" method="post">
            <ng-container *ngFor="let param of cart.paymentAttributes.entry">
                <input type="hidden" name="{{ param.key }}" value="{{ param.value }}" />
            </ng-container>
        </form>
    </ng-template>
</ng-container>

<ng-template let-ctx #saveForLaterBtn>
    <button
        *ngIf="selectiveCartEnabled"
        class="link cx-action-link cx-sfl-btn"
        [disabled]="ctx.loading"
        (click)="saveForLater(ctx.item)"
        type="button">
        {{ 'saveForLaterItems.saveForLater' | cxTranslate }}
    </button>
</ng-template>

<ng-container *ngIf="((items && items.length > 0) || banners) && (size$ | async) as size">
    <div class="row align-items-center">
        <div class="col-8 col-sm-6">
            <h3 *ngIf="title">{{ title }}</h3>
        </div>
        <div class="col-4 col-sm-6 text-right">
            <ng-container *ngIf="showAllUrl && showScroll(divContainer, divSlides)">
                <a
                    class="show-all"
                    [ngClass]="showAllText ? 'd-inline-block d-sm-none' : ''"
                    [routerLink]="showAllUrl"
                    >{{ 'aimo.product.carousel.showAll' | cxTranslate }}</a
                >
                <a *ngIf="showAllUrl" class="show-all d-none d-sm-inline-block" [routerLink]="showAllUrl">{{
                    showAllText
                }}</a>
            </ng-container>
        </div>
    </div>

    <div class="carousel-wrapper" #carouselWrapper>
        <div
            class="carousel-panel"
            (scroll)="scroll(divContainer)"
            [ngClass]="{
                'show-scroll': showScroll(divContainer, divSlides),
                'no-title': !title,
            }"
            #divContainer>
            <div class="carousel-fade" *ngIf="showButton(divContainer, true, divSlides)">
                <div class="fade-left"></div>
            </div>

            <button
                *ngIf="showButton(divContainer, true, divSlides)"
                class="previous btn btn-primary btn-icon btn-lg"
                (click)="scrollContainer(divContainer, true)"
                [attr.aria-label]="'aimo.product.carousel.previousSlide' | cxTranslate">
                <i class="aimo-icon icon-caret-left icon-sm icon-white"></i>
            </button>

            <div class="slides" #divSlides>
                <ng-container *ngFor="let _ of banners; let i = index">
                    <div class="slide" *ngIf="i % size === 0" [class.active]="i === activeSlide">
                        <ng-container *ngFor="let item of banners | slice: i : i + size; let j = index">
                            <div
                                [attr.data-slide]="i"
                                *ngIf="item as data"
                                class="item"
                                [class.active]="i === activeSlide">
                                <ng-container
                                    *ngTemplateOutlet="template; context: { item: data, index: i }"></ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngFor="let _ of items; let i = index">
                    <div class="slide" *ngIf="i % size === 0" [class.active]="i === activeSlide">
                        <ng-container *ngFor="let item of items | slice: i : i + size; let j = index">
                            <div
                                [attr.data-slide]="i"
                                *ngIf="item | async as data"
                                class="item"
                                [class.active]="i === activeSlide">
                                <ng-container
                                    *ngTemplateOutlet="
                                        template;
                                        context: { item: data, index: i, idSuffix: idSuffix, gtmListId: gtmListId }
                                    "></ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="showAllUrl && showScroll(divContainer, divSlides)">
                            <div class="item active">
                                <div class="show-more">
                                    <a class="btn btn-primary" href="{{ showAllUrl }}">{{
                                        'aimo.product.carousel.showAll' | cxTranslate
                                    }}</a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>

            <div class="carousel-fade" *ngIf="showButton(divContainer, false, divSlides)">
                <div class="fade-right"></div>
            </div>

            <button
                *ngIf="showButton(divContainer, false, divSlides)"
                class="next btn-primary btn-icon btn-lg"
                (click)="scrollContainer(divContainer, false)"
                tabindex="-1"
                [attr.aria-label]="'aimo.product.carousel.nextSlide' | cxTranslate">
                <i class="aimo-icon icon-caret-right icon-sm icon-white"></i>
            </button>
        </div>
    </div>

    <div *ngIf="!hideIndicators && size < items.length" class="indicators">
        <ng-container *ngFor="let _ of items; let i = index">
            <button
                *ngIf="i % size === 0"
                (focus)="activeSlide = i"
                [disabled]="i === activeSlide"
                [attr.aria-label]="
                    'carousel.slideNumber' | cxTranslate: { currentSlideNumber: getSlideNumber(size, i) }
                "
                tabindex="-1">
                <cx-icon [type]="indicatorIcon" aria-hidden="true"></cx-icon>
            </button>
        </ng-container>
    </div>
</ng-container>

<div id="cart_item_{{ entry.entryNumber }}">
    <div
        id="product_{{ entry.product.code }}"
        class="row no-gutters cart-item align-center product_code_{{ entry.product.code }}"
        [ngClass]="{ deleted: entry.quantity === 0 }">
        <div class="col-12 col-md-4 pb-2 pb-md-0 pr-md-2">
            <div class="row no-gutters">
                <div class="col-12 align-center">
                    <a
                        [id]="'cart_item_link_' + entry.entryNumber"
                        class="cx-product-image-container mr-1 cursor-pointer"
                        role="button"
                        (click)="openProductModal('cart_item_link_' + entry.entryNumber)"
                        tabindex="0">
                        <aimo-product-tag [product]="entry.product" />
                        <cx-media
                            class="cx-product-image"
                            [container]="entry.product?.images?.PRIMARY"
                            format="cartIcon"></cx-media>
                    </a>

                    <div class="product-name-wrapper">
                        <button
                            [id]="'cart_item_name_' + entry.entryNumber"
                            class="btn-link text-left text-bold"
                            [attr.aria-label]="
                                'aimo.product.openModal.arialLabel' | cxTranslate: { productName: entry.product.name }
                            "
                            (click)="openProductModal('cart_item_name_' + entry.entryNumber)"
                            tabindex="0">
                            {{ entry.product.name }}
                        </button>

                        <div class="product-code">
                            <aimo-product-code [product]="entry.product"></aimo-product-code>
                        </div>
                    </div>

                    <div class="btn btn-icon btn-icon-transparent d-inline-block d-md-none ml-1">
                        <i
                            class="aimo-icon icon-trash icon-inline"
                            *ngIf="entry.deletable"
                            (click)="deleteEntry(entry)"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-12">
            <div class="row no-gutters align-center">
                <div class="d-md-none col-5">
                    {{ 'aimo.cart.item.basePrice' | cxTranslate }}
                    {{ 'aimo.cart.item.notax' | cxTranslate }}
                </div>
                <div class="col-7 col-md-6 cart-item-data cart-item-data-right">
                    <div>
                        <i
                            *ngIf="entry.product.myAssortment"
                            placement="top"
                            container="body"
                            triggers="mouseenter:mouseleave"
                            [ngbPopover]="'aimo.product.myAssortment' | cxTranslate"
                            class="aimo-icon icon-inline icon-sm icon-house-line-bold"
                            [ngClass]="entry.strikeThroughPrice ? 'icon-red' : 'icon-petrol-blue'"></i>
                        <span
                            class="text-bold d-inline-block mr-1"
                            [ngClass]="
                                entry.strikeThroughPrice ? 'text-red' : entry.product.myAssortment ? 'text-primary' : ''
                            "
                            >{{ entry.basePrice?.formattedValue }}
                        </span>
                        <span class="d-inline-block mr-1"> / {{ entry.unit.name }} </span>
                    </div>
                    <div class="text-strike" *ngIf="entry.strikeThroughPrice">
                        <span class="d-inline-block mr-1"> {{ entry.strikeThroughPrice?.formattedValue }} </span>
                        <span class="d-inline-block mr-1"> / {{ entry.unit.name }} </span>
                    </div>
                </div>
                <div class="col-7 col-md-6 cart-item-data cart-item-data-right d-none d-md-block">
                    <div *ngIf="entry.comparisonPrice">
                        <span class="d-inline-block mr-1"> {{ entry.comparisonPrice?.formattedValue }} </span>
                        <span class="d-inline-block mr-1"> / {{ entry.comparisonUnit?.name }} </span>
                    </div>
                    <div class="text-strike" *ngIf="entry.strikeThroughComparisonPrice">
                        <span class="d-inline-block mr-1">
                            {{ entry.strikeThroughComparisonPrice?.formattedValue }}
                        </span>
                        <span class="d-inline-block mr-1"> / {{ entry.comparisonUnit?.name }} </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-md-none col-5">
            {{ 'aimo.cart.item.quantity' | cxTranslate }}
        </div>

        <div class="col-md-2 col-7 cart-item-data cart-item-data-right" [formGroup]="addToCartForm">
            <div class="align-center">
                <span class="d-inline-block d-md-none mr-2 ml-auto">
                    <ng-container *ngIf="entry.sizeInBaseUnits !== 1 && entry.sizeInBaseUnits !== 0">
                        {{ entry.sizeInBaseUnits }} {{ entry.product.baseUnit?.name }}
                    </ng-container>
                </span>
                <aimo-item-counter
                    class="cart-item-counter noPrint"
                    *ngIf="entry.editable"
                    [autoAddToCart]="true"
                    [min]="0"
                    [id]="'quantity_' + entry.entryNumber"
                    [step]="entry.product.allowedLotSize"
                    [control]="addToCartForm.get('quantity')"
                    [unitName]="entry.unit.name"
                    [cartCounter]="true"
                    [product]="entry.product"
                    [gtmProductAttributes]="gtmProductAttributes"
                    (afterChange)="changeQuantity()"></aimo-item-counter>
                <span class="noPrint" *ngIf="!entry.editable">{{ entry.quantity }} {{ entry.unit.name }}</span>
                <div class="pt-2 onlyPrint">{{ entry.quantity }} {{ entry.unit.name }}</div>
            </div>
        </div>

        <div class="col-md-1 d-none d-md-flex">
            <span *ngIf="entry.sizeInBaseUnits !== 1 && entry.sizeInBaseUnits !== 0"
                >{{ entry.sizeInBaseUnits }} {{ entry.product.baseUnit?.name }}</span
            >
        </div>

        <div class="d-md-none col-6">
            {{ 'aimo.cart.item.total' | cxTranslate }}
            {{ 'aimo.cart.item.notax' | cxTranslate }}
        </div>

        <div class="col-md-2 col-6 d-md-flex cart-item-data cart-item-data-right align-center">
            <div class="mr-auto">
                <div class="text-bold">
                    <span class="d-inline-block mr-1">{{ entry.totalPrice?.formattedValue }}</span>
                </div>
                <div class="d-none d-md-block text-lowercase" *ngIf="!cart.paymentMode.credit || !alreadyOrdered">
                    <span class="d-inline-block mr-1">{{ entry.totalPriceWithTax?.formattedValue }}</span>
                    <span class="d-inline-block">
                        ({{ 'aimo.cart.totals.vat' | cxTranslate: { tax: entry.tax?.rate } }})
                    </span>
                </div>
                <aimo-cart-item-deposits
                    [alreadyOrdered]="alreadyOrdered"
                    [paymentMode]="cart.paymentMode"
                    [entry]="entry"></aimo-cart-item-deposits>
            </div>

            <button
                class="btn btn-xs btn-icon btn-icon-transparent d-none d-md-block noPrint"
                [attr.aria-label]="'aimo.arialLabel.cart.messages' | cxTranslate"
                *ngIf="cart?.user.tukkumyyja"
                (click)="openEntryMessagesModal(entry)">
                <i class="aimo-icon icon-chat-circle-text icon-inline icon-petrol-blue noPrint"></i>
                <span
                    *ngIf="numberOfMessages > 0"
                    class="circle circle-sm bg-petrol quantity text-white text-xxsm noPrint"
                    >{{ numberOfMessages }}</span
                >
            </button>

            <button
                class="btn btn-xs btn-icon btn-icon-transparent d-none d-md-block noPrint"
                [attr.aria-label]="'aimo.arialLabel.cart.remove' | cxTranslate"
                *ngIf="entry.deletable"
                (click)="deleteEntry(entry)">
                <i class="aimo-icon icon-trash icon-inline icon-petrol-blue noPrint"></i>
            </button>

            <div
                *ngIf="!entry.deletable"
                class="btn btn-xs btn-icon btn-icon-transparent disabled d-none d-md-block noPrint">
                <i
                    placement="top"
                    container="body"
                    triggers="mouseenter:mouseleave"
                    [ngbPopover]="'aimo.cart.item.locked' | cxTranslate"
                    class="aimo-icon icon-lock-key icon-inline icon-mid-grey"></i>
            </div>
        </div>

        <div class="d-md-none col-6">
            {{ 'aimo.cart.item.totalWithTax' | cxTranslate: { tax: entry.tax?.rate } }}
        </div>

        <div class="d-md-none col-6 cart-item-data cart-item-data-right" *ngIf="!cart.paymentMode.credit">
            {{ entry.totalPriceWithTax?.formattedValue }}
        </div>

        <div *ngIf="entry.closingTime && entry.external && entry.editable" class="open-until">
            {{
                'aimo.cart.item.openUntil'
                    | cxTranslate
                        : {
                              date: entry.closingTime | cxDate: ('aimo.dateFormat' | cxTranslate),
                              time: entry.closingTime | cxDate: ('aimo.timeFormat' | cxTranslate),
                          }
            }}
        </div>

        <aimo-cart-item-errors
            [entry]="entry"
            [showCloseIcon]="true"
            [gtmProductAttributes]="gtmProductAttributes"
            class="col-12">
        </aimo-cart-item-errors>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnalyticsConfig } from './aimo-environment.objects';
import { AimoOccEnvironmentAdapter } from './aimo-occ-environment.adapter';

@Injectable({
    providedIn: 'root',
})
export class AimoEnvironmentConnector {
    constructor(protected adapter: AimoOccEnvironmentAdapter) {}

    getAnalyticsConfig(): Observable<AnalyticsConfig> {
        return (this.adapter as AimoOccEnvironmentAdapter).getAnalyticsConfig();
    }
}

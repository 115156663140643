import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { CommandService, RoutingService } from '@spartacus/core';
import { OrderFacade } from '@spartacus/order/root';
import { Subscription } from 'rxjs';

import { AimoRoutingService } from '../../cms-structure/routing/aimo-routing.service';
import { AimoCart } from '../../model/cart.model';
import { AimoActiveCartService } from '../../service/cart/aimo-active-cart.service';
import { AIMO_CART_UPDATE_HEADER, AIMO_CART_UPDATE_HEADER_SUCCESS } from '../../service/cart/aimo-cart.action';
import { AimoSpinnerService } from '../shared/spinner/aimo-spinner.service';

@Component({
    selector: 'aimo-cart-totals',
    templateUrl: './aimo-cart-totals.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoCartTotalsComponent implements OnDestroy, OnInit {
    subscription: Subscription = new Subscription();

    @Input() cart: AimoCart;
    orderClicked = false;
    hasPrevious = false;
    hasModifiedEntries = false;
    hasNewEntries = false;

    constructor(
        protected orderFacade: OrderFacade,
        protected routingService: RoutingService,
        protected commandService: CommandService,
        protected spinnerService: AimoSpinnerService,
        protected actions$: Actions,
        protected cdr: ChangeDetectorRef,
        protected activeCartService: AimoActiveCartService,
        protected aimoRoutingService: AimoRoutingService,
    ) {
        this.subscription.add(
            this.actions$.subscribe((action) => {
                if (action.type === AIMO_CART_UPDATE_HEADER) {
                    this.orderClicked = true;
                    this.cdr.detectChanges();
                }
                if (
                    action.type === AIMO_CART_UPDATE_HEADER_SUCCESS ||
                    action.type === CartActions.CART_UPDATE_ENTRY_FAIL ||
                    action.type === CartActions.CART_ADD_ENTRY_SUCCESS ||
                    action.type === CartActions.CART_UPDATE_ENTRY_SUCCESS ||
                    action.type === CartActions.LOAD_CART_SUCCESS
                ) {
                    this.orderClicked = false;
                    this.cdr.detectChanges();
                }
            }),
        );
    }

    ngOnInit(): void {
        this.hasPrevious =
            this.cart?.dayGroupedEntries
                .filter((d) => d.active)
                .flatMap((d) => d.alreadyOrdered)
                .flatMap((e) => e.entries).length > 0;
        this.hasModifiedEntries =
            this.cart?.dayGroupedEntries
                .filter((d) => d.active)
                .flatMap((d) => d.alreadyOrdered)
                .flatMap((e) => e.entries)
                .find((e) => e.modified) !== undefined;
        this.hasNewEntries =
            this.cart?.dayGroupedEntries
                .filter((d) => d.active)
                .flatMap((d) => d.entries)
                .find((e) => e.external === false) !== undefined;
    }

    order(cart: AimoCart): void {
        if (cart.validForOrdering && !this.orderClicked) {
            this.orderClicked = true;
            this.spinnerService.setTitle('aimo.loading.placeOrder');
            this.activeCartService.placeOrderClicked(cart);
            this.orderFacade.placeOrder(true);
        }
    }

    ngOnDestroy(): void {
        this.spinnerService.setTitle(null);
        this.subscription.unsubscribe();
    }

    orderingDisabled(): boolean {
        return !this.cart.validForOrdering || this.orderClicked;
    }

    removeExternalEntries(cart: AimoCart): void {
        this.activeCartService.updateCartHeader(cart, undefined, true);
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}

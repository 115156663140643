import { AimoProduct } from '../../model/product.model';

export enum GTMEventCode {
    AddToCart = 'add_to_cart',
    BeginCheckout = 'begin_checkout',
    AddPaymentInfo = 'add_payment_info',
    RemoveFromCart = 'remove_from_cart',
    SelectItem = 'select_item',
    ViewCart = 'view_cart',
    ViewItem = 'view_item',
    ViewItemList = 'view_item_list',
    ViewFullSearchResults = 'view_full_search_results',
    ViewSearchSuggestions = 'view_search_suggestions',
    ChangeCartDate = 'change_cart_date',
    OpenCartCalendar = 'open_cart_calendar',
    ViewAlternativeProducts = 'view_alternative_products',

    SelectContent = 'select_content',
    SelectPromotion = 'select_promotion',
    ViewPromotion = 'view_promotion',
    Purchase = 'purchase',
    ExpandOrderHistory = 'expand_order_history',
}

export enum GTMItemListId {
    cart = 'cart',
    cart_items = 'cart_items',
    mini_cart = 'mini_cart',
    cart_suggestive_search = 'search_box',
    suggestive_search = 'search_box',
    product_comparison = 'product_comparison',
    product_category_page = 'category_page',
    product_search_page = 'search_results',
    excel_upload = 'excel_upload',
    order_history = 'order_history',
    favorites = 'favorites',
    campaign = 'front_page_campaigns',
    mostPurchased = 'front_page_last_purchased',
    novelty = 'front_page_novelty',
    clickAndCollect = 'front_page_clickAndCollect',
    order_template = 'order_template',
    product_summary = 'product_summary',
    cross_selling = 'cross_selling',
    alternative = 'alternative',
    reports = 'reports',
}

export enum GTMCalendarSource {
    cart = 'cart',
    claim = 'claim',
    orderHistory = 'order_history',
    calendar = 'calendar',
    orderTemplate = 'order_template',
}

export interface AimoGTMProductAttributes {
    item_list_id: GTMItemListId;
    index?: number;
    cart_type?: GTMCartType;
    category_code?: string;
    search_term?: string;
    klevuParams?: KlevuParams;
}

export interface KlevuParams {
    klevuBannerId: string;
    klevuBannerTitle: string;
    klevuBannerType: string;
    klevuBannerPageType: string;
}

export interface AimoGTMProduct extends AimoProduct {
    quantity: number;
    gtmProductAttributes: AimoGTMProductAttributes;
    orderId?: string;
}

export interface GTMEvent {
    event: GTMEventCode;
    ecommerce?: GTMEcommerce;
    search_term?: string;
    total_hits?: number;
    order_status?: GTMOrderStatus;
    days_until_selected_date?: number;
    items_in_cart?: number;
    amount_of_open_carts?: number;
    number_of_open_carts?: number;
    original_product?: GTMItem;
    items?: GTMItem[];
    search_origin?: GTMCartType;
    originalTransactionId?: string;
    purchaseType?: GTMPurchaseType;
    cart_type?: GTMCartType;
}

export enum GTMOrderStatus {
    open = 'open',
    closed = 'closed',
}

export interface GTMEcommerce {
    transaction_id?: string;
    value?: number;
    tax?: number;
    currency?: string;
    items?: GTMItem[];
    creative_slot?: GTMCreativeSlot;
    promotion_name?: string;
    promotion_id?: string;
}

export enum GTMPurchaseType {
    modification = 'modification',
    new = 'new',
}

export enum GTMItemType {
    content = 'content',
    product = 'product',
}

export enum GTMCartType {
    cart = 'cart',
    orderTemplate = 'ordertemplate',
    baseOrder = 'baseorder',
    main = 'main',
}

export interface GTMItem {
    item_type: GTMItemType;
    item_id?: string;
    item_name?: string;
    price?: number;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    quantity?: number;
    content_type?: GTMContentType;
    vendor_id?: string;
}

export enum GTMContentType {
    aimo = 'Valio Aimo sisältö',
    vendor = 'Toimittajasisältö',
}

export enum GTMCreativeSlot {
    category_page_vendor_promotion = 'category_page_vendor_promotion',
    front_page_hero_banner = 'front_page_hero_banner',
    front_page_large_banner = 'front_page_large_banner',
}

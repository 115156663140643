const cartFields =
    'code,name,modified,guid,exceptionDateSelected,expressDateSelected,user(name,uid,tukkumyyja,phone,email,orderConfirmationsInEmail),' +
    'purchaseOrderNumber,unit(FULL,routeInfos(FULL)),taxes(FULL),deliveryCost(FULL),deliveryItemsQuantity,subTotal(FULL),totalPrice(FULL),totalTax(FULL),' +
    'deposits(FULL),totalPriceWithTax(FULL),extraCharge(FULL),deposits(FULL),' +
    'deliveryAddress(postalCode,town,phone,line1,country,companyName),deliveryMode(code,name),totalItems,paymentMode(code,name,credit),requestedDeliveryDate,' +
    'deliveryInstructions,validForOrdering,dayGroupedEntries(FULL),categoryGroupedEntries(FULL),messages(FULL),visibility,assignedUnits(uid,name),oddWeek,weekDays,' +
    'blocks(FULL),dailyTotalWithoutTax(FULL),dailyTotalWithTax(FULL),salesBlocked,unApprovedChanges,allowedPickupSlots,pickupSlotId,pickupTime,editable';
const templateFields =
    'name,totalItems,entries(product(images(FULL),code,name,myAssortment),entryNumber,doubleQuantity),code,messages(FULL),visibility,assignedUnits(uid,name),editable';
const claimFields =
    'created,remark,contactType,entries(product(images(FULL),code,name,unit(FULL)),entryNumber,doubleQuantity, reason,dateOrBatch),requestedDeliveryDate,code,messages(FULL)';
const entryFields =
    'pk,closingTimeWarningDate,messages(FULL),closingStatus,closingTime,product(name,categories(FULL),code,icons,similarProducts,frozenProduct,myAssortment,images(FULL),' +
    'lotSize,baseUnit(FULL),campaign,novelty),tax(FULL),comparisonPrice(FULL),comparisonUnit(FULL),doubleQuantity,previousQuantity,deliveredQuantity,' +
    'totalPrice(FULL),deposits(FULL),entryNumber,basePrice(FULL),basePriceWithTax(FULL),unit(FULL),editable,productExists,gtmItemListId,deletable,totalPriceWithTax(FULL),' +
    'lotSizeInfo,discount(FULL),strikeThroughPrice(FULL),strikeThroughComparisonPrice(FULL),deliveryDocumentNumber,invoiceDocumentNumber,deliveryUnit(FULL),reason,dateOrBatch,externalOrderNumber';
const baseOrderFields =
    'name,user(FULL,email),created,modified,purchaseOrderNumber,code,editable,categoryGroupedEntries(FULL),entries(' +
    entryFields +
    '),oddWeek,weekDays,blocks(FULL),messages(FULL),salesBlocked,aboutToBeSalesBlocked,firstPossibleDeliveryDate,deliveryDatesAhead,unApprovedChanges,subTotal(FULL),totalPrice(FULL),' +
    'totalTax,totalPriceWithTax(FULL),taxes(FULL),deliveryCost(FULL),extraCharge(FULL),deposits(FULL)';
const orderFields =
    'code,statusDisplay,status,pickupSlotId,pickupTime,guid,user(name,uid,email,tukkumyyja,phone,orderConfirmationsInEmail),purchaseOrderNumber,unit(FULL),taxes(FULL),deliveryCost(FULL),' +
    'deliveryItemsQuantity,subTotal(FULL),totalPrice(FULL),totalTax,totalPriceWithTax(FULL),' +
    'paymentUrl,paymentAttributes,cdpOrderCode,deliveryAddress(postalCode,town,phone,line1,country,companyName),deliveryMode(code,name),totalItems,paymentMode(code,name,credit),requestedDeliveryDate,' +
    'deliveryInstructions,validForOrdering,created,modified,' +
    'entries(' +
    entryFields +
    ')';
const addEntryFields =
    'statusCode,entry(' +
    entryFields +
    '),doubleQuantity,deliveryModeChanged,statusMessage,quantityAdded,addedDoubleQuantity,addedQuantityPrice(DEFAULT),addedQuantityDiscount(DEFAULT),adjustedBasePrice(DEFAULT)';

export const aimoOccCartConfig = {
    backend: {
        occ: {
            endpoints: {
                createCart: 'users/${userId}/carts?fields=' + cartFields,
                carts: 'users/${userId}/carts?fields=carts(' + cartFields + ')',
                cart: 'users/${userId}/carts/${cartId}?fields=' + cartFields,
                addEntries: 'users/${userId}/carts/${cartId}/entries?fields=' + addEntryFields,
                updateEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}?fields=' + addEntryFields,
                routeCalendar: 'users/${userId}/carts/${cartId}/routeCalendar',
                updateHeader: 'users/${userId}/carts/${cartId}/header?fields=' + cartFields,
                deliveryDate: 'users/${userId}/carts/${cartId}/deliveryDate',
                excelExportCart: 'users/${userId}/carts/${cartId}/excelExport?spinner=true',
                placeOrder:
                    'users/${userId}/orders?fields=' + orderFields + '&cartFields=' + cartFields + '&spinner=true',
                getOrderToBePaid:
                    'users/${userId}/orders/${orderId}?forPayment=true&fields=' +
                    orderFields +
                    '&cartFields=' +
                    cartFields +
                    '&spinner=true',
                excelImport: 'users/${userId}/carts/${cartId}/excelImport?fields=' + cartFields + '&spinner=true',
                addMany: 'users/${userId}/carts/${cartId}/addMany?fields=' + cartFields,
                orderHistory:
                    'users/${userId}/orders?fields=totalOrders,searchFrom,searchTo,pagination(FULL),facets(FULL),dayGroupedOrders(date,dateTitle,status,orderNumbers,poNumbers,totalRows' +
                    ',total(FULL),extraCharge(FULL),originalTotalWithTax(FULL),subTotal(FULL),deliveryCost(FULL),totalWithTax(FULL),claimCreated,claimAllowed,taxes(FULL),orders(' +
                    orderFields +
                    '))' +
                    '&spinner=true',
                claims: 'users/${userId}/claims?fields=' + claimFields + '&spinner=true',
                baseOrders: '/users/${userId}/baseorders?fields=' + baseOrderFields,
                baseOrder: '/users/${userId}/baseorders/${code}?fields=' + baseOrderFields,
                baseOrderApprove: '/users/${userId}/baseorders/${code}/${action}',
                template: 'users/${userId}/carts?template=true&fields=' + templateFields,
                templates: 'users/${userId}/carts?template=true&fields=carts(' + templateFields + '),facets',
                templateImport:
                    'users/${userId}/carts/${cartId}/templateImport/${fromId}?fields=' + cartFields + '&spinner=true',
                downloadOrderDocument: '/users/${userId}/orders/${invoiceId}/${deliveryNote}',
                purchaseReport:
                    '/users/${userId}/purchaseReport?fields=categoryGroupedEntries(FULL),requestedDeliveryDate&spinner=true',
                purchaseReportExcel:
                    '/users/${userId}/purchaseReportExcel?fields=categoryGroupedEntries(FULL)&spinner=true',
            },
        },
    },
};

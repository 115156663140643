import { DOCUMENT } from '@angular/common';
import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CmsService, Page } from '@spartacus/core';
import { ICON_TYPE, NavigationNode } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

import { AimoHamburgerMenuService } from '../../../layout/hamburger-menu/aimo-hamburger-menu.service';
import { AimoNavigationNode } from '../../../service/navigation/aimo-navigation-node.model';
import { AimoUserService } from '../../../service/user/aimo-user.service';

/**
 * override of the original Spartacus NavigationUIComponent
 * added logic: conditional stop event propagation in toggleOpen(event: UIEvent): void function
 * added logic: clickout(event) function
 */

@Component({
    selector: 'aimo-cx-navigation-ui',
    templateUrl: './aimo-navigation-ui.component.html',
})
export class AimoNavigationUiComponent implements OnDestroy, AfterContentInit {
    customerName$: Observable<string>;

    customerId$: Observable<string>;

    pendingApprovals$: Observable<number>;

    @Input() node: AimoNavigationNode;

    /**
     * The number of child nodes that must be wrapped.
     */
    @Input() wrapAfter: number;
    /**
     * the icon type that will be used for navigation nodes
     * with children.
     */
    iconType = ICON_TYPE;

    /**
     * Indicates whether the navigation should support flyout.
     * If flyout is set to true, the
     * nested child navigation nodes will only appear on hover or focus.
     */
    @Input() @HostBinding('class.flyout') flyout = true;

    @Input() @HostBinding('class.is-open') isOpen = false;

    @Input() stripNarrowAvailable: boolean = false;

    @ViewChild('menu')
    menu: ElementRef;

    private openNodes: HTMLElement[] = [];
    private subscriptions = new Subscription();
    private resize = new EventEmitter<void>();
    private currentPage$: Observable<Page>;

    private isOpened = false;

    @HostListener('window:resize')
    onResize(): void {
        this.resize.next();
    }

    @HostListener('document:click', ['$event'])
    clickOut(event: UIEvent): void {
        if (this.menu?.nativeElement && !this.menu?.nativeElement?.contains(event.target)) {
            this.closeMenu(event, this.menu.nativeElement);
        }
    }

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private elemRef: ElementRef,
        private hamburgerMenuService: AimoHamburgerMenuService,
        protected userService: AimoUserService,
        protected cmsService: CmsService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.customerName$ = this.userService.get().pipe(
            map((user) => {
                return user ? user.name : '';
            }),
        );

        this.customerId$ = this.userService.get().pipe(
            map((user) => {
                return user ? user.name : '';
            }),
        );
        this.currentPage$ = this.cmsService.getCurrentPage().pipe(filter(Boolean));
        this.pendingApprovals$ = this.userService.get().pipe(map((user) => user?.pendingApprovals));
        this.subscriptions.add(
            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.clear()),
        );
        this.subscriptions.add(
            this.resize.pipe(debounceTime(50)).subscribe(() => {
                this.alignWrappersToRightIfStickOut();
            }),
        );
    }

    ngAfterContentInit(): void {
        let pageTitle = this.document.getElementsByClassName('page-title');
        if (pageTitle && pageTitle.length > 0) {
            (pageTitle[0] as HTMLElement).focus();
        }
    }

    toggleOpen(event: UIEvent): void {
        if (event.type === 'keydown') {
            event.preventDefault();
        }
        const node = <HTMLElement>event.currentTarget;
        if (this.openNodes.includes(node)) {
            this.closeMenu(event, node);
        } else {
            this.isOpened = true;
            this.openNodes.push(node);
        }

        this.updateClasses();

        /* added !myAccountNode condition to original NavigationUIComponent
     to make clickout work in top level nav
     */

        let myAccountNode = this.node.uid && this.node.uid === 'MyAccountComponent';
        if (!myAccountNode) {
            event.stopImmediatePropagation();
            event.stopPropagation();
        }
    }

    private closeMenu(event: UIEvent, node: HTMLElement): void {
        this.isOpened = false;
        if (event.type === 'keydown') {
            this.back();
        } else {
            this.openNodes = this.openNodes.filter((n) => n !== node);
            this.renderer.removeClass(node, 'is-open');
        }
    }

    back(): void {
        if (this.openNodes[this.openNodes.length - 1]) {
            this.renderer.removeClass(this.openNodes[this.openNodes.length - 1], 'is-open');
            this.openNodes.pop();
            this.updateClasses();
        }
    }

    clear(): void {
        this.openNodes = [];
        this.updateClasses();
    }

    onMouseEnter(event: MouseEvent): void {
        this.alignWrapperToRightIfStickOut(<HTMLElement>event.currentTarget);
        this.focusAfterPreviousClicked(event);
    }

    getTotalDepth(node: AimoNavigationNode, depth = 0): number {
        if (node.children && node.children.length > 0) {
            return Math.max(...node.children.map((n) => this.getTotalDepth(n, depth + 1)));
        } else {
            return depth;
        }
    }

    getColumnCount(length: number): number {
        return Math.round(length / (this.wrapAfter || length));
    }

    focusAfterPreviousClicked(event: MouseEvent): Document {
        const target: HTMLElement = <HTMLElement>(event.target || event.relatedTarget);
        if (target.ownerDocument.activeElement.matches('nav[tabindex]') && target.parentElement.matches('.flyout')) {
            target.focus();
        }
        return target.ownerDocument;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private alignWrapperToRightIfStickOut(node: HTMLElement): void {
        const wrapper = <HTMLElement>node.querySelector('.wrapper');
        const body = <HTMLElement>node.closest('body');
        if (wrapper) {
            this.renderer.removeStyle(wrapper, 'margin-left');
            if (wrapper.offsetLeft + wrapper.offsetWidth > body.offsetLeft + body.offsetWidth) {
                this.renderer.setStyle(wrapper, 'margin-left', `${node.offsetWidth - wrapper.offsetWidth}px`);
            }
        }
    }

    private alignWrappersToRightIfStickOut(): void {
        const navs = <HTMLCollection>this.elemRef.nativeElement.childNodes;
        Array.from(navs)
            .filter((node) => node.tagName === 'NAV')
            .forEach((nav) => this.alignWrapperToRightIfStickOut(<HTMLElement>nav));
    }

    private updateClasses(): void {
        this.openNodes.forEach((node, i) => {
            if (i + 1 < this.openNodes.length) {
                this.renderer.addClass(node, 'is-opened');
                this.renderer.removeClass(node, 'is-open');
            } else {
                this.renderer.removeClass(node, 'is-opened');
                this.renderer.addClass(node, 'is-open');
            }
        });

        this.isOpen = this.openNodes.length > 0;
    }

    closeIfClickedTheSameLink(navNode: NavigationNode): void {
        if (typeof navNode.url === 'string' && this.document.defaultView.window?.location.href.includes(navNode.url)) {
            this.hamburgerMenuService.toggle(true);
        }
    }

    isActivePage(url: string): boolean {
        let isActive = false;
        if (url) {
            this.currentPage$
                .subscribe((page) => {
                    if (page.label?.includes(url)) {
                        isActive = true;
                    }
                })
                .unsubscribe();
        }

        return isActive;
    }
}

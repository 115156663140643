import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { FormUtils } from '../util/form-utils';

@Component({
    selector: 'aimo-cx-input',
    templateUrl: './aimo-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoInputComponent implements AfterViewInit {
    @Input() counterStep: number = 1;
    @Input() disabled: boolean;
    @Input() disabledIcon: string;
    @Input() errorMessage: string;
    @Input() extraInfoBottom: string;
    @Input() hint: string; // Translated string
    @Input() idName: string;
    @Input() inputValue: string | number;
    @Input() isNotValidFunction: boolean;
    @Input() isShowPasswordVisible: boolean;
    @Input() isTextArea: boolean;
    @Input() label: string;
    @Input() labelTooltip: string;
    @Input() max: number;
    @Input() maxlength: number = 50000;
    @Input() readOnly: boolean = false;
    @Input() min: number = 0;
    @Input() nameForm: string;
    @Input() nonEditable: boolean;
    @Input() parentForm: FormGroup;
    @Input() placeholder: string;
    @Input() required: boolean = false;
    @Input() showControls: boolean = false;
    @Input() subLabel: string;
    @Input() submitClicked: boolean;
    @Input() typeInput: string;
    @Input() componentClass: string;
    @Input() labelClass: string;
    @Input() inputIcon: string;
    @Input() inputClass: string;
    @Input() isLeftIcon: boolean = true;
    @Input() arialLabel: string;
    // eslint-disable-next-line
    @Output() afterBlur = new EventEmitter<any>();
    // eslint-disable-next-line
    @Output() afterFocus = new EventEmitter<any>();
    // eslint-disable-next-line
    @Output() afterChange = new EventEmitter<any>();
    // eslint-disable-next-line
    @Output() afterMouseOut = new EventEmitter<any>();
    @Output() afterEnter = new EventEmitter<string>();
    // eslint-disable-next-line
    @Output() afterKeyPress = new EventEmitter<any>();
    @Output() disabledIconAction = new EventEmitter<void>();
    // eslint-disable-next-line
    @Output() afterIconClick = new EventEmitter<any>();
    @Output() afterInput = new EventEmitter<string>();
    @Input() cols: number = 20;
    @Input() rows: number = 3;
    @Input() autofocus: boolean;

    @ViewChild('inputElement') inputElement: ElementRef;

    constructor(
        private eRef: ElementRef,
        @Inject(DOCUMENT) protected document: Document,
    ) {}

    isNotValid(formControlName: string): boolean {
        if (this.parentForm != null) {
            return FormUtils.isNotValidField(this.parentForm, formControlName, this.submitClicked);
        } else {
            return true;
        }
    }

    hasValue(): boolean {
        if (this.parentForm) {
            return (
                this.parentForm.get(this.nameForm) &&
                this.parentForm.get(this.nameForm).value &&
                this.parentForm.get(this.nameForm).value !== ''
            );
        } else if (this.inputValue) {
            return this.inputValue !== '';
        } else {
            return false;
        }
    }

    toggleVisibility(elId: string): void {
        const input: HTMLInputElement = this.document.getElementById(elId) as HTMLInputElement;
        if (input.type === 'password') {
            input.type = 'text';
        } else {
            input.type = 'password';
        }
    }

    isRequired(): boolean {
        if (this.parentForm) {
            return this.parentForm.get(this.nameForm)?.hasValidator(Validators.required);
        } else {
            return this.required;
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event): void {
        let node = <HTMLElement>this.eRef.nativeElement;
        if (!node.classList?.contains('input-label')) {
            node = node.querySelector('.input-label');
        }
        const input = node?.querySelector('.aimo-input');
        if (this.eRef.nativeElement.contains(event.target)) {
            node?.classList?.add('has-value');
            (input as HTMLElement)?.focus();
        } else {
            if (!this.hasValue()) {
                node?.classList?.remove('has-value');
                (input as HTMLElement)?.blur();
            }
        }
    }

    decrement(): void {
        if (this.parentForm) {
            if (Number(this.parentForm.get(this.nameForm).value) >= this.min) {
                this.parentForm
                    .get(this.nameForm)
                    .setValue(Number(this.parentForm.get(this.nameForm).value) - Number(this.counterStep));

                if (this.parentForm.get(this.nameForm).value < this.min) {
                    this.parentForm.get(this.nameForm).setValue(0);
                }
            }
        } else {
            if (Number(this.inputValue) >= this.min) {
                this.inputValue = Number(this.inputValue) - Number(this.counterStep);

                if (this.inputValue < this.min) {
                    this.inputValue = 0;
                }
            }
        }
    }

    increment(): void {
        if (this.parentForm) {
            if (this.max) {
                if (Number(this.parentForm.get(this.nameForm).value) <= this.max) {
                    this.parentForm
                        .get(this.nameForm)
                        .setValue(Number(this.parentForm.get(this.nameForm).value) + Number(this.counterStep));

                    if (this.parentForm.get(this.nameForm).value > this.max) {
                        this.parentForm.get(this.nameForm).setValue(this.max);
                    }
                }
            } else {
                this.parentForm
                    .get(this.nameForm)
                    .setValue(Number(this.parentForm.get(this.nameForm).value) + Number(this.counterStep));
            }
        } else {
            if (this.max) {
                if (Number(this.inputValue) <= this.max) {
                    this.inputValue = Number(this.inputValue) + Number(this.counterStep);

                    if (this.inputValue > this.max) {
                        this.inputValue = this.max;
                    }
                }
            } else {
                this.inputValue = Number(this.inputValue) + Number(this.counterStep);
            }
        }
    }

    onBlur($event: FocusEvent): void {
        this.afterBlur.emit($event);
    }

    onFocus($event: FocusEvent): void {
        this.afterFocus.emit($event);
    }

    // eslint-disable-next-line
    onChange($event: any): void {
        this.afterChange.emit($event);
    }

    // eslint-disable-next-line
    onMouseOut($event: any): void {
        this.afterMouseOut.emit($event);
    }

    // eslint-disable-next-line
    onKeypress($event: any): void {
        this.afterKeyPress.emit($event);
    }

    // eslint-disable-next-line
    onEnter($event: any): void {
        this.afterEnter.emit($event);
    }

    onDisabledActionIcon(): void {
        this.disabledIconAction.emit();
    }

    // eslint-disable-next-line
    onIconClick($event: any): void {
        this.afterIconClick.emit($event);
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            setTimeout(() => {
                this.inputElement.nativeElement.focus();
            }, 100);
        }
    }

    onInputChange($event: string): void {
        this.afterInput.emit($event);
    }
}

<div class="container" *ngIf="category$ | async as category">
    <div class="row no-gutters">
        <div class="col-12" [ngClass]="{ 'col-lg-6': category.image?.url }">
            <div class="category-content" [ngClass]="{ 'category-content-half-width': category.image?.url }">
                <ng-container *ngIf="breadCrumbs$ | async as breadcrumbs">
                    <aimo-breadcrumb
                        *ngIf="breadcrumbs.length > 0; else normalBreadcrumb"
                        [hideTitle]="true"
                        [isProductBreadcrumb]="breadcrumbs.length > 1"
                        [breadCrumbs]="breadcrumbs"
                        [showBackLink]="false">
                    </aimo-breadcrumb>
                </ng-container>
                <ng-template #normalBreadcrumb>
                    <aimo-breadcrumb [hideTitle]="true" [showBackLink]="false" [contentPageTitle]="category.name">
                    </aimo-breadcrumb>
                </ng-template>
                <div class="category-description">
                    <h1>{{ category.name }}</h1>
                    <div [innerHTML]="category.description">{{ category.description }}</div>
                </div>
                <ng-container *ngIf="category.level < 2 && !category.dummyCategory">
                    <div class="sub-categories-wrapper" *ngIf="categoryHierarchy$ | async as hierarchy">
                        <div class="sub-categories">
                            <aimo-category-header-subcategory
                                [showAll]="showAllCategories"
                                (showAllAction)="toggleShowAllCategories($event)"
                                [values]="hierarchy.subcategories">
                            </aimo-category-header-subcategory>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="category.level > 1 || category.dummyCategory">
                    <ng-container *ngIf="facets$ | async as facet">
                        <ng-container *ngIf="isShowSubCategories(category.level, facet) || category.dummyCategory">
                            <div class="sub-categories-wrapper">
                                <div class="sub-categories">
                                    <a
                                        routerLink="/category/{{ category.code }}"
                                        class="btn-facet btn-sm category"
                                        [ngClass]="{ selected: isAllCategoriesSelected(facet) }">
                                        {{
                                            ('aimo.product.allCategories' | cxTranslate) +
                                                ' ' +
                                                category.name.toLowerCase()
                                        }}
                                        <span class="amount" *ngIf="total$ | async as total">{{ total }}</span>
                                    </a>

                                    <aimo-product-category-facets
                                        [showAll]="showAllCategories"
                                        (showAllAction)="toggleShowAllCategories($event)"
                                        [values]="facet.values"
                                        [link]="'/category/' + category.code"></aimo-product-category-facets>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="col-12 col-lg-6" *ngIf="category.image?.url">
            <div class="category-image">
                <cx-media [container]="category.image" class="d-none d-lg-block"></cx-media>
            </div>
        </div>
    </div>
</div>

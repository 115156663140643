import { Injectable } from '@angular/core';
import { Occ } from '@spartacus/core';
import { UserProfileConnector } from '@spartacus/user/profile/core';
import { Observable } from 'rxjs';

import {
    AimoB2BUnitOption,
    AimoBulletinList,
    AimoContactUs,
    AimoRegistration,
    AimoUser,
    AimoUserRegistrationData,
    B2BUnitOption,
    KitchenAlcoholInfo,
    OpeningHour,
} from '../../model/user.model';

import { AimoOccUserProfileAdapter } from './aimo-occ-user-profile.adapter';

import User = Occ.User;

@Injectable({
    providedIn: 'root',
})
export class AimoUserProfileConnector extends UserProfileConnector {
    constructor(protected adapter: AimoOccUserProfileAdapter) {
        super(adapter);
    }

    searchUnits(userId: string, term: string): Observable<B2BUnitOption[]> {
        return this.adapter.searchUnit(userId, term);
    }

    selectUnit(userId: string, uid: string, fromSearch: boolean): Observable<User> {
        return this.adapter.selectUnit(userId, uid, fromSearch);
    }

    toggleFavorite(userId: string, productPk: number): Observable<void> {
        return this.adapter.toggleFavorite(userId, productPk);
    }

    setPassword(token: string, newPassword: string): Observable<unknown> {
        return this.adapter.setPassword(token, newPassword);
    }

    contactUs(body: AimoContactUs): Observable<unknown> {
        return this.adapter.contactUs(body);
    }

    closeUserAccount(userId: string): Observable<unknown> {
        return this.adapter.close(userId);
    }

    registerCustomer(userId: string, data: AimoRegistration): Observable<unknown> {
        return this.adapter.registerCustomer(userId, data);
    }

    addOrModifyUser(userId: string, data: AimoUserRegistrationData): Observable<unknown> {
        return this.adapter.addOrModifyUser(userId, data);
    }

    modifyB2BUnit(userId: string, data: AimoB2BUnitOption): Observable<unknown> {
        return this.adapter.modifyB2BUnit(userId, data);
    }

    getMyOrganizationDetails(userId: string): Observable<AimoUser> {
        return this.adapter.getMyOrganizationDetails(userId);
    }

    getAlchoholQuotaInfo(userId: string): Observable<KitchenAlcoholInfo> {
        return this.adapter.getAlchoholQuotaInfo(userId);
    }

    getActiveBulletinList(category: string, id: string): Observable<AimoBulletinList> {
        return this.adapter.getActiveBulletinList(category, id);
    }

    giveConsent(userId: string): Observable<unknown> {
        return this.adapter.giveConsent(userId);
    }

    getStoreOpeningHours(): Observable<OpeningHour[]> {
        return this.adapter.getStoreOpeningHours();
    }

    setHideNormalOrderTemplatesFromBasicUsers(userId: string, value: boolean): Observable<User> {
        return this.adapter.setHideNormalOrderTemplatesFromBasicUsers(userId, value);
    }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import {
    CmsActions,
    CmsService,
    EventService,
    HttpErrorModel,
    LoginEvent,
    ProductActions,
    RoutingService,
    UserIdService,
    WindowRef,
} from '@spartacus/core';
import { UserAccountChangedEvent } from '@spartacus/user/account/root';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { openCloseSpinner } from '../../cms-components/shared/utils/spinner/aimo-spinner-utils';
import { AimoActiveCartService } from '../cart/aimo-active-cart.service';
import { AimoAssortmentService } from '../product/aimo-assortment.service';

import { AimoUserProfileConnector } from './aimo-user-profile.connector';
import {
    ADD_USER,
    AddOrModifyUser,
    AddOrModifyUserFailed,
    AddOrModifyUserSuccess,
    AIMO_GIVE_CONSENT,
    AIMO_SET_HIDE_ORDER_TEMPLATES_FROM_BASIC_USERS,
    AimoGiveConsent,
    AimoGiveConsentSuccess,
    AimoSetHideNormalOrderTemplatesFromBasicUsers,
    MODIFY_B2B_UNIT,
    ModifyB2BUnit,
    ModifyB2BUnitFailed,
    ModifyB2BUnitSuccess,
    Register,
    REGISTER,
    RegisterFailed,
    RegisterSuccess,
    SELECT_UNIT,
    SELECT_UNIT_SUCCESS,
    SelectUnit,
    SelectUnitSuccess,
    TOGGLE_FAVOURITE_PRODUCT,
    ToggleFavouriteProduct,
    ToggleFavouriteProductFail,
    ToggleFavouriteProductSuccess,
} from './aimo-user.action';

@Injectable()
export class AimoUserEffects {
    toggleFavouriteProducts$: Observable<ToggleFavouriteProductSuccess | ToggleFavouriteProductFail>;

    setHideNormalOrderTemplatesFromBasicUsers$: Observable<AddOrModifyUserSuccess>;

    selectUnit$: Observable<CartActions.LoadCart | SelectUnitSuccess>;

    selectUnitSuccess$: Observable<
        CartActions.LoadCart | ProductActions.ClearProductSearchResult | CmsActions.LoadCmsPageData
    >;

    register$: Observable<RegisterSuccess | RegisterFailed>;

    addOrModifyUser$: Observable<AddOrModifyUserSuccess | AddOrModifyUserFailed>;

    modifyB2BUnit$: Observable<ModifyB2BUnitSuccess | ModifyB2BUnitFailed>;

    giveConsent$: Observable<AimoGiveConsentSuccess>;

    constructor(
        private actions$: Actions,
        private userProfileConnector: AimoUserProfileConnector,
        protected activeCartService: AimoActiveCartService,
        protected cmsService: CmsService,
        protected userIdService: UserIdService,
        protected eventService: EventService,
        protected routingService: RoutingService,
        protected assortmentService: AimoAssortmentService,
        protected winRef: WindowRef,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.toggleFavouriteProducts$ = createEffect(() =>
            this.actions$.pipe(
                ofType(TOGGLE_FAVOURITE_PRODUCT),
                map((action: ToggleFavouriteProduct) => action.payload),
                switchMap(({ userId, productPk }) =>
                    this.userProfileConnector.toggleFavorite(userId, productPk).pipe(
                        map(() => new ToggleFavouriteProductSuccess()),
                        catchError(() => of(new ToggleFavouriteProductFail())),
                    ),
                ),
            ),
        );

        this.setHideNormalOrderTemplatesFromBasicUsers$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_SET_HIDE_ORDER_TEMPLATES_FROM_BASIC_USERS),
                switchMap((action: AimoSetHideNormalOrderTemplatesFromBasicUsers) =>
                    this.userProfileConnector
                        .setHideNormalOrderTemplatesFromBasicUsers(action.userId, action.value)
                        .pipe(
                            tap((user) => {
                                this.eventService.dispatch({ user: user }, UserAccountChangedEvent);
                            }),
                            map(() => new AddOrModifyUserSuccess()),
                        ),
                ),
            ),
        );

        this.selectUnit$ = createEffect(() =>
            this.actions$.pipe(
                ofType(SELECT_UNIT),
                map((action: SelectUnit) => action),
                switchMap((action) => {
                    openCloseSpinner(this.document, true);
                    return this.userProfileConnector.selectUnit(action.userId, action.unit, action.fromSearch).pipe(
                        concatMap(async (user) => {
                            await this.routingService.goByUrl('/');
                            //   this.routingService.goByUrl('/');
                            /*const sessionValue = JSON.parse(this.winRef.localStorage.getItem(DEFAULT_LOCAL_STORAGE_KEY));
                                 sessionValue.user = user;
                                 this.winRef.localStorage.setItem(DEFAULT_LOCAL_STORAGE_KEY, JSON.stringify(sessionValue)); */ // update storage user
                            const evt = new UserAccountChangedEvent();
                            evt.user = user;
                            this.eventService.dispatch(evt);
                            this.userIdService.setUserId(action.userId);
                            return new SelectUnitSuccess(action.userId, action.unit);
                        }),
                    );
                }),
            ),
        );

        this.selectUnitSuccess$ = createEffect(() =>
            this.actions$.pipe(
                ofType(SELECT_UNIT_SUCCESS),
                map((action: SelectUnit) => action),
                switchMap((action) => {
                    this.assortmentService.setOnlyMyAssortment(false);
                    openCloseSpinner(this.document, false);
                    this.document.location.reload();
                    return from([
                        new CartActions.LoadCart({
                            userId: action.userId,
                            cartId: 'current',
                        }),
                        new ProductActions.ClearProductSearchResult({
                            clearPageResults: true,
                            clearSearchboxResults: true,
                        }),
                        new CmsActions.LoadCmsPageData(action.pageContext),
                    ]);
                }),
            ),
        );

        this.register$ = createEffect(() =>
            this.actions$.pipe(
                ofType(REGISTER),
                map((action: Register) => action),
                switchMap((action) => {
                    return this.userProfileConnector.registerCustomer(action.userId, action.data).pipe(
                        map(() => {
                            return new RegisterSuccess();
                        }),
                        catchError((error: HttpErrorModel) => of(new RegisterFailed(error.details[0].message))),
                    );
                }),
            ),
        );

        this.addOrModifyUser$ = createEffect(() =>
            this.actions$.pipe(
                ofType(ADD_USER),
                map((action: AddOrModifyUser) => action),
                switchMap((action) => {
                    return this.userProfileConnector.addOrModifyUser(action.userId, action.data).pipe(
                        map(() => {
                            return new AddOrModifyUserSuccess();
                        }),
                        catchError((error: HttpErrorModel) => of(new AddOrModifyUserFailed(error.details[0].message))),
                    );
                }),
            ),
        );

        this.modifyB2BUnit$ = createEffect(() =>
            this.actions$.pipe(
                ofType(MODIFY_B2B_UNIT),
                map((action: ModifyB2BUnit) => action),
                switchMap((action) => {
                    return this.userProfileConnector.modifyB2BUnit(action.userId, action.data).pipe(
                        map(() => {
                            return new ModifyB2BUnitSuccess();
                        }),
                        catchError((error: HttpErrorModel) => of(new ModifyB2BUnitFailed(error.details[0].message))),
                    );
                }),
            ),
        );

        this.giveConsent$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_GIVE_CONSENT),
                map((action: AimoGiveConsent) => action),
                switchMap((action) =>
                    this.userProfileConnector.giveConsent(action.userId).pipe(
                        map(() => {
                            this.userIdService.setUserId(action.userId);
                            this.eventService.dispatch(new LoginEvent());
                            return new AimoGiveConsentSuccess(action.userId);
                        }),
                    ),
                ),
            ),
        );
    }
}

<ng-container *ngIf="product">
    <aimo-product-tag [product]="product" />

    <div
        (mouseleave)="hideAddToCart()"
        class="aimo-product-grid-item"
        id="product_{{ product.code }}"
        [ngClass]="{ 'lowest-comparison-price': product.lowestPriceInComparison }">
        <div class="cx-actions">
            <ng-container *ngIf="!isComparisonProduct">
                <button
                    [attr.aria-label]="'productList.comparison.tooltip' | cxTranslate"
                    [title]="'productList.comparison.tooltip' | cxTranslate"
                    (click)="addToComparison(product)"
                    class="btn btn-primary btn-icon btn-icon-light btn-sm"
                    placement="top"
                    container="body"
                    ngbPopover="{{ 'productList.comparison.tooltip' | cxTranslate }}"
                    triggers="mouseenter:mouseleave"
                    popoverClass="popover-default">
                    <i class="aimo-icon icon-sm icon-arrow-left-right"></i>
                </button>

                <ng-container *ngIf="user$ | async as user">
                    <button
                        *ngIf="
                            !isPikatukku() &&
                            (loggedUser$ | async) &&
                            (user.customerAdmin || !user.isHideNormalOrderTemplatesFromBasicUsers)
                        "
                        [id]="'product_add_to_template_' + idSuffix"
                        (click)="addToOrderTemplate('product_add_to_template_' + idSuffix)"
                        [attr.aria-label]="'productList.orderTemplate.tooltip' | cxTranslate"
                        [title]="'productList.orderTemplate.tooltip' | cxTranslate"
                        class="btn btn-primary btn-icon btn-icon-light btn-sm d-inline-flex"
                        placement="top"
                        container="body"
                        ngbPopover="{{ 'productList.orderTemplate.tooltip' | cxTranslate }}"
                        triggers="mouseenter:mouseleave"
                        popoverClass="popover-default">
                        <i class="aimo-icon icon-sm icon-file-plus"></i>
                    </button>
                </ng-container>

                <button
                    *ngIf="loggedUser$ | async"
                    [attr.aria-label]="(product.favorite ? 'aimo.favorite.remove' : 'aimo.favorite.add') | cxTranslate"
                    class="btn btn-primary btn-icon btn-icon-light btn-sm d-inline-flex"
                    placement="top"
                    [ngbPopover]="(product.favorite ? 'aimo.favorite.remove' : 'aimo.favorite.add') | cxTranslate"
                    container="body"
                    triggers="mouseenter:mouseleave"
                    popoverClass="popover-default popover-hide-mobile"
                    (click)="toggleFavorite()">
                    <i
                        class="aimo-icon icon-sm icon-star"
                        [ngClass]="{
                            'icon-star-filled': product.favorite,
                            'icon-star': !product.favorite,
                        }"></i>
                </button>
            </ng-container>
            <ng-container *ngIf="isComparisonProduct">
                <button
                    [attr.aria-label]="'aimo.product.comparison.remove' | cxTranslate"
                    [title]="'aimo.product.comparison.remove' | cxTranslate"
                    class="btn btn-primary btn-icon btn-icon-light btn-sm d-inline-flex"
                    (click)="removeFromComparisonList(product.code)">
                    <i class="aimo-icon icon-sm icon-x"></i>
                </button>
            </ng-container>
        </div>

        <div class="cx-product-image-container">
            <!-- href is for google bots to follow links -->
            <a tabindex="-1" role="button" [href]="product.url" (click)="doNotOpen($event)">
                <cx-media
                    [id]="'product_image_' + idSuffix"
                    (click)="openProductModal($event, 'product_image_' + idSuffix)"
                    class="cx-product-image"
                    [container]="product.images?.PRIMARY"
                    format="product"
                    [title]="product.name"
                    [alt]="product.name"></cx-media>
            </a>
        </div>

        <div class="product-name-wrapper">
            <button
                [id]="'product_name_' + idSuffix"
                class="btn-link text-left"
                (click)="openProductModal($event, 'product_name_' + idSuffix)">
                <span
                    class="cx-product-name"
                    [attr.aria-label]="
                        'aimo.product.openModal.arialLabel' | cxTranslate: { productName: product.nameHtml }
                    "
                    [innerHTML]="product.nameHtml">
                </span>
            </button>

            <div class="product-code text-dark-grey">
                <aimo-product-code [product]="product"></aimo-product-code>
            </div>
        </div>

        <div class="price" *ngIf="product.price">
            <div class="active-price">
                <i
                    *ngIf="product.myAssortment"
                    placement="top"
                    container="body"
                    triggers="mouseenter:mouseleave"
                    [ngbPopover]="'aimo.product.myAssortment' | cxTranslate"
                    class="aimo-icon icon-inline icon-smd icon-house-line-bold"
                    [ngClass]="product.strikeThroughPrice ? 'icon-red' : 'icon-petrol-blue'"></i>
                <span
                    [attr.aria-label]="'aimo.product.price.arialLabel' | cxTranslate"
                    class="align-baseline"
                    [ngClass]="
                        product.strikeThroughPrice ? 'promo-price' : product.myAssortment ? 'my-assortment-price' : ''
                    ">
                    <span class="price-value">{{ product.price?.formattedValue }}</span>
                    <span class="d-none d-md-inline"> / {{ product.unit?.name }}</span>
                </span>
                <span
                    class="text-strike align-baseline ml-2"
                    [attr.aria-label]="'aimo.product.strikePrice.arialLabel' | cxTranslate"
                    *ngIf="product.strikeThroughPrice">
                    {{ product.strikeThroughPrice.formattedValue }}
                </span>
            </div>

            <div class="row position-relative add-to-cart-row">
                <div
                    class="col-auto mr-auto comparison-price"
                    [ngClass]="{
                        hidden: (isStepperVisible$ | async),
                        'mobile-max-width':
                            (isPikatukku() && !product.pikatukkuWebShopProduct) ||
                            (!isPikatukku() &&
                                product.purchasable &&
                                !(product.stock.stockLevelStatus === 'outOfStock' && product.similarProducts)),
                        'web-shop-product-max-width': product.pikatukkuWebShopProduct,
                    }">
                    <div class="text-dark-grey">
                        <span class="align-middle" *ngIf="product.comparisonPrice">
                            <span class="d-inline-block mr-1">{{ product.comparisonPrice.formattedValue }}</span>
                            <span class="d-inline-block"> / {{ product.comparisonUnit?.name }}</span>
                        </span>
                        <span
                            [ngClass]="{ 'lowest-comparison-price': product.lowestPriceInComparison }"
                            *ngIf="product.lowestPriceInComparison">
                            <i class="aimo-icon icon-inline icon-summer-green icon-sm icon-arrow-down"></i>
                            <span class="ml-0">{{ 'aimo.product.lowestPriceInComparison' | cxTranslate }}</span>
                        </span>
                    </div>

                    <div class="text-dark-grey">
                        <span class="d-inline-block mr-1">{{ product.priceWithTax?.formattedValue }}</span>
                        <span class="d-inline-block mr-1"> / {{ product.unit?.name }}</span>
                        <span class="d-inline-block">{{
                            'aimo.product.vat' | cxTranslate: { amount: product.taxPercentage }
                        }}</span>
                    </div>
                </div>
                <div
                    [ngClass]="[
                        (isStepperVisible$ | async) ? 'wide-add-to-cart' : '',
                        product.pikatukkuWebShopProduct ? 'col-auto web-shop-product-col' : 'cx-add-to-cart',
                    ]">
                    <aimo-cx-add-to-cart
                        *ngIf="
                            (isPikatukku() && (product.purchasable || !product.pikatukkuWebShopProduct)) ||
                            (!isPikatukku() &&
                                product.purchasable &&
                                !(product.stock.stockLevelStatus === 'outOfStock' && product.similarProducts))
                        "
                        class="d-inline float-right add-to-cart-sm noPrint"
                        (showHideStepper)="showHideStepper($event)"
                        [showQuantity]="showQuantity"
                        [product]="product"
                        [gtmProductAttributes]="gtmProductAttributes"
                        [isPLP]="true"
                        [addToCartCallBack]="addToCartCallBack"
                        [options]="{
                            displayAddToCart: true,
                            addToCartString: '',
                            iconAddToCart: 'aimo-icon icon-white icon-shopping-cart-simple icon-sm',
                        }">
                    </aimo-cx-add-to-cart>
                    <div class="web-shop-product d-inline-block float-right" *ngIf="product.pikatukkuWebShopProduct">
                        {{ 'aimo.product.shopProduct' | cxTranslate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="product-stock">
            <div class="cx-stock">
                <aimo-product-availability [stock]="product.stock" *ngIf="!isPikatukku()"></aimo-product-availability>
                <ng-container *ngIf="isPikatukku()">
                    <div *ngFor="let ptstock of product.pikatukkuStock" class="cx-stock">
                        <aimo-product-availability [suggestiveMode]="true" [printStockName]="true" [stock]="ptstock">
                        </aimo-product-availability>
                    </div>
                </ng-container>
            </div>
            <div
                class="d-inline float-right similar-products ml-2"
                *ngIf="product.stock.stockLevelStatus === 'outOfStock' && product.similarProducts">
                <button
                    [id]="'product_stock_' + idSuffix"
                    class="btn-link"
                    (click)="openProductModal($event, 'product_stock_' + idSuffix, 'similar-products-container')">
                    <span class="text-underline">{{ 'aimo.product.lookSimilarProductsShort' | cxTranslate }}</span>
                </button>
            </div>
            <div class="float-right">
                <div class="d-inline aimo-popover">
                    <i
                        class="aimo-icon icon-sm icon-clock-counter-clockwise icon-inline icon-dark-grey"
                        *ngIf="product.lastPurchased"
                        triggers="mouseenter:mouseleave click"
                        placement="bottom"
                        container="body"
                        [ngbPopover]="
                            'aimo.product.lastPurchased'
                                | cxTranslate
                                    : {
                                          date:
                                              (convertDate(product.lastPurchased)
                                              | cxDate: ('aimo.dateFormat' | cxTranslate)),
                                      }
                        "></i>
                </div>
                <div
                    class="d-inline aimo-popover"
                    *ngIf="product.stock.closingTime"
                    ngbPopover="{{
                        'productList.closingTime.tooltip'
                            | cxTranslate: { time: (convertDate(product.stock.closingTime) | cxDate: 'HH:mm') }
                    }}"
                    placement="bottom"
                    container="body"
                    triggers="mouseenter:mouseleave"
                    popoverClass="popover-default">
                    <i class="aimo-icon icon-inline icon-dark-grey icon-clock icon-sm mr-1"></i>
                    <span class="text-lowercase">{{ convertDate(product.stock.closingTime) | cxDate: 'HH:mm' }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

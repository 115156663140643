import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductSearchConnector } from '@spartacus/core';
import { CmsComponent } from '@spartacus/core/src/model/cms.model';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { AimoSearchConfig } from '../../../model/misc.model';
import { AimoProduct } from '../../../model/product.model';
import { AimoGTMProductAttributes, GTMCartType, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoProductService } from '../../../service/product/aimo-product.service';

export const SEARCH_CONFIG: AimoSearchConfig = { currentPage: 0, pageSize: 19, sort: 'relevance' };

@Component({
    selector: 'aimo-cms-carousel',
    templateUrl: './aimo-cms-carousel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoCMSCarouselComponent {
    data$: Observable<AimoCMSCarouselComponentData>;
    products$: Observable<Observable<AimoProduct>[]>;
    carouselProducts: Observable<AimoProduct>[];
    carouselType: GTMItemListId;
    klevuBannerId: string;
    klevuBannerTitle: string;

    constructor(
        protected componentData: CmsComponentData<AimoCMSCarouselComponentData>,
        protected productService: AimoProductService,
        protected productSearchConnector: ProductSearchConnector,
        protected activatedRoute: ActivatedRoute,
    ) {
        this.data$ = componentData.data$;
        this.products$ = this.data$.pipe(
            take(1),
            switchMap((component) => this.getProducts(component)),
            map((l) => l?.map((p) => of(p))),
        );
    }

    getProducts(component: AimoCMSCarouselComponentData): Observable<AimoProduct[]> {
        const sort = component.randomSort === 'true' ? 'random' : 'relevance';
        const conf = { ...SEARCH_CONFIG, sort: sort };
        if (component.carouselType === CAROUSEL_TYPE.PRODUCT_LIST) {
            if (component.productCodes) {
                return this.productService
                    .getProducts(component.productCodes.split(' '))
                    .pipe(
                        map((list) =>
                            list.sort(() => (component.randomSort === 'true' ? (Math.random() > 0.5 ? 1 : -1) : 1)),
                        ),
                    );
            } else if (component.categoryCodes) {
                const param = component.categoryCodes
                    .split(' ')
                    .map((p) => ':allCategories:' + p)
                    .join('');
                return this.productSearchConnector.search('*:' + sort + param, conf).pipe(map((res) => res.products));
            }
        } else if (component.carouselType === CAROUSEL_TYPE.LAST_PURCHASES) {
            this.carouselType = GTMItemListId.mostPurchased;
            return this.productSearchConnector
                .search('*:' + sort + ':mostPurchased:true', conf)
                .pipe(map((res) => res.products));
        } else if (component.carouselType === CAROUSEL_TYPE.NOVELTY) {
            this.carouselType = GTMItemListId.novelty;
            return this.productSearchConnector
                .search('*:' + sort + ':novelty:true', conf)
                .pipe(map((res) => res.products));
        } else if (component.carouselType === CAROUSEL_TYPE.CAMPAIGN) {
            this.carouselType = GTMItemListId.campaign;
            return this.productSearchConnector
                .search('*:' + sort + ':campaign:true', conf)
                .pipe(map((res) => res.products));
        } else if (component.carouselType === CAROUSEL_TYPE.KLEVU_RECOMMENDATION) {
            //   this.carouselType = GTMItemListId.campaign;
            const category =
                this.activatedRoute.snapshot.url?.length >= 2 && this.activatedRoute.snapshot.url[0].path === 'category'
                    ? ':category:' + this.activatedRoute.snapshot.url[1].path
                    : '';
            this.klevuBannerId = component.klevuGuid;
            this.klevuBannerTitle = component.title;
            return this.productSearchConnector
                .search('*:' + sort + ':klevu:' + component.klevuGuid + category, {
                    ...conf,
                    klevuBannerId: this.klevuBannerId,
                    klevuBannerTitle: this.klevuBannerTitle,
                } as AimoSearchConfig)
                .pipe(map((res) => res.products));
        }
        return of();
    }

    createGtmAttributes(idx: number, product: AimoProduct): AimoGTMProductAttributes {
        return {
            index: idx,
            item_list_id: this.carouselType,
            cart_type: GTMCartType.cart,
            klevuParams: this.klevuBannerId
                ? {
                      klevuBannerId: this.klevuBannerId,
                      klevuBannerTitle: this.klevuBannerTitle,
                      klevuBannerType: product.klevuBannerType,
                      klevuBannerPageType: product.klevuBannerPageType,
                  }
                : null,
        } as AimoGTMProductAttributes;
    }
}

interface AimoCMSCarouselComponentData extends CmsComponent {
    title?: string;
    carouselType?: string;
    productCodes?: string;
    showAllText?: string;
    showAllUrl?: string;
    klevuGuid?: string;
    randomSort?: string;
    categoryCodes?: string;
}

enum CAROUSEL_TYPE {
    PRODUCT_LIST = 'PRODUCT_LIST',
    LAST_PURCHASES = 'LAST_PURCHASES',
    NOVELTY = 'NOVELTY',
    CAMPAIGN = 'CAMPAIGN',
    REFERENCE = 'REFERENCE',
    KLEVU_RECOMMENDATION = 'KLEVU_RECOMMENDATION',
}

<ng-container>
    <div class="row no-gutters bg-petrol-light p-2 p-md-3 cart-totals">
        <h3 class="col-12 text-default mb-3 d-none d-md-block">
            {{ 'aimo.cart.totals.summary' | cxTranslate }}
        </h3>
        <h4 class="col-12 text-default mb-3 d-block d-md-none">
            {{ 'aimo.cart.totals.summary' | cxTranslate }}
        </h4>

        <p
            class="col-12 text-md text-bold mt-2 mb-3"
            *ngIf="hasPrevious && cart.dailyTotalWithoutTax !== null && cart.totalPrice?.value > 0">
            {{
                hasModifiedEntries && !hasNewEntries
                    ? ('aimo.cart.totals.editedEntries' | cxTranslate)
                    : hasModifiedEntries && hasNewEntries
                      ? ('aimo.cart.totals.newOrEdited' | cxTranslate)
                      : ('aimo.cart.totals.newEntries' | cxTranslate)
            }}
        </p>

        <div class="col-8">
            {{ 'aimo.cart.totals.subtotal' | cxTranslate }}
        </div>
        <div class="col-4 text-right">
            {{ cart.subTotal?.formattedValue }}
        </div>

        <ng-container *ngIf="cart.deposits && false">
            <div class="col-8">
                {{ 'aimo.cart.totals.deposits' | cxTranslate }}
            </div>
            <div class="col-4 text-right">
                {{ cart.deposits?.formattedValue }}
            </div>
        </ng-container>

        <ng-container *ngIf="!isPikatukku()">
            <div class="col-8">
                <span class="align-middle">{{ 'aimo.cart.totals.deliveryCost' | cxTranslate }}</span>
                <span
                    class="btn btn-xs btn-icon btn-icon-transparent"
                    [ngbPopover]="'aimo.cart.totals.deliveryCostTooltip' | cxTranslate"
                    placement="top"
                    container="body"
                    triggers="mouseenter:mouseleave click"
                    *ngIf="!cart.paymentMode.credit">
                    <i class="icon-inline aimo-icon icon-info icon-petrol-blue icon-sm"></i>
                </span>
            </div>
            <div class="col-4 text-right">
                {{ cart.paymentMode.credit ? cart.deliveryCost?.formattedValue : '...' }}
            </div>
        </ng-container>

        <div class="col-8">
            {{ 'aimo.cart.totals.totalPrice' | cxTranslate }}
        </div>
        <div class="col-4 text-right text-md text-bold">
            {{ cart.totalPrice?.formattedValue }}
        </div>

        <ng-container *ngFor="let tax of cart.taxes">
            <div class="col-8">{{ 'aimo.cart.totals.vat' | cxTranslate: { tax: tax.rate } }}</div>
            <div class="col-4 text-right">
                {{ tax.value.formattedValue }}
            </div>
        </ng-container>

        <ng-container *ngIf="cart.extraCharge">
            <div class="col-8">
                <span class="align-middle">{{ 'aimo.cart.totals.extraCharge' | cxTranslate }}</span>
                <span
                    class="btn btn-xs btn-icon btn-icon-transparent"
                    triggers="mouseenter:mouseleave click"
                    popoverClass="popover-sm"
                    placement="right"
                    container="body"
                    [ngbPopover]="'aimo.cart.totals.extraChargeDescription' | cxTranslate">
                    <i class="icon-inline aimo-icon icon-info icon-petrol-blue icon-sm"></i>
                </span>
            </div>
            <div class="col-4 text-right">
                {{ cart.extraCharge?.formattedValue }}
            </div>
        </ng-container>

        <div class="col-8">
            {{ 'aimo.cart.totals.totalPriceWithTax' | cxTranslate }}
        </div>
        <div class="col-4 text-right text-md text-bold">
            {{ cart.totalPriceWithTax?.formattedValue }}
        </div>

        <ng-container *ngIf="cart.dailyTotalWithoutTax">
            <div class="col-12">
                <hr />
            </div>
            <div class="col-8" [innerHTML]="'aimo.cart.totals.dailyTotalWithoutTax' | cxTranslate">
                {{ 'aimo.cart.totals.dailyTotalWithoutTax' | cxTranslate }}
            </div>
            <div class="col-4 text-right text-md text-bold">
                {{ cart.dailyTotalWithoutTax?.formattedValue }}
            </div>
        </ng-container>

        <button class="noPrint col-12 btn btn-highlighted mt-3" (click)="order(cart)" [disabled]="orderingDisabled()">
            {{ 'aimo.cart.totals.order' + cart.paymentMode.code | cxTranslate }}
        </button>

        <button
            class="noPrint col-12 btn btn-secondary mt-3"
            (click)="removeExternalEntries(cart)"
            *ngIf="hasModifiedEntries">
            {{ 'aimo.cart.totals.removeExternalEntries' | cxTranslate }}
        </button>
    </div>
</ng-container>

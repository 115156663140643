import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AuthActions, LanguageService, OCC_USER_ID_ANONYMOUS, UserActions, UserIdService} from '@spartacus/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Actions} from '@ngrx/effects';
import {AimoUserService} from '../user/aimo-user.service';
import {
    AimoLoadDeliveryDate,
    AimoLoadDeliveryDateSuccess,
    AimoStateWithDeliveryDate,
    LOAD_DELIVERY_DATE_SUCCESS
} from './aimo-deliverydate.action';
import {
    AIMO_ADD_MANY_PRODUCTS,
    AIMO_CART_UPDATE_HEADER_SUCCESS,
    AIMO_ORDER_TEMPLATE_IMPORT
} from "../cart/aimo-cart.action";
import {SELECT_UNIT} from "../user/aimo-user.action";
import {AIMO_CART_ADD_ENTRIES} from "../cart/aimo-entry.action";
import {CartActions} from '@spartacus/cart/base/core';

@Injectable({
    providedIn: 'root',
})
export class AimoDeliveryDateService {
    private deliveryDate$: BehaviorSubject<string>;
    private userId: string;
    private initialLoadingDone = false;

    constructor(
        protected store: Store<AimoStateWithDeliveryDate>,
        protected userService: AimoUserService,
        protected userIdService: UserIdService,
        protected language: LanguageService,
        private actions$: Actions
    ) {
        this.deliveryDate$ = new BehaviorSubject<string>(undefined);
        this.userIdService.getUserId().subscribe((userId) => {
                this.userId = userId;
                if (!this.initialLoadingDone && userId === OCC_USER_ID_ANONYMOUS) {
                    this.initialLoadingDone = true;
                    this.loadDeliveryDate();
                }
            }
        );

        this.actions$.subscribe((action) => {
                if (action.type === LOAD_DELIVERY_DATE_SUCCESS) {
                    this.deliveryDate$.next((action as AimoLoadDeliveryDateSuccess).date);
                }
                if (
                    action.type === AIMO_CART_UPDATE_HEADER_SUCCESS ||
                    action.type === SELECT_UNIT ||
                    action.type === AuthActions.LOGIN ||
                    action.type === CartActions.DELETE_CART_SUCCESS ||
                    action.type === AIMO_ADD_MANY_PRODUCTS ||
                    action.type === AIMO_CART_ADD_ENTRIES ||
                    action.type === AIMO_ORDER_TEMPLATE_IMPORT ||
                    action.type === UserActions.LOAD_USER_CONSENTS_SUCCESS
                ) {
                    this.loadDeliveryDate();
                } else if (!this.initialLoadingDone && action.type === CartActions.LOAD_CART_SUCCESS) {
                    this.initialLoadingDone = true;
                    this.loadDeliveryDate();
                }
            }
        );
    }

    /**
     * can't user cart.requestedDeliveryDate, because this will result to a new catalog search after each add to cart
     */
    getDeliveryDate(): Observable<string> {
        return this.deliveryDate$.asObservable();
    }

    loadDeliveryDate(): void {
        this.store.dispatch(new AimoLoadDeliveryDate(this.userId, 'current'));
    }
}

<div class="order-template" *ngIf="orderTemplate$ | async as orderTemplate">
    <aimo-breadcrumb
        [hideTitle]="true"
        [showBackLink]="false"
        [normalBreadCrumbs]="getBreadcrumbs(orderTemplate, 'aimo.ordertemplates.title' | cxTranslate)">
    </aimo-breadcrumb>
    <h1 class="page-title">
        {{ orderTemplate.name }}
    </h1>
    <div class="row no-gutters mb-3 mb-md-3" [ngClass]="printViewWithTable ? 'noPrint' : ''">
        <div class="col-md-3 col-6">
            <label class="text-bold">{{ 'aimo.ordertemplates.createdAt' | cxTranslate }}</label>
            <div>
                {{
                    'aimo.orderhistory.datetime'
                        | cxTranslate
                            : {
                                  date:
                                      (convertDate(orderTemplate.requestedDeliveryDate)
                                      | cxDate: ('aimo.dateFormat' | cxTranslate)),
                                  time:
                                      (convertDate(orderTemplate.requestedDeliveryDate)
                                      | cxDate: ('aimo.timeFormat' | cxTranslate)),
                              }
                }}
            </div>
            <div *ngIf="orderTemplate.modified">
                ({{ 'aimo.ordertemplates.modifiedAt' | cxTranslate }}
                {{
                    'aimo.orderhistory.datetime'
                        | cxTranslate
                            : {
                                  date:
                                      (convertDate(orderTemplate.modified) | cxDate: ('aimo.dateFormat' | cxTranslate)),
                                  time:
                                      (convertDate(orderTemplate.modified) | cxDate: ('aimo.timeFormat' | cxTranslate)),
                              }
                }})
            </div>
        </div>
        <div class="col-md-3 col-6">
            <label class="text-bold">{{ 'aimo.ordertemplates.createdBy' | cxTranslate }}</label>
            <div>{{ orderTemplate.user.name }}</div>
            <div>{{ orderTemplate.user.email }}</div>
        </div>
        <div class="col-md-3 col-6 pt-3 pt-md-0">
            <label class="text-bold">{{ 'aimo.ordertemplates.visibility' | cxTranslate }}</label>
            <div
                [innerHTML]="
                    'aimo.ordertemplates.privacy.' + orderTemplate.visibility + '_description' | cxTranslate
                "></div>
        </div>
    </div>

    <h2 class="mb-3 d-none d-md-block">{{ 'aimo.cart.item.productTitle' | cxTranslate }}</h2>
    <div class="bg-petrol-light row no-gutters p-2 noPrint mb-4">
        <div class="col-lg-2 col-12">
            <aimo-cx-searchbox
                *ngIf="orderTemplate.editable"
                [cart]="orderTemplate"
                [cartId]="orderTemplateId"
                [orderTemplate]="true"
                id="cartSearchBox"
                [isCartSearchBox]="true"></aimo-cx-searchbox>
        </div>
        <div class="cart-functions float-right col-lg-10 col-12 pt-3 pb-2 pt-lg-0 pb-lg-0 row no-gutters">
            <button
                *ngIf="orderTemplate.editable"
                [id]="'edit'"
                class="btn-link order-1"
                [attr.aria-label]="'aimo.ordertemplates.edit' | cxTranslate"
                [title]="'aimo.ordertemplates.edit' | cxTranslate"
                (click)="edit(orderTemplate, 'edit')">
                <i class="aimo-icon icon-pencil-simple icon-inline"></i>
                <span>{{ 'aimo.ordertemplates.edit' | cxTranslate }}</span>
            </button>

            <button
                [id]="'copy'"
                class="btn-link order-2"
                [attr.aria-label]="'aimo.ordertemplates.copy' | cxTranslate"
                [title]="'aimo.ordertemplates.copy' | cxTranslate"
                [disabled]="orderTemplate.categoryGroupedEntries?.length <= 0"
                (click)="copy()">
                <i class="aimo-icon icon-copy icon-inline"></i>
                <span>{{ 'aimo.ordertemplates.copy' | cxTranslate }}</span>
            </button>

            <button
                [id]="'to_excel'"
                class="btn-link d-none d-md-inline-block order-3"
                [attr.aria-label]="'aimo.cart.export.excel' | cxTranslate"
                [title]="'aimo.cart.export.excel' | cxTranslate"
                [disabled]="orderTemplate.categoryGroupedEntries?.length <= 0"
                (click)="exportCartToExcel()">
                <i class="aimo-icon icon-file-arrow-down icon-inline"></i>
                <span>{{ 'aimo.cart.export.excel' | cxTranslate }}</span>
            </button>

            <button
                [id]="'print'"
                class="btn-link d-none d-md-inline-block order-4"
                [attr.aria-label]="'aimo.cart.print' | cxTranslate"
                [title]="'aimo.cart.print' | cxTranslate"
                [disabled]="orderTemplate.categoryGroupedEntries?.length <= 0"
                (click)="print()">
                <i class="aimo-icon icon-printer icon-inline"></i>
                <span>{{ 'aimo.cart.print' | cxTranslate }}</span>
            </button>

            <button
                [id]="'printWithTable'"
                class="btn-link d-none d-md-inline-block order-5"
                [attr.aria-label]="'aimo.cart.printTable' | cxTranslate"
                [title]="'aimo.cart.printTable' | cxTranslate"
                [disabled]="orderTemplate.categoryGroupedEntries?.length <= 0"
                (click)="printWithTable()">
                <i class="aimo-icon icon-printer icon-inline"></i>
                <span>{{ 'aimo.cart.printTable' | cxTranslate }}</span>
            </button>

            <button
                *ngIf="orderTemplate.editable"
                [id]="'delete'"
                class="btn-link order-6"
                [attr.aria-label]="'aimo.ordertemplates.delete' | cxTranslate"
                [title]="'aimo.ordertemplates.delete' | cxTranslate"
                (click)="delete(orderTemplate)">
                <i class="aimo-icon icon-trash icon-inline"></i>
                <span>{{ 'aimo.ordertemplates.delete' | cxTranslate }}</span>
            </button>

            <button
                #moreFunctionsOption
                [id]="'more_functions'"
                class="btn-link d-md-none order-7"
                [attr.aria-label]="'aimo.cart.more.functions' | cxTranslate"
                [title]="'aimo.cart.more.functions' | cxTranslate"
                (click)="showMoreFunctions(moreFunctionsOption)">
                <i class="aimo-icon icon-dots-three-vertical icon-inline"></i>
                <span>{{ 'aimo.cart.more.functions' | cxTranslate }}</span>
            </button>

            <button
                [id]="'add_all_to_cart'"
                class="btn btn-sm btn-primary order-8 order-md-last ml-md-3 mt-3 mt-md-0 float-right"
                [attr.aria-label]="'aimo.ordertemplates.addAllToCart' | cxTranslate"
                [title]="'aimo.ordertemplates.addAllToCart' | cxTranslate"
                [disabled]="orderTemplate.categoryGroupedEntries?.length <= 0"
                (click)="addAllToCart(orderTemplate)">
                <i class="aimo-icon icon-shopping-cart-simple icon-inline mr-2 mb-1"></i>
                {{ 'aimo.ordertemplates.addAllToCart' | cxTranslate }}
            </button>
        </div>
    </div>

    <aimo-order-template-item-list
        [printViewWithTable]="printViewWithTable"
        [cart]="cart$ | async"
        [orderTemplate]="orderTemplate"></aimo-order-template-item-list>
</div>

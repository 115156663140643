import { Action } from '@ngrx/store';
import { ProductActions } from '@spartacus/core';

import { AimoSearchConfig } from '../../../model/misc.model';
import { AimoProductSearchPage } from '../../../model/product.model';
import { GTMCartType } from '../../gtm/aimo-gtm.model';

export const AIMO_SEARCH_PRODUCTS = '[AIMO Product] Search Products';

export class AimoSearchProducts implements Action {
    readonly type = AIMO_SEARCH_PRODUCTS;

    constructor(
        public payload: { queryText: string; searchConfig?: AimoSearchConfig },
        public origin?: GTMCartType,
        public auxiliary?: boolean,
    ) {}
}

export const AIMO_QUICK_SEARCH_PRODUCTS = '[AIMO Product] Quick Search Products';

export class AimoQuickSearchProducts implements Action {
    readonly type = AIMO_QUICK_SEARCH_PRODUCTS;

    constructor(
        public payload: { queryText: string; searchConfig?: AimoSearchConfig },
        public origin?: GTMCartType,
        public auxiliary?: boolean,
    ) {}
}

export const AIMO_CLOSE_QUICK_SEARCH = '[AIMO Product] Close Quick Search Products';

export class AimoCloseQuickSearchProducts implements Action {
    readonly type = AIMO_CLOSE_QUICK_SEARCH;

    constructor() {}
}

export class AimoSearchProductsSuccess extends ProductActions.SearchProductsSuccess {
    constructor(
        public payload: AimoProductSearchPage,
        public auxiliary?: boolean,
        public origin?: GTMCartType,
    ) {
        super(payload, auxiliary);
    }
}

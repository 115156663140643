import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { KeyboardFocusService, StorefrontComponent } from '@spartacus/storefront';

import { AimoHamburgerMenuService } from '../hamburger-menu/aimo-hamburger-menu.service';

@Component({
    selector: 'aimo-cx-storefront',
    templateUrl: './aimo-storefront.component.html',
})
export class AimoStorefrontComponent extends StorefrontComponent implements AfterViewChecked, OnInit {
    constructor(
        hamburgerMenuService: AimoHamburgerMenuService,
        routingService: RoutingService,
        protected elementRef: ElementRef<HTMLElement>,
        protected keyboardFocusService: KeyboardFocusService,
        @Inject(DOCUMENT) private document: Document,
        protected router: Router,
    ) {
        super(hamburgerMenuService, routingService, elementRef, keyboardFocusService);
    }

    testEnvironments = ['test-shop', 'test-my'];
    // eslint-disable-next-line
    private tokenExpirationTimer: any;

    ngAfterViewChecked(): void {
        this.addIfTestEnvironment();
    }

    addIfTestEnvironment(): void {
        // if (this.testEnvironments.some(testEnvironment => this.aWinRef.location.hostname.includes(testEnvironment))) {
        //   // There's at least one
        //   const siteLogo = document.getElementById('header-area').querySelector('.header > .SiteLogo');
        //   if (siteLogo && !siteLogo.querySelector('#testParagraph')) {
        //     const paragraph = document.createElement('h4');
        //     paragraph.setAttribute('id', 'testParagraph');
        //     paragraph.innerHTML = 'Test';
        //     siteLogo.append(paragraph);
        //   }
        // }
    }

    // clickout logic added to original NavigationUIComponent
    @HostListener('document:click', ['$event'])
    clickout(event): void {
        const element = event.target;
        const parentElement = (<HTMLElement>event.target)?.closest('cx-page-layout.navigation');
        if (
            element.nodeName.toLowerCase() === 'header' &&
            element.className.includes('is-expanded') &&
            !parentElement
        ) {
            this.collapseMenu();
        }
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(): void {
        this.stickyNavigationObserver();
    }

    stickyNavigationObserver(): void {
        const header = <HTMLElement>this.document.querySelector('.sticky-header');
        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
            { threshold: [1] },
        );
        observer.observe(header);
    }
}

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Actions } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { RoutingService, TranslationService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AimoBaseOrder, AimoBaseOrderList } from '../../model/cart.model';
import { AimoTemplateFacet } from '../../model/product.model';
import { AimoActiveCartService } from '../../service/cart/aimo-active-cart.service';
import { AimoGtmService } from '../../service/gtm/aimo-gtm.service';
import { AimoUserService } from '../../service/user/aimo-user.service';
import { AimoNgbDateParserFormatter } from '../shared/datepicker/aimo-datepicker-parser-formatter';
import { openCloseSpinner } from '../shared/utils/spinner/aimo-spinner-utils';

@Component({
    selector: 'aimo-base-orders',
    templateUrl: './aimo-base-orders.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AimoBaseOrdersComponent implements OnInit, OnDestroy {
    baseOrderList$ = new BehaviorSubject<AimoBaseOrderList>({ carts: [] });
    searchForm: FormGroup;
    subscriptions: Subscription = new Subscription();
    selectedFacet: AimoTemplateFacet = {
        selected: true,
        code: 'all-facets',
    };

    // eslint-disable-next-line
    breadcrumbs$: Observable<any[]>;

    protected baseOrderId$;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected userService: AimoUserService,
        protected activeCartService: AimoActiveCartService,
        protected ngbDateParserFormatter: AimoNgbDateParserFormatter,
        protected activatedRoute: ActivatedRoute,
        protected routingService: RoutingService,
        protected cdr: ChangeDetectorRef,
        private lTranslation: TranslationService,
        protected actions$: Actions,
        protected gtmService: AimoGtmService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    pageAllowed(): Observable<boolean> {
        return this.userService.get().pipe(
            filter((u) => u !== undefined),
            map((u) => u.unit.allowInvoices),
        );
    }

    ngOnInit(): void {
        // eslint-disable-next-line
        this.breadcrumbs$ = this.lTranslation.translate('aimo.baseOrders.title').pipe(
            filter(Boolean),
            map((translation) => [{ label: translation, link: '/baseorders' }]),
        );

        this.baseOrderId$ = this.routingService.getRouterState().pipe(
            map((routingData) => routingData.state.params.orderId),
            distinctUntilChanged(),
        );
        const searchTerm = this.activatedRoute.snapshot.queryParams?.searchTerm;
        this.searchForm = new FormGroup({
            search: new FormControl(searchTerm),
        });
        this.fetchBaseOrders();
        this.subscriptions.add(
            this.launchDialogService.dialogClose.subscribe((next) => {
                if (next && this.activatedRoute.snapshot.url.length == 1) {
                    this.fetchBaseOrders();
                }
            }),
        );
        this.subscriptions.add(
            this.actions$.subscribe((action) => {
                if (action.type === CartActions.DELETE_CART_SUCCESS || action.type === CartActions.LOAD_CART_SUCCESS) {
                    this.fetchBaseOrders();
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openCreateBaseOrderModal(): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CREATE_BASE_ORDER, undefined, {
            createNew: true,
        });
    }

    fetchBaseOrders(): void {
        this.activeCartService.getBaseOrders(this.searchForm.controls['search'].value).forEach((next) => {
            this.baseOrderList$.next(next);
            openCloseSpinner(this.document, false);
        });
        this.cdr.detectChanges();
    }

    getDeliveryDatesAhead(baseorder: AimoBaseOrder): number {
        return baseorder?.deliveryDatesAhead;
    }

    convertDate(d: string): NgbDate {
        return d ? this.ngbDateParserFormatter.parse(new Date(d)) : null;
    }

    pushGtmEvent(event: string): void {
        this.gtmService.pushEvent(event);
    }
}

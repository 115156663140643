import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { EventService, LogoutEvent } from '@spartacus/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AimoProduct } from '../../model/product.model';
import { AimoProductService } from '../product/aimo-product.service';

const STORAGE_KEY = 'productComparison';
const SEPARATOR = ',';

@Injectable({
    providedIn: 'root',
})
export class AimoProductComparisonService implements OnDestroy {
    productSubject: BehaviorSubject<string[]>;
    subscription: Subscription = new Subscription();

    constructor(
        protected productService: AimoProductService,
        @Inject(DOCUMENT) private document: Document,
        protected eventService: EventService,
    ) {
        this.productSubject = new BehaviorSubject(this.getStorageValue());
        this.subscription.add(this.eventService.get(LogoutEvent).subscribe(() => this.clear()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    toggleComparison(productCode: string): void {
        if (this.getStorageValue().filter((c) => c === productCode).length > 0) {
            this.removeProductFromComparison(productCode);
        }
        this.addProductToComparison(productCode);
    }

    addProductToComparison(productCode: string): void {
        const products = this.getStorageValue();
        if (products.filter((c) => c === productCode).length === 0) {
            products.push(productCode);
        }
        this.triggerSubject(products);
    }

    removeProductFromComparison(productCode: string): void {
        const products = this.getStorageValue().filter((c) => c !== productCode);
        this.triggerSubject(products);
    }

    clear(): void {
        this.triggerSubject([]);
    }

    private triggerSubject(value: string[]): void {
        this.setStorageValue(value);
        this.productSubject.next(value);
    }

    private setStorageValue(value: string[]): void {
        this.document.defaultView.sessionStorage?.setItem(STORAGE_KEY, value.join(SEPARATOR));
    }

    private getStorageValue(): string[] {
        const value = this.document.defaultView.sessionStorage?.getItem(STORAGE_KEY);
        if (value) {
            return value.split(SEPARATOR);
        }
        return [];
    }

    getCompareProducts(from: number, size: number): Observable<AimoProduct[]> {
        return this.productSubject.asObservable().pipe(
            switchMap((products) => (products.length === 0 ? of([]) : this.productService.getProducts(products))),
            map((products) => {
                return products.slice(from, from + size);
            }),
        );
    }
}

import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AuthService, Breadcrumb, RoutingService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService, ProductSummaryComponent } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';
import { AimoCategory, AimoClassification, AimoFeature, AimoProduct } from '../../../model/product.model';
import { AimoUser } from '../../../model/user.model';
import { AimoGTMProductAttributes, GTMCartType, GTMItemListId, KlevuParams } from '../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../service/product/aimo-current-product.service';
import {
    AimoProductService,
    CROSSSELING_PRODUCTS,
    SIMILAR_PRODUCTS,
} from '../../../service/product/aimo-product.service';
import { AimoProductComparisonService } from '../../../service/user/aimo-product-comparison.service';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { DateUtils } from '../../../shared/util/date-utils';
import { AimoOrderTemplateDialogData } from '../../order-template/order-template-modal/aimo-order-template-layout.config';

import { AimoProductSummaryDialogData } from './product-summary-layout.config';

@Component({
    selector: 'aimo-product-summary',
    templateUrl: './aimo-product-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductSummaryComponent extends ProductSummaryComponent implements OnDestroy, OnInit {
    CROSSSELING_PRODUCTS = CROSSSELING_PRODUCTS;
    SIMILAR_PRODUCTS = SIMILAR_PRODUCTS;

    private subscription = new Subscription();
    modal: boolean = false;
    addToCartCallBack: BehaviorSubject<number>;
    openModalAfterClose: LAUNCH_CALLER;
    focusIdAfterClose: string;
    user$: Observable<AimoUser>;

    nutritionFactNames = [
        'FAT',
        'FASAT',
        'FAPU',
        'FAPUCIS',
        'CHOAVL',
        'SUGAR-',
        'LACS',
        'PRO-',
        'SALTEQ',
        'FIBTG',
        'POLYL',
        'STARCH',
    ];

    vitaminNames = [
        'VITA-',
        'VITC',
        'VITB9',
        'VITB12',
        'VITB2',
        'VITD',
        'VITE',
        'VITB1',
        'VITB3',
        'VITB6-',
        'VITB5',
        'VITH',
        'VITK',
    ];

    mineralNames = ['NA', 'IODIZED_SALT', 'CA', 'K', 'MG', 'P', 'ZN', 'FE', 'CLD', 'FD', 'MN', 'SE', 'CU'];

    crProducts: Observable<AimoProduct>[];
    similarProducts: Observable<AimoProduct>[];
    breadCrumbs: Breadcrumb[];

    @ViewChild('productLink')
    productLink: ElementRef;

    @ViewChild('modalContent')
    modalContentRef: ElementRef;

    klevuParams: KlevuParams;

    loggedUser$: Observable<boolean>;

    crossSelling = GTMItemListId.cross_selling;
    alternative = GTMItemListId.alternative;
    productSummary = GTMItemListId.product_summary;

    constructor(
        protected aimoUserService: AimoUserService,
        protected currentProductService: AimoCurrentProductService,
        protected productService: AimoProductService,
        @Inject(DOCUMENT) private document: Document,
        protected launchDialogService: LaunchDialogService,
        protected el: ElementRef,
        protected routingService: RoutingService,
        protected productComparisonService: AimoProductComparisonService,
        protected aimoRoutingService: AimoRoutingService,
        protected auth: AuthService,
    ) {
        super(currentProductService);
        this.user$ = this.aimoUserService.get();
        this.loggedUser$ = this.auth.isUserLoggedIn();
    }

    ngOnInit(): void {
        this.launchDialogService.data$
            .subscribe((dialogData: AimoProductSummaryDialogData) => {
                if (dialogData?.product) {
                    this.modal = true;
                    this.product$ = dialogData.product.pipe(map((p) => ({ ...p })));
                    this.addToCartCallBack = dialogData.addToCartCallBack;
                    this.focusIdAfterClose = dialogData.focusIdAfterClose;
                    this.openModalAfterClose = dialogData.openModalAfterClose;
                    this.klevuParams = dialogData.klevuParams;
                    if (dialogData.scrollToId) {
                        setTimeout(() => this.scrollTo(dialogData.scrollToId), 1500); // 500 or 1000 is too fast sometimes for similar products container
                    }
                }
            })
            .unsubscribe();
        this.product$ = this.product$.pipe(map((p) => ({ ...p })));
        setTimeout(() => this.getTheFocusBack(), 100);
    }

    onProductChange(quantity: number): void {
        this.addToCartCallBack?.next(quantity);
    }

    ngOnDestroy(): void {
        this.closeDialog();
        this.subscription.unsubscribe();
    }

    getAimoProduct(): Observable<AimoProduct> {
        return this.product$ as Observable<AimoProduct>;
    }

    toggleFavorite(product: AimoProduct): void {
        this.aimoUserService.toggleFavorite(product);
        product.favorite = !product.favorite;
    }

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    getClassificationClass(product: AimoProduct, code: string): AimoClassification {
        return this.productService.getClassificationClass(product, code);
    }

    getNutritionalContent(product: AimoProduct, code: string): AimoFeature {
        return this.productService.getNutritionalContent(product, code);
    }

    getClassificationValueList(product: AimoProduct, code: string): string {
        return this.productService.getClassificationValueList(product, code);
    }

    getClassificationNameList(product: AimoProduct, code: string): string {
        return this.productService.getClassificationNameList(product, code);
    }

    getClassification(product: AimoProduct, code: string): AimoFeature[] {
        return this.productService.getClassification(product, code);
    }

    getClassificationName(product: AimoProduct, code: string): string {
        return this.productService.getClassificationName(product, code);
    }

    getClassificationValueWithUnit(product: AimoProduct, code: string, trimZeros?: boolean): string {
        const f = this.productService.getClassificationValue(product, code);
        if (f) {
            let value = f.value;
            if (trimZeros) {
                value = f.value.replace(/\..*/, '');
            }
            return value.replace('.', ',') + ' ' + f.unit?.name;
        }
        return '';
    }

    print(): void {
        this.document.defaultView.window.print();
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.PRODUCT_SUMMARY);
        this.launchDialogService.closeDialog('closed');
        if (this.openModalAfterClose) {
            this.launchDialogService.openDialogAndSubscribe(this.openModalAfterClose, undefined);
        }
        if (this.focusIdAfterClose && this.document.getElementById(this.focusIdAfterClose)) {
            this.document.getElementById(this.focusIdAfterClose).focus();
        }
    }

    getFullUrl(): string {
        return this.productLink
            ? (this.productLink.nativeElement as HTMLAnchorElement)?.href
            : this.document.location.href;
    }

    @HostListener('click', ['$event'])
    clickout(event: UIEvent): void {
        // eslint-disable-next-line
        if ((event.target as any).tagName === 'A' || (event.target as any).tagName === this.el.nativeElement.tagName) {
            // close when links are clicked
            this.closeDialog();
        }
    }

    getSimilarProducts(product: AimoProduct): Observable<AimoProduct>[] {
        if (!this.similarProducts) {
            this.similarProducts = this.productService.getSimilarProducts(product)?.map((p) => of(p));
        }
        return this.similarProducts;
    }

    getCrossSellingProducts(product: AimoProduct): Observable<AimoProduct>[] {
        if (!this.crProducts) {
            this.crProducts = this.productService.getCrossSellingProducts(product)?.map((p) => of(p));
        }
        return this.crProducts;
    }

    getBreadcrumbs(product: AimoProduct): Breadcrumb[] {
        const breadcrumbs = [];
        breadcrumbs.push({ facetValueName: product.name, facetValueCode: product.code });
        product.categories
            ?.filter((cat) => !(cat as AimoCategory).dummyCategory)
            .forEach((cat) => {
                breadcrumbs.push({
                    facetValueName: cat.name || cat.code,
                    facetValueCode: cat.code,
                } as Breadcrumb);
            });
        return breadcrumbs.reverse();
    }

    truncate(text: string): string {
        if (text.length > 400) {
            return text.substring(0, 400) + '\u2026';
        }
        return null;
    }

    showDescription = false;

    toggleDescription(): void {
        this.showDescription = !this.showDescription;
    }

    addToComparison(product: AimoProduct): void {
        this.productComparisonService.addProductToComparison(product.code);
    }

    scrollTo(id: string): void {
        this.document.querySelector('#' + id)?.scrollIntoView();
    }

    createGtmAttributes(idx: number, listId: GTMItemListId): AimoGTMProductAttributes {
        return {
            index: idx,
            item_list_id: listId,
            cart_type: GTMCartType.cart,
            klevuParams: this.klevuParams,
        } as AimoGTMProductAttributes;
    }

    addToOrderTemplate(product: AimoProduct, sourceId: string): void {
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, {
            addToTemplateProduct: product,
            createNew: false,
            openProductModalAfterClose: this.modal,
            focusIdAfterClose: sourceId,
        } as AimoOrderTemplateDialogData);
    }

    @ViewChild('closeButton')
    closeButton: ElementRef;

    getTheFocusBack(event?: FocusEvent): void {
        event?.preventDefault();
        if (this.closeButton) {
            this.closeButton.nativeElement.focus();
        } else {
            setTimeout(() => this.getTheFocusBack(event), 100);
        }
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    safetyDataExport(product: AimoProduct): void {
        this.productService.safetyDataExport(product.code, product.name);
    }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventService, LoginEvent } from '@spartacus/core';

const MESSAGE_KEY = 'hiddenMessages';

@Injectable({ providedIn: 'root' })
export class AimoMessageService {
    constructor(
        @Inject(DOCUMENT) protected document: Document,
        protected eventService: EventService,
    ) {
        this.eventService
            .get(LoginEvent)
            .subscribe(() => this.document.defaultView.sessionStorage?.removeItem(MESSAGE_KEY));
    }

    hideMessage(id: string): void {
        if (!this.isMessageHidden(id)) {
            let value = this.document.defaultView.sessionStorage?.getItem(MESSAGE_KEY);
            if (value === undefined) {
                value = id;
            } else {
                value += ',' + id;
            }
            this.document.defaultView.sessionStorage?.setItem(MESSAGE_KEY, value);
        }
    }

    isMessageHidden(id: string): boolean {
        return this.getMessages()?.find((m) => m === id) !== undefined;
    }

    private getMessages(): string[] {
        return this.document.defaultView.sessionStorage?.getItem(MESSAGE_KEY)?.split(',');
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyService, LanguageService, RoutingService } from '@spartacus/core';
import { ProductListComponentService, ProductListRouteParams, SearchCriteria, ViewConfig } from '@spartacus/storefront';

import { AimoSearchCriteria } from '../../model/misc.model';
import { GTMItemListId } from '../gtm/aimo-gtm.model';

import { AimoProductSearchService } from './search/aimo-product-search.service';

@Injectable({
    providedIn: 'root',
})
export class AimoProductListComponentService extends ProductListComponentService {
    constructor(
        protected productSearchService: AimoProductSearchService,
        protected routing: RoutingService,
        protected activatedRoute: ActivatedRoute,
        protected currencyService: CurrencyService,
        protected languageService: LanguageService,
        protected router: Router,
        protected http: HttpClient,
        protected viewConfig: ViewConfig,
    ) {
        super(productSearchService, routing, activatedRoute, currencyService, languageService, router, viewConfig);
    }

    protected search(criteria: AimoSearchCriteria): void {
        const currentPage = criteria.currentPage;
        const pageSize = criteria.pageSize;
        const sort = criteria.sortCode;
        const followRedirects = criteria.followRedirects === 'true';

        this.productSearchService.search(
            criteria.query,
            Object.assign(
                {},
                currentPage && { currentPage },
                pageSize && { pageSize },
                sort && { sort },
                followRedirects && { followRedirects },
            ),
        );
    }

    protected getCriteriaFromRoute(
        routeParams: ProductListRouteParams,
        queryParams: AimoSearchCriteria,
        pageSize?: number,
    ): AimoSearchCriteria {
        return {
            query: this.getAimoQueryFromRouteParams(routeParams, queryParams),
            pageSize: queryParams.pageSize || (pageSize > 0 ? pageSize : this.viewConfig.view.defaultPageSize),
            currentPage: queryParams.currentPage,
            sortCode: queryParams.sortCode,
            followRedirects: queryParams.followRedirects,
        };
    }

    getPageItemsForPageSize(pageSize: number, pageNumber: number): void {
        this.routing
            .getRouterState()
            .subscribe((route) => {
                const routeCriteria = this.getCriteriaFromRoute(
                    <ProductListRouteParams>route.state.params,
                    <SearchCriteria>route.state.queryParams,
                    pageSize,
                );
                const criteria = {
                    ...routeCriteria,
                    currentPage: pageNumber,
                };
                this.search(criteria);
            })
            .unsubscribe();
    }

    protected getAimoQueryFromRouteParams(
        { query, categoryCode, brandCode }: ProductListRouteParams,
        queryParams: AimoSearchCriteria,
    ): string | undefined {
        let prefix = queryParams.query || query || ':relevance';
        prefix += ':';
        if (this.getDummyCategoryParam(categoryCode) !== undefined) {
            return prefix + this.getDummyCategoryParam(categoryCode);
        }
        if (categoryCode) {
            prefix += 'allCategories:' + categoryCode;
        }
        if (!query) {
            return prefix;
        }
        return super.getQueryFromRouteParams({ query: prefix, categoryCode, brandCode } as ProductListRouteParams);
    }

    public getDummyCategoryParam(categoryCode: string): string {
        if (
            categoryCode === 'favorites' ||
            categoryCode === 'novelty' ||
            categoryCode === 'mostPurchased' ||
            categoryCode === 'campaign' ||
            categoryCode === 'clickAndCollect'
        ) {
            return categoryCode + ':true';
        }
        return undefined;
    }

    public static hasAnyCategoryParam(query: string): boolean {
        return (
            query?.indexOf('favorites') >= 0 ||
            query?.indexOf('novelty') >= 0 ||
            query?.indexOf('mostPurchased') >= 0 ||
            query?.indexOf('campaign') >= 0 ||
            query?.indexOf('allCategories') >= 0 ||
            query?.indexOf('clickAndCollect') >= 0
        );
    }

    public static getGTMCategoryParamName(query: string): GTMItemListId {
        if (this.getCategoryParam(query, 'favorites')) {
            return GTMItemListId.favorites;
        }
        if (this.getCategoryParam(query, 'novelty')) {
            return GTMItemListId.novelty;
        }
        if (this.getCategoryParam(query, 'mostPurchased')) {
            return GTMItemListId.mostPurchased;
        }
        if (this.getCategoryParam(query, 'campaign')) {
            return GTMItemListId.campaign;
        }
        if (this.getCategoryParam(query, 'allCategories')) {
            return GTMItemListId.product_category_page;
        }
        if (this.getCategoryParam(query, 'clickAndCollect')) {
            return GTMItemListId.clickAndCollect;
        }
        return null;
    }

    private static getCategoryParam(query: string, name: string): string {
        if (query.indexOf(name) >= 0) {
            const start = query.indexOf(name) + 1;
            return query.substring(start, query.indexOf(':', start) > 0 ? query.indexOf(':', start) : query.length);
        }
        return null;
    }
}

import { NgModule } from '@angular/core';
import {
    AnonymousConsentsModule,
    AuthModule,
    ExternalRoutesModule,
    ProductModule,
    ProductOccModule,
    SiteContextModule,
    UserModule,
    UserOccModule,
} from '@spartacus/core';
import { OrderCoreModule } from '@spartacus/order/core';
import { OrderOccModule } from '@spartacus/order/occ';
import {
    AnonymousConsentManagementBannerModule,
    AnonymousConsentsDialogModule,
    AppRoutingModule,
    BannerCarouselModule,
    BannerModule,
    BreadcrumbModule,
    CategoryNavigationModule,
    ConsentManagementModule,
    FooterNavigationModule,
    HamburgerMenuModule,
    HomePageEventModule,
    LinkModule,
    LoginRouteModule,
    LogoutModule,
    MyCouponsModule,
    MyInterestsModule,
    NavigationEventModule,
    NavigationModule,
    NotificationPreferenceModule,
    PageTitleModule,
    PaymentMethodsModule,
    ProductCarouselModule,
    ProductDetailsPageModule,
    ProductImagesModule,
    ProductIntroModule,
    ProductListingPageModule,
    ProductPageEventModule,
    ProductReferencesModule,
    ProductTabsModule,
    ScrollToTopModule,
    SearchBoxModule,
    StockNotificationModule,
    TabParagraphContainerModule,
} from '@spartacus/storefront';
import { UserProfileCoreModule } from '@spartacus/user/profile/core';

import { AimoRoutingModule } from '../features/cms-structure/routing/aimo-routing.module';

@NgModule({
    declarations: [],
    imports: [
        AuthModule.forRoot(),
        LogoutModule,
        LoginRouteModule,
        HamburgerMenuModule,
        LinkModule,
        BannerModule,
        TabParagraphContainerModule,
        BannerCarouselModule,
        CategoryNavigationModule,
        NavigationModule,
        FooterNavigationModule,
        BreadcrumbModule,
        ScrollToTopModule,
        PageTitleModule,
        UserModule,
        UserOccModule,
        PaymentMethodsModule,
        NotificationPreferenceModule,
        MyInterestsModule,
        StockNotificationModule,
        ConsentManagementModule,
        MyCouponsModule,
        AnonymousConsentsModule.forRoot(),
        AnonymousConsentsDialogModule,
        AnonymousConsentManagementBannerModule,
        ProductModule.forRoot(),
        ProductOccModule,
        ProductDetailsPageModule,
        ProductListingPageModule,
        SearchBoxModule,
        ProductTabsModule,
        ProductCarouselModule,
        ProductReferencesModule,
        ProductImagesModule,
        ProductIntroModule,
        NavigationEventModule,
        HomePageEventModule,
        ProductPageEventModule,
        ExternalRoutesModule.forRoot(),
        AppRoutingModule,
        AimoRoutingModule,
        SiteContextModule.forRoot(),
        AppRoutingModule,
        UserProfileCoreModule,
        OrderCoreModule,
        OrderOccModule,
    ],
})
export class SpartacusFeaturesModule {}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import { ImageGroup, WindowRef } from '@spartacus/core';
import { CurrentProductService, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AimoProduct } from '../../../../model/product.model';
import { AimoGTMProductAttributes, GTMItemListId } from '../../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../../service/product/aimo-current-product.service';
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'aimo-product-images',
    templateUrl: './aimo-product-image-zoom-product-images.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductImageZoomProductImagesComponent implements OnInit {
    protected mainMediaContainer = new BehaviorSubject<ImageGroup | ImageGroup[]>(null);
    expandImage = new BehaviorSubject(false);
    selectedIndex: number | undefined;

    @Input()
    protected product: AimoProduct;

    @Input()
    modal: boolean = false;

    thumbs$: Observable<ImageGroup>[];
    mainImage$: Observable<ImageGroup | ImageGroup[]>;

    isMobile = false;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected currentProductService: CurrentProductService,
        @Inject(DOCUMENT) protected document: Document,
        protected cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.thumbs$ = this.createThumbs(this.product);
        this.mainMediaContainer.next(this.product.images?.PRIMARY ? this.product.images.PRIMARY : {});
        this.mainImage$ = this.mainMediaContainer.asObservable();
        this.isMobile = this.document.defaultView.window.matchMedia('only screen and (max-width: 760px)').matches;
    }

    isActive(thumbnail: ImageGroup): Observable<boolean> {
        return this.mainMediaContainer.pipe(
            filter(Boolean),
            map((container: ImageGroup) => {
                return (
                    container.zoom &&
                    container.zoom.url &&
                    thumbnail.zoom &&
                    thumbnail.zoom.url &&
                    container.zoom.url === thumbnail.zoom.url
                );
            }),
        );
    }

    /** find the index of the main media in the list of media */
    // eslint-disable-next-line
    getActive(thumbs: any[]): Observable<number> {
        return this.mainMediaContainer.pipe(
            filter(Boolean),
            map((container: ImageGroup) => {
                const current = thumbs.find(
                    (t) =>
                        t.media &&
                        container.zoom &&
                        t.media.container &&
                        t.media.container.zoom &&
                        t.media.container.zoom.url === container.zoom.url,
                );
                return thumbs.indexOf(current);
            }),
        );
    }

    createGtmAttributes(): AimoGTMProductAttributes {
        return { item_list_id: GTMItemListId.product_summary } as AimoGTMProductAttributes;
    }

    openImage(item: ImageGroup): void {
        this.mainMediaContainer.next(item);
        this.selectedIndex = item.zoom?.galleryIndex;
    }

    /**
     * Opens image zoom dialog.
     */
    triggerZoom(value: boolean, main: ImageGroup | ImageGroup[], product?: AimoProduct): void {
        if (Object.keys(main).length > 0) {
            this.expandImage.next(value);
            if (!value) {
                this.launchDialogService.clear(LAUNCH_CALLER.PRODUCT_IMAGE_ZOOM);
            }
        }
        if (product && this.modal) {
            (this.currentProductService as AimoCurrentProductService).openProductModal(
                product.code,
                this.createGtmAttributes(),
            );
        }
    }

    /**
     * Return an array of CarouselItems for the product thumbnails.
     * In case there are less then 2 thumbs, we return null.
     */
    private createThumbs(product: AimoProduct): Observable<ImageGroup>[] {
        if (
            !product.images ||
            !product.images.GALLERY ||
            (Array.isArray(product.images.GALLERY) && product.images.GALLERY.length < 2)
        ) {
            return [];
        }

        return (<ImageGroup[]>product.images.GALLERY).map((c) => of({ container: c }));
    }
}

import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { BaseSiteService, Breadcrumb, isNotNullable, PageMetaService, TranslationService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'aimo-breadcrumb',
    templateUrl: './aimo-breadcrumb.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBreadcrumbComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() isProductBreadcrumb: boolean;
    @Input() showBackLink: boolean = true;
    @Input() productTitle: string;
    @Input() contentPageTitle: string;
    @Input() breadCrumbs: Breadcrumb[]; // category breadcrumb
    // eslint-disable-next-line
    @Input() normalBreadCrumbs: any[] = [];
    @Input() hideTitle: boolean = false;
    // eslint-disable-next-line
    crumbs$: Observable<any[]>; // normal breadcrumb

    title$: Observable<string>;
    lastestTitle$: Observable<string>;

    secondLevelCategory: string;

    @ViewChild('breadcrumb') private breadcrumb: ElementRef;

    constructor(
        protected pageMetaService: PageMetaService,
        private lTranslation: TranslationService,
        protected baseSiteService: BaseSiteService,
        protected location: Location,
    ) {}

    ngOnInit(): void {
        this.setTitle();
    }

    ngAfterViewInit(): void {
        this.lastestTitle$ = this.title$;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setCrumbs(!this.isProductBreadcrumb);
        if (this.isProductBreadcrumb) {
            this.showBackLink = false;
        }
    }

    /* Commented for now if some day we need this back
    ngAfterViewChecked(): void {
        this.scrollToLastElement();
    }
    */

    setTitle(): void {
        this.title$ = this.pageMetaService.getMeta().pipe(
            filter(isNotNullable),
            map((meta) => (meta.heading || meta.title) ?? ''),
        );
    }

    setCrumbs(addFrontPage: boolean): void {
        // eslint-disable-next-line
        const initialCrumbs$: Observable<any[]> = combineLatest([
            this.pageMetaService.getMeta(),
            this.lTranslation.translate('common.home').pipe(filter(() => addFrontPage)),
        ]).pipe(map(([meta, textHome]) => (meta?.breadcrumbs ? meta.breadcrumbs : [{ label: textHome, link: '/' }])));

        if (this.normalBreadCrumbs) {
            this.crumbs$ = initialCrumbs$.pipe(map((crumbs) => [...crumbs, ...this.normalBreadCrumbs]));
        } else {
            this.crumbs$ = initialCrumbs$;
        }
    }

    setSecondLevelCategory(code: string): void {
        this.secondLevelCategory = code;
    }

    back(): void {
        this.location.back();
    }

    scrollToLastElement(): void {
        try {
            this.breadcrumb.nativeElement.scrollLeft = this.breadcrumb.nativeElement.scrollWidth;
        } catch (err) {}
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EventService, ProductActions, UserIdService } from '@spartacus/core';
import { OrderPlacedEvent } from '@spartacus/order/root';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AimoOrder } from '../../model/cart.model';
import { AimoProductSearchPage } from '../../model/product.model';
import { AimoActiveCartService } from '../cart/aimo-active-cart.service';
import { AimoProductListComponentService } from '../product/aimo-product-list-component.service';
import { AimoProductService } from '../product/aimo-product.service';
import { AimoSearchProductsSuccess } from '../product/search/aimo-product-search.action';

import {
    AIMO_GTM_CALENDAR,
    AIMO_GTM_PRODUCT,
    AIMO_GTM_PRODUCTS,
    AIMO_PLACE_ORDER_CLICKED,
    AIMO_VIEW_GTM_PRODUCTS,
    AimoGtmCalendar,
    AimoGtmProduct,
    AimoGtmProducts,
    AimoPlaceOrderClicked,
    AimoViewGtmProducts,
} from './aimo-gtm.action';
import { AimoGTMProduct, GTMEventCode } from './aimo-gtm.model';
import { AimoGtmService } from './aimo-gtm.service';

@Injectable({ providedIn: 'root' })
export class AimoGtmEffects implements OnDestroy {
    private subscriptions = new Subscription();

    placeOrderClicked$: Observable<AimoPlaceOrderClicked>;

    addGTMCalendar$: Observable<AimoGtmCalendar>;

    addGTMProducts$: Observable<AimoGtmProducts>;

    openGTMProduct$: Observable<AimoGtmProducts>;

    productList$: Observable<AimoViewGtmProducts>;

    productViewList$: Observable<AimoGtmProducts>;

    constructor(
        private actions$: Actions,
        protected gtmService: AimoGtmService,
        protected activeCartService: AimoActiveCartService,
        protected productService: AimoProductService,
        protected userIdService: UserIdService,
        protected eventService: EventService,
    ) {
        this.placeOrderClicked$ = createEffect(
            () =>
                this.actions$.pipe(
                    ofType(AIMO_PLACE_ORDER_CLICKED),
                    tap((action: AimoPlaceOrderClicked) =>
                        this.gtmService.pushOrderClicked(action.cart, GTMEventCode.BeginCheckout),
                    ),
                ),
            { dispatch: false },
        );

        this.addGTMCalendar$ = createEffect(
            () =>
                this.actions$.pipe(
                    ofType(AIMO_GTM_CALENDAR),
                    tap((action) => this.gtmService.pushCalendar(action as AimoGtmCalendar)),
                ),
            { dispatch: false },
        );

        this.addGTMProducts$ = createEffect(
            () =>
                this.actions$.pipe(
                    ofType(AIMO_GTM_PRODUCTS),
                    tap((action) => this.gtmService.pushProducts(action as AimoGtmProducts)),
                ),
            { dispatch: false },
        );

        this.openGTMProduct$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_GTM_PRODUCT),
                map((action) => action as AimoGtmProduct),
                map(
                    (action) =>
                        new AimoGtmProducts(
                            [
                                {
                                    ...action.product,
                                    quantity: 1,
                                    discount: action.product.discount,
                                    gtmProductAttributes: action.gtmProductAttributes,
                                } as AimoGTMProduct,
                            ],
                            action.event,
                            action.product.price?.value ?? 0,
                            undefined,
                            undefined,
                            action.linkedProducts?.map((l) => ({ ...l }) as AimoGTMProduct),
                        ),
                ),
            ),
        );

        this.productList$ = createEffect(() =>
            this.actions$.pipe(
                ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS),
                map((action) => action as AimoSearchProductsSuccess),
                map((action) => {
                    return new AimoViewGtmProducts(
                        action.payload.products,
                        (action.payload as AimoProductSearchPage).suggestiveMode
                            ? GTMEventCode.ViewSearchSuggestions
                            : AimoProductListComponentService.hasAnyCategoryParam(
                                    action.payload.currentQuery?.query?.value,
                                )
                              ? GTMEventCode.ViewItemList
                              : GTMEventCode.ViewFullSearchResults,
                        AimoProductListComponentService.getGTMCategoryParamName(
                            action.payload.currentQuery?.query?.value,
                        ),
                        action.payload.freeTextSearch,
                        action.payload.pagination,
                        action.origin,
                    );
                }),
            ),
        );

        this.productViewList$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_VIEW_GTM_PRODUCTS),
                map((action) => action as AimoViewGtmProducts),
                map((action) => {
                    let index = 0;
                    return new AimoGtmProducts(
                        action.products.map(
                            (product) =>
                                ({
                                    ...product,
                                    quantity: 1,
                                    gtmProductAttributes: {
                                        index: index++,
                                        item_list_id: action.itemListId,
                                    },
                                }) as AimoGTMProduct,
                        ),
                        action.event,
                        null,
                        action.searchTerm,
                        action.pagination?.totalResults,
                        null,
                        action.search_origin,
                    );
                }),
            ),
        );
        this.subscriptions.add(
            this.eventService.get(OrderPlacedEvent).subscribe((event) => {
                // eslint-disable-next-line
                this.gtmService.pushOrderPlaced(event.order as AimoOrder);
                if ((event.order as AimoOrder).paymentMode.credit) {
                    this.gtmService.pushOrderClicked(event.order as unknown, GTMEventCode.AddPaymentInfo);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

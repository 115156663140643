<ng-container *ngIf="orderTemplate.categoryGroupedEntries?.length > 0">
    <div class="sub-header row bg-petrol-light no-gutters p-2 sub-header-line align-items-center d-none d-lg-flex">
        <div class="col-12" [ngClass]="baseOrder ? 'col-lg-5' : 'col-lg-4'">
            <span class="text-bold">{{ 'aimo.cart.item.product' | cxTranslate }}</span>
        </div>
        <div class="col-3">
            <div class="row no-gutters">
                <div class="col-6">
                    <span class="text-bold">{{ 'aimo.cart.item.basePrice' | cxTranslate }}</span>
                    <br />
                    {{ 'aimo.cart.item.notax' | cxTranslate }}
                </div>
                <div class="col-6">
                    <span class="text-bold">{{ 'aimo.cart.item.comparisonPrice' | cxTranslate }}</span>
                    <br />
                    {{ 'aimo.cart.item.notax' | cxTranslate }}
                </div>
            </div>
        </div>

        <div class="col-3" *ngIf="!baseOrder">
            <div class="row no-gutters">
                <div class="col-6">
                    <span class="text-bold">{{ 'aimo.cart.item.availability' | cxTranslate }}</span>
                </div>
                <div class="col-6">
                    <span class="text-bold">{{ 'aimo.product.closingTime' | cxTranslate }}</span>
                </div>
            </div>
        </div>

        <div class="col-2">
            <span class="text-bold" [ngClass]="{ 'pl-4': !baseOrder }">{{
                'aimo.cart.item.quantity' | cxTranslate
            }}</span>
        </div>

        <div class="col-2" *ngIf="baseOrder">
            <span class="text-bold">{{ 'aimo.cart.item.total' | cxTranslate }}</span>
        </div>
    </div>

    <div class="d-none" [ngClass]="printViewWithTable ? 'print' : ''">
        <div class="sub-header py-2 align-items-end row d-flex">
            <div class="col-6"></div>
            <div class="col-1"><div class="date-slot-for-print"></div></div>
            <div class="col-1"><div class="date-slot-for-print"></div></div>
            <div class="col-1"><div class="date-slot-for-print"></div></div>
            <div class="col-1"><div class="date-slot-for-print"></div></div>
            <div class="col-1"><div class="date-slot-for-print"></div></div>
            <div class="col-1"><div class="date-slot-for-print"></div></div>
        </div>
    </div>

    <div class="row no-gutters">
        <ng-container *ngFor="let categoryGroupedEntry of orderTemplate.categoryGroupedEntries; index as idx">
            <div
                class="col-12 sub-header sub-header-line bg-light-grey p-2 text-bold"
                [ngClass]="printViewWithTable ? 'noPrint' : ''"
                *ngIf="categoryGroupedEntry.title">
                {{ categoryGroupedEntry.title }}
            </div>
            <ng-container *ngFor="let entry of categoryGroupedEntry.entries; index as idx">
                <aimo-order-template-item
                    [printViewWithTable]="printViewWithTable"
                    [alreadyAddedToCart]="getAlreadyAddedToCart(cart, entry)"
                    [baseOrder]="baseOrder"
                    class="col-12"
                    [entry]="entry"
                    [cart]="orderTemplate"
                    [idSuffix]="idx"
                    [gtmProductAttributes]="createGtmAttributes(idx)">
                </aimo-order-template-item>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
<div *ngIf="orderTemplate.categoryGroupedEntries?.length <= 0" class="pt-4 pb-4">
    <h3 class="mb-0 text-default">
        {{ (baseOrder ? 'aimo.baseorders.noItems' : 'aimo.ordertemplate.noOrders') | cxTranslate }}
    </h3>
</div>

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AimoRoutingService } from '../../cms-structure/routing/aimo-routing.service';
import { AimoCart, AimoOrder, AimoOrderEntry } from '../../model/cart.model';
import { AimoFacet, AimoFacetValue, AimoProductSearchPage } from '../../model/product.model';
import { AimoUser } from '../../model/user.model';
import { AimoActiveCartService } from '../cart/aimo-active-cart.service';
import { AimoCategoryService } from '../category/aimo-category.service';
import { AimoEnvironmentService } from '../environment/aimo-environment.service';
import { AimoUserService } from '../user/aimo-user.service';

declare var ScarabQueue: any;

@Injectable({
    providedIn: 'root',
})
export class AimoWebextendService implements OnDestroy {
    private subscriptions: Subscription = new Subscription();
    private cart: AimoCart;
    private debug: boolean = false;
    private goTimeout = undefined;
    private merchantId: String;
    private enabled: boolean;
    private currentSearchTerm: String;
    private currentCategories: CategoryItem[] = [];
    private currentCategoryPath: string;
    private topLevelCategory: string;

    constructor(
        protected aimoRoutingService: AimoRoutingService,
        protected aimoEnvironmentService: AimoEnvironmentService,
        protected cartService: AimoActiveCartService,
        protected userService: AimoUserService,
        protected categoryService: AimoCategoryService,
        @Inject(DOCUMENT) protected document: Document,
    ) {}

    initWebextend() {
        this.subscriptions.add(
            this.aimoEnvironmentService.analyticsConfig().subscribe((value) => {
                if (value != null && value?.webextendMerchantId != this.merchantId) {
                    this.merchantId = value.webextendMerchantId;
                    this.enabled = value.webextendEnabled;
                }
                this.createStartTags(document);
            }),
        );
        this.subscriptions.add(
            this.cartService.getActive().subscribe((cart) => {
                this.cartEvent(cart);
            }),
        );
        this.userService.get().subscribe((user) => {
            if (user) {
                this.setCustomerIdEvent(user);
            }
        });
    }

    setCustomerIdEvent(user: AimoUser) {
        if (user) {
            this.pushToQueue(['setCustomerId', user.cdcUid]);
            this.goEvent();
        }
    }

    createImpressionEventFromSearchPage(searchPage: AimoProductSearchPage) {
        if (!searchPage) {
            return;
        }
        if (searchPage.breadcrumbs?.length > 0 && !searchPage.freeTextSearch) {
            const categoryCodes = searchPage.breadcrumbs
                .filter((b) => b.facetValueCode.length > 0)
                .map((b) => b.facetValueCode);
            if (
                !this.arraysAreEqual(
                    categoryCodes,
                    this.currentCategories.map((c) => c.code),
                )
            ) {
                categoryCodes.forEach((c) => {
                    this.categoryService.getCategoryById(c).subscribe((val) => {
                        this.currentCategories.push({ code: c, name: val.webextendName });
                    });
                });
                const selectedCategories: AimoFacetValue[] = searchPage?.facets
                    ?.filter((f: AimoFacet) => f.code == 'category')[0]
                    .values?.filter((c) => c.selected);
                if (selectedCategories?.length > 0) {
                    this.topLevelCategory = selectedCategories[0].name;
                }
            }
            this.goEvent();
        } else {
            this.currentCategories = [];
        }
        if (searchPage.freeTextSearch && this.currentSearchTerm != searchPage.freeTextSearch) {
            this.currentSearchTerm = searchPage.freeTextSearch;
            const selectedCategories: AimoFacetValue[] = searchPage?.facets
                ?.filter((f: AimoFacet) => f.code == 'category')[0]
                .values?.filter((c) => c.selected);
            if (selectedCategories?.length > 0) {
                selectedCategories.forEach((sc) => {
                    if (!this.currentCategories.map((c) => c.code).includes(sc.code)) {
                        this.categoryService.getCategoryPathById(sc.code).subscribe((path) => {
                            if (path) {
                                this.currentCategoryPath = path;
                            }
                        });
                    }
                });
            }
            this.goEvent();
        } else {
            this.currentCategoryPath = undefined;
            this.currentSearchTerm = undefined;
        }
    }

    viewEvent(productCode: String) {
        this.pushToQueue(['view', productCode]);
        this.goEvent();
    }

    purchaseEvent(aimoOrder: AimoOrder) {
        this.pushToQueue([
            'purchase',
            {
                orderId: aimoOrder.cdpOrderCode,
                items: aimoOrder.entries.map((orderEntry) => this.createOrderEntry(orderEntry)),
            },
        ]);
        this.goEvent();
    }

    cartEvent(cart: AimoCart) {
        this.cart = cart;
        this.goEvent();
    }

    tagEvent() {
        const tagContent: String = this.aimoRoutingService.isPikatukku() ? 'pikatukku' : 'toimitustukku';
        this.pushToQueue(['tag', tagContent]);
    }

    goEvent() {
        clearTimeout(this.goTimeout);
        this.goTimeout = setTimeout(() => {
            this.pushCollectedData();
            this.pushToQueue(['go']);
            this.clearCollectedData();
        }, 2000);
    }

    private pushToQueue(payload: any[]) {
        if (this.debug) {
            console.log('Webextend command:');
            console.log(payload);
        }
        if (this.enabled) {
            ScarabQueue.push(payload);
        }
    }

    private createCartEntry(entry: AimoOrderEntry) {
        return {
            item: entry.product.code,
            price: entry.totalPrice.value,
            quantity: entry.quantity,
        };
    }

    private createOrderEntry(orderEntry: AimoOrderEntry) {
        return {
            item: orderEntry.product.code,
            price: orderEntry.totalPrice.value,
            quantity: orderEntry.quantity,
        };
    }

    private createStartTags(document: Document) {
        const startComment = document.createComment('START EMARSYS WEBEXTEND SCRIPT');

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.classList.add('optanon-category-C0004');
        script.text = `
            var ScarabQueue = ScarabQueue || [];
            (function(subdomain, id) {
              if (document.getElementById(id)) return;
              var js = document.createElement('script'); js.id = id;
              js.src = subdomain + '.scarabresearch.com/js/${this.merchantId}/scarab-v2.js';
              var fs = document.getElementsByTagName('script')[0];
              fs.parentNode.insertBefore(js, fs);
            })('https:' == document.location.protocol ? 'https://recommender' : 'http://cdn', 'scarab-js-api');
            `;

        const endComment = document.createComment('END EMARSYS WEBEXTEND SCRIPT');

        document.head.appendChild(startComment);
        document.head.appendChild(script);
        document.head.appendChild(endComment);
    }

    private arraysAreEqual(arr1: String[], arr2: String[]) {
        if (!arr1 && !arr2) {
            return true;
        } else if (arr1 && arr2) {
            return arr1.every((e) => arr2.includes(e)) && arr2.every((e) => arr1.includes(e));
        } else {
            return false;
        }
    }

    private pushCollectedData() {
        this.pushCollectedCart();
        this.tagEvent();
        if (this.currentCategories?.length > 0) {
            const cats = this.currentCategories
                .sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0))
                .map((c) => c.name);
            if (this.topLevelCategory) {
                cats.push(this.topLevelCategory);
            }
            this.pushToQueue(['category', cats.join('>')]);
        }
        if (this.currentSearchTerm) {
            this.pushToQueue(['searchTerm', this.currentSearchTerm]);
        }
        if (this.currentCategoryPath) {
            this.pushToQueue(['category', this.currentCategoryPath]);
        }
    }

    private pushCollectedCart() {
        if (this.cart?.dayGroupedEntries?.length > 0) {
            const cartProducts = [];
            this.cart.dayGroupedEntries
                .filter((dge) => dge.entries && dge.entries.length > 0)
                .forEach((dge) => {
                    dge.entries.forEach((e) => cartProducts.push(this.createCartEntry(e)));
                });
            this.pushToQueue(['cart', cartProducts]);
        }
    }

    private clearCollectedData() {
        this.currentSearchTerm = undefined;
        this.currentCategories = [];
        this.currentCategoryPath = undefined;
        this.topLevelCategory = undefined;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

export interface CategoryItem {
    code: string;
    name: string;
}

<div
    class="row no-gutters cart-item align-center product_code_{{ entry.product.code }}"
    [ngClass]="{
        'cart-item-compact': printViewWithTable,
    }"
    id="cart_item_{{ entry.entryNumber }}">
    <div
        class="col-10 order-1"
        [ngClass]="{
            'col-lg-5': baseOrder,
            'col-lg-4': !baseOrder,
            noPrint: printViewWithTable,
        }"
        id="product_{{ entry.product.code }}">
        <div class="row no-gutters">
            <div class="col-12 align-center">
                <a
                    class="cx-product-image-container mr-1 cursor-pointer"
                    [ngClass]="{ disabled: isProductRemoved() }"
                    role="button"
                    [id]="'product_image_' + idSuffix"
                    (click)="openProductModal('product_image_' + idSuffix)"
                    tabindex="0">
                    <aimo-product-tag [product]="entry.product" />
                    <cx-media
                        class="cx-product-image"
                        [container]="entry.product?.images?.PRIMARY"
                        format="cartIcon"></cx-media>
                </a>

                <div class="product-name-wrapper">
                    <button
                        [id]="'product_name_' + idSuffix"
                        class="btn-link text-left text-bold"
                        [ngClass]="{ disabled: isProductRemoved() }"
                        [attr.aria-label]="
                            'aimo.product.openModal.arialLabel' | cxTranslate: { productName: entry.product.name }
                        "
                        (click)="openProductModal('product_name_' + idSuffix)"
                        tabindex="0">
                        {{ entry.product.name }}
                    </button>

                    <div class="product-code" [ngClass]="{ 'text-dark-grey': isProductRemoved() }">
                        <aimo-product-code [product]="entry.product"></aimo-product-code>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 onlyPrint">
        <div class="row align-items-center">
            <div
                class="col-5 order-1"
                [ngClass]="{
                    print: printViewWithTable,
                    noPrint: !printViewWithTable,
                }">
                <div class="product-name-wrapper">
                    <div>{{ entry.product.name }}</div>
                    <div class="product-code" [ngClass]="{ 'text-dark-grey': isProductRemoved() }">
                        <aimo-product-code [product]="entry.product"></aimo-product-code>
                    </div>
                </div>
            </div>

            <div
                class="col-1 order-2 text-right"
                [ngClass]="{
                    print: printViewWithTable,
                    noPrint: !printViewWithTable,
                }">
                {{ entry.quantity }} {{ entry.unit.name }}
            </div>

            <div
                class="col-6 order-3"
                [ngClass]="{
                    print: printViewWithTable,
                    noPrint: !printViewWithTable,
                }">
                <div class="row d-flex">
                    <div class="col-2">
                        <div class="date-slot-for-print"></div>
                    </div>
                    <div class="col-2">
                        <div class="date-slot-for-print"></div>
                    </div>
                    <div class="col-2">
                        <div class="date-slot-for-print"></div>
                    </div>
                    <div class="col-2">
                        <div class="date-slot-for-print"></div>
                    </div>
                    <div class="col-2">
                        <div class="date-slot-for-print"></div>
                    </div>
                    <div class="col-2">
                        <div class="date-slot-for-print"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-5 order-3 order-lg-2" [ngClass]="printViewWithTable ? 'noPrint' : ''">
        <div class="row no-gutters" *ngIf="!isProductRemoved()">
            <div class="col-12 col-lg-6 cart-item-data">
                <div>
                    <i
                        *ngIf="entry.product.myAssortment"
                        placement="top"
                        container="body"
                        triggers="mouseenter:mouseleave"
                        [ngbPopover]="'aimo.product.myAssortment' | cxTranslate"
                        class="aimo-icon icon-inline icon-sm icon-house-line-bold"
                        [ngClass]="entry.strikeThroughPrice ? 'icon-red' : 'icon-petrol-blue'"></i>
                    <span
                        class="text-bold d-inline-block mr-1"
                        [ngClass]="
                            entry.strikeThroughPrice ? 'text-red' : entry.product.myAssortment ? 'text-primary' : ''
                        ">
                        {{ entry.basePrice?.formattedValue }}
                    </span>
                    <span class="d-inline-block mr-1"> / {{ entry.unit.name }}</span>
                    <span class="d-inline-block d-lg-none">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                </div>
                <div class="text-strike" *ngIf="entry.strikeThroughPrice">
                    <span class="d-inline-block mr-1">{{ entry.strikeThroughPrice?.formattedValue }}</span>
                    <span class="d-inline-block mr-1"> / {{ entry.unit.name }}</span>
                    <span class="d-inline-block d-lg-none">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                </div>
                <div class="d-block d-lg-none">
                    <div *ngIf="entry.comparisonPrice">
                        <span class="d-inline-block mr-1">{{ entry.comparisonPrice?.formattedValue }}</span>
                        <span class="d-inline-block mr-1"> / {{ entry.comparisonUnit?.name }}</span>
                        <span class="d-inline-block">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                    </div>
                    <div class="text-strike" *ngIf="entry.strikeThroughComparisonPrice">
                        <span class="d-inline-block mr-1">
                            {{ entry.strikeThroughComparisonPrice?.formattedValue }}
                        </span>
                        <span class="d-inline-block mr-1"> / {{ entry.comparisonUnit?.name }}</span>
                        <span class="d-inline-block">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-7 col-lg-6 cart-item-data d-none d-lg-block">
                <div *ngIf="entry.comparisonPrice">
                    <span class="d-inline-block mr-1">{{ entry.comparisonPrice?.formattedValue }}</span>
                    <span class="d-inline-block mr-1"> / {{ entry.comparisonUnit?.name }}</span>
                </div>
                <div class="text-strike" *ngIf="entry.strikeThroughComparisonPrice">
                    <span class="d-inline-block mr-1">{{ entry.strikeThroughComparisonPrice?.formattedValue }}</span>
                    <span class="d-inline-block mr-1"> / {{ entry.comparisonUnit?.name }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-12 order-lg-3 order-5 noPrint" *ngIf="!baseOrder">
        <div class="row no-gutters" *ngIf="!isProductRemoved()">
            <div class="col-6">
                <div class="cx-stock" *ngIf="entry.product.stock?.stockLevelStatus">
                    <aimo-product-availability
                        [stock]="entry.product.stock"
                        *ngIf="!isPikatukku()"></aimo-product-availability>
                    <ng-container *ngIf="isPikatukku()">
                        <div *ngFor="let ptstock of entry.product.pikatukkuStock" class="cx-stock">
                            <aimo-product-availability
                                [suggestiveMode]="true"
                                [printStockName]="true"
                                [stock]="ptstock"></aimo-product-availability>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="col-6 text-right text-lg-left">
                <ng-container *ngIf="entry.product.stock?.closingTime">
                    <i
                        class="aimo-icon icon-sm icon-clock icon-inline"
                        triggers="mouseenter:mouseleave"
                        popoverClass="popover-default"
                        container="body"
                        [ngbPopover]="'aimo.product.closingTime' | cxTranslate"></i>
                    {{ entry.product.stock.closingTime | cxDate: 'HH:mm' }}
                </ng-container>
            </div>
        </div>
    </div>

    <div
        class="col-lg-2 col-7 cart-actions order-lg-5 order-4 pl-3 pl-lg-0 align-items-center"
        [ngClass]="printViewWithTable ? 'noPrint' : ''"
        [formGroup]="addToCartForm">
        <ng-container *ngIf="isProductRemoved() && !baseOrder">
            <span class="web-shop-product">{{ 'aimo.product.removed' | cxTranslate }}</span>
        </ng-container>

        <ng-container>
            <button
                class="btn btn-sm btn-icon btn-icon-transparent d-none noPrint"
                [ngClass]="baseOrder ? 'd-lg-none' : 'd-lg-block'"
                [attr.aria-label]="
                    (baseOrder ? 'aimo.arialLabel.baseorder.remove' : 'aimo.arialLabel.ordertemplate.remove')
                        | cxTranslate
                "
                triggers="mouseenter:mouseleave"
                popoverClass="popover-default"
                container="body"
                [ngbPopover]="
                    (baseOrder ? 'aimo.arialLabel.baseorder.remove' : 'aimo.arialLabel.ordertemplate.remove')
                        | cxTranslate
                "
                *ngIf="entry.deletable"
                (click)="deleteEntry(entry)">
                <i class="aimo-icon icon-trash icon-inline icon-sm icon-petrol-blue noPrint"></i>
            </button>

            <div class="d-flex align-items-center cart-actions-unit" *ngIf="!isProductRemoved()">
                <aimo-item-counter
                    [ngClass]="entry.sizeInBaseUnits !== 1 && entry.sizeInBaseUnits > 0 ? 'has-baseUnits' : ''"
                    class="cart-item-counter noPrint"
                    *ngIf="entry.editable"
                    [min]="entry.product.allowedLotSize <= 0.01 ? 1 : entry.product.allowedLotSize"
                    [id]="'quantity_' + entry.entryNumber"
                    [step]="entry.product.allowedLotSize"
                    [control]="addToCartForm.get('quantity')"
                    [unitName]="entry.unit.name"
                    [cartCounter]="true"
                    [autoAddToCart]="true"
                    [product]="entry.product"
                    [showSpinner]="false"
                    [gtmProductAttributes]="gtmProductAttributes"
                    (afterChange)="changeQuantity()"></aimo-item-counter>
                <span *ngIf="!entry.editable" class="ml-4">{{ entry.quantity }} {{ entry.unit.name }}</span>
                <span class="onlyPrint">{{ entry.quantity }} {{ entry.unit.name }}</span>
            </div>
            <button
                *ngIf="!baseOrder && !isProductRemoved()"
                (click)="addToCart(entry)"
                class="btn btn-sm btn-primary btn-icon btn-add-to-cart ml-2 noPrint"
                type="submit"
                [attr.aria-label]="'addToCart.addToCart' | cxTranslate: { name: entry.product?.name }"
                triggers="mouseenter:mouseleave"
                popoverClass="popover-md"
                container="body"
                [ngbPopover]="'addToCart.addToCart' | cxTranslate: { name: entry.product?.name }">
                <i class="aimo-icon icon-white icon-shopping-cart-simple icon-sm noPrint" #iconCart></i>
            </button>
            <div
                class="active-carts circle circle-sm bg-red text-white text-xsm text-bold ml-2 noPrint"
                *ngIf="alreadyAddedToCart">
                {{ alreadyAddedToCart }}
            </div>
        </ng-container>
    </div>

    <div class="col-lg-2 col-12 order-lg-6 order-5 cart-item-data" *ngIf="baseOrder">
        <div class="row no-gutters">
            <div class="col-10" [ngClass]="!isProductRemoved() ? '' : 'text-right'">
                <ng-container *ngIf="!isProductRemoved()">
                    <div>
                        <span
                            class="text-bold d-inline-block mr-1"
                            [ngClass]="{ 'text-red': entry.strikeThroughPrice }">
                            {{ entry.totalPrice?.formattedValue }}
                        </span>
                        <span class="d-inline-block">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                    </div>
                    <div class="text-lowercase">
                        <span class="d-inline-block mr-1">{{ entry.totalPriceWithTax?.formattedValue }}</span>
                        <span class="d-inline-block">
                            ({{ 'aimo.cart.totals.vat' | cxTranslate: { tax: entry.tax?.rate } }})
                        </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="isProductRemoved() && baseOrder">
                    <div class="mt-2">
                        <span class="web-shop-product">{{ 'aimo.product.removed' | cxTranslate }}</span>
                    </div>
                </ng-container>
            </div>
            <div class="col-2 text-right">
                <button
                    class="btn btn-sm btn-icon btn-icon-transparent d-none d-lg-inline-block"
                    [attr.aria-label]="
                        (baseOrder ? 'aimo.arialLabel.baseorder.remove' : 'aimo.arialLabel.ordertemplate.remove')
                            | cxTranslate
                    "
                    triggers="mouseenter:mouseleave"
                    popoverClass="popover-default"
                    container="body"
                    [ngbPopover]="
                        (baseOrder ? 'aimo.arialLabel.baseorder.remove' : 'aimo.arialLabel.ordertemplate.remove')
                            | cxTranslate
                    "
                    *ngIf="entry.deletable"
                    (click)="deleteEntry(entry)">
                    <i class="aimo-icon icon-trash icon-inline icon-sm icon-petrol-blue noPrint"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="col-2 d-block d-lg-none order-2 text-right noPrint">
        <button
            class="btn btn-sm btn-icon btn-icon-transparent noPrint"
            [ngClass]="{ 'd-lg-none': baseOrder }"
            [attr.aria-label]="
                (baseOrder ? 'aimo.arialLabel.baseorder.remove' : 'aimo.arialLabel.ordertemplate.remove') | cxTranslate
            "
            *ngIf="entry.deletable"
            (click)="deleteEntry(entry)">
            <i class="aimo-icon icon-trash icon-inline icon-sm icon-petrol-blue noPrint"></i>
        </button>
    </div>

    <aimo-cart-item-errors
        [entry]="entry"
        [showCloseIcon]="true"
        [gtmProductAttributes]="gtmProductAttributes"
        [cartCode]="cart?.code"
        class="col-12 d-block order-10 noPrint">
    </aimo-cart-item-errors>
</div>

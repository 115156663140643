import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedRouterStateSnapshot, Breadcrumb, PageType, ProductSearchPage, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, pluck, switchMap } from 'rxjs/operators';

import { AimoCategory, AimoCategoryHierarchy, AimoFacet } from '../../../../model/product.model';
import { AimoCategoryService } from '../../../../service/category/aimo-category.service';
import { AimoProductFacetService } from '../../../../service/product/aimo-product-facet.service';
import { AimoProductListComponentService } from '../../../../service/product/aimo-product-list-component.service';

@Component({
    selector: 'aimo-category-header',
    templateUrl: './aimo-category-header.component.html',
})
export class AimoCategoryHeaderComponent {
    showAllCategories = false;

    model$: Observable<ProductSearchPage>;
    protected routeState$: Observable<ActivatedRouterStateSnapshot>;

    protected category$: Observable<AimoCategory>;

    protected categoryHierarchy$: Observable<AimoCategoryHierarchy>;

    facets$: Observable<AimoFacet>;

    breadCrumbs$: Observable<Breadcrumb[]>;

    total$: Observable<number | string>;

    constructor(
        protected productFacetService: AimoProductFacetService,
        protected productListComponentService: AimoProductListComponentService,
        protected routing: RoutingService,
        protected categoryService: AimoCategoryService,
        protected activatedRoute: ActivatedRoute,
    ) {
        this.model$ = this.productListComponentService.model$;
        this.routeState$ = this.routing.getRouterState().pipe(pluck('state'));

        this.category$ = this.routeState$.pipe(
            distinctUntilChanged(),
            filter((state) => state.context.type === PageType.CATEGORY_PAGE),
            filter((state) => state.context.id !== null),
            map((state) => state.context.id),
            mergeMap((categoryId) => this.categoryService.getCategoryById(categoryId)),
        );

        this.categoryHierarchy$ = this.routeState$.pipe(
            distinctUntilChanged(),
            filter((state) => state.context.type === PageType.CATEGORY_PAGE),
            filter((state) => state.context.id !== null),
            map((state) => state.context.id),
            mergeMap((categoryId) => this.categoryService.getCategoryHierarchyById(categoryId)),
        );

        this.facets$ = this.productFacetService.facetList$.pipe(
            map((list) => list.facets.find((facet) => (facet as AimoFacet).code === 'category')),
        );

        this.breadCrumbs$ = this.model$.pipe(
            filter((searchResult) => searchResult.products.length > 0),
            map((searchResult) => {
                return searchResult.breadcrumbs;
            }),
        );

        this.total$ = this.model$.pipe(
            switchMap(() =>
                this.activatedRoute.url.pipe(
                    switchMap((url) =>
                        this.facets$.pipe(
                            map((facet) => {
                                if (
                                    url.length > 1 &&
                                    this.productListComponentService.getDummyCategoryParam(url[1].path)
                                ) {
                                    return '';
                                }
                                return this.countCategories(facet);
                            }),
                        ),
                    ),
                ),
            ),
        );
    }

    countCategories(category: AimoFacet): number {
        return category?.values?.map((val) => val.count).reduce((p, c) => p + c);
    }

    isAllCategoriesSelected(category: AimoFacet): boolean {
        return category.values.filter((val) => val.selected).length == 0;
    }

    toggleShowAllCategories(showAllCategories: boolean): void {
        this.showAllCategories = showAllCategories;
    }

    isShowSubCategories(categoryLevel: number, category: AimoFacet): boolean {
        return categoryLevel > 1 && category.values.length > 1;
    }
}

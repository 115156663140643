import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { CurrentProductService } from '@spartacus/storefront';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AimoCart, AimoCartItemComponentOptions } from '../../../model/cart.model';
import { AimoProduct } from '../../../model/product.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGTMProductAttributes } from '../../../service/gtm/aimo-gtm.model';
import { ProductEvent } from '../../../shared/item-counter/aimo-item-counter.component';

@Component({
    selector: 'aimo-cx-add-to-cart',
    templateUrl: './aimo-add-to-cart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoAddToCartComponent implements OnInit, OnDestroy {
    @Input() product: AimoProduct;
    @Input() options: AimoCartItemComponentOptions;
    @Input() gtmProductAttributes?: AimoGTMProductAttributes;

    subscriptions: Subscription = new Subscription();

    maxQuantity: number;
    purchasable: boolean;
    quantityStep: number;
    @Input() showQuantity: boolean;

    addToCartForm: UntypedFormGroup;
    @Input() isPLP = false;
    @Output() showHideStepper = new EventEmitter<boolean>();
    @Output() afterChange = new EventEmitter<number>();
    @Input() addToCartCallBack: BehaviorSubject<number> = new BehaviorSubject(undefined);
    @Input() autofocus: boolean;

    constructor(
        protected currentProductService: CurrentProductService,
        protected cd: ChangeDetectorRef,
        protected activeCartService: AimoActiveCartService,
        protected actions$: Actions,
    ) {
        this.createFormGroup(0);
    }

    ngOnInit(): void {
        this.purchasable = this.product.purchasable;
        this.quantityStep = this.product.allowedLotSize;
        this.maxQuantity = this.product.stock.futureStockLevel
            ? this.product.stock.futureStockLevel
            : this.product.stock.stockLevel;

        this.subscriptions.add(
            this.activeCartService
                .getActive()
                .pipe(filter((cart) => cart.code !== undefined))
                .subscribe((cart) => this.initQuantityForm(cart)),
        );
    }

    private initQuantityForm(cart: AimoCart): void {
        const currentQuantity = cart.dayGroupedEntries
            ?.filter((d) => d.active)
            ?.flatMap((d) => d.entries)
            ?.filter((e) => e.product.code === this.product.code)
            .map((e) => e.quantity)
            .find((e) => e);
        this.createFormGroup(currentQuantity);
        this.cd.detectChanges();
    }

    private createFormGroup(alreadyInCart: number): void {
        this.addToCartForm = new UntypedFormGroup({
            quantity: new UntypedFormControl(alreadyInCart ? alreadyInCart : 0, { updateOn: 'blur' }),
        });
    }

    addToCart(): void {
        this.updateCart();
    }

    updateCart(event$?: ProductEvent): void {
        const quantity = this.addToCartForm.get('quantity')?.value;
        if (!this.product.code) {
            return;
        }

        this.product = { ...this.product } as AimoProduct;
        this.activeCartService.addAimoEntry(this.product.code, quantity, this.gtmProductAttributes);
        if (event$) {
            this.showHideStepper.emit(event$.showHideQuantity);
        }
        this.afterChange.next(quantity);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import {
    GlobalMessageService,
    ProductActions,
    SiteContextActions,
    withdrawOn,
    LoggerService,
    normalizeHttpError,
} from '@spartacus/core';
import { ClearSearch } from '@spartacus/core/src/model/product-search.model';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { openCloseInnerSpinner } from '../../cms-components/shared/utils/spinner/aimo-spinner-utils';
import { AimoUserAccountConnector } from '../../shared/connectors/aimo-user-account.connector';
import { AimoGtmCalendar } from '../gtm/aimo-gtm.action';
import { GTMEventCode } from '../gtm/aimo-gtm.model';

import { AimoActiveCartService } from './aimo-active-cart.service';
import {
    AIMO_ADD_MANY_PRODUCTS,
    AIMO_CART_UPDATE_HEADER,
    AIMO_CART_UPDATE_HEADER_SUCCESS,
    AIMO_EXCEL_IMPORT,
    AIMO_ORDER_TEMPLATE_IMPORT,
    AimoAddManyProducts,
    AimoExcelImport,
    AimoOrderTemplateImport,
    AimoUpdateCartHeader,
    AimoUpdateCartHeaderSuccess,
} from './aimo-cart.action';
import { AimoCartConnector } from './aimo-cart.connector';

@Injectable()
export class AimoCartEffects {
    private contextChange$;

    updateHeader$: Observable<
        | AimoUpdateCartHeaderSuccess
        | CartActions.CartUpdateEntryFail
        | CartActions.LoadCart
        | AimoGtmCalendar
        | ProductActions.ClearProductSearchResult
        | SiteContextActions.CurrencyChange
    >;

    excelImport$: Observable<CartActions.LoadCartSuccess | CartActions.LoadCartFail>;

    orderTemplateImport$: Observable<CartActions.LoadCartSuccess | CartActions.LoadCartFail>;

    addMany$: Observable<CartActions.LoadCartSuccess | CartActions.LoadCartFail>;

    refreshFromHeaderUpdate$: Observable<CartActions.LoadCartSuccess | CartActions.LoadCartFail>;

    constructor(
        private actions$: Actions,
        private aimoCartConnector: AimoCartConnector,
        protected globalMessageService: GlobalMessageService,
        protected activeCartService: AimoActiveCartService,
        protected userAccountConnector: AimoUserAccountConnector,
        protected logger: LoggerService,
    ) {
        this.contextChange$ = this.actions$.pipe(
            ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE),
        );

        this.updateHeader$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_CART_UPDATE_HEADER),
                concatMap((action: AimoUpdateCartHeader) => {
                    const payload = {
                        userId: action.userId,
                        cartId: action.cartId,
                        productCode: '',
                        quantity: 0,
                        entryNumber: '0',
                    };
                    return this.aimoCartConnector
                        .updateHeader(action.userId, action.cartId, action.cart, action.resetExternalItems)
                        .pipe(
                            concatMap((cart) => {
                                const ret: (
                                    | AimoUpdateCartHeaderSuccess
                                    | SiteContextActions.CurrencyChange
                                    | AimoGtmCalendar
                                    | ProductActions.ClearProductSearchResult
                                )[] = [
                                    new AimoUpdateCartHeaderSuccess({
                                        userId: action.userId,
                                        cartId: action.cartId,
                                        cart: cart,
                                    }),
                                ];
                                if (action.cart.requestedDeliveryDate) {
                                    ret.push(
                                        new ProductActions.ClearProductSearchResult({
                                            clearPageResults: true,
                                            clearSearchboxResults: true,
                                        } as ClearSearch),
                                    );
                                    ret.push(
                                        new AimoGtmCalendar(cart, GTMEventCode.ChangeCartDate, action.calendarSource),
                                    );
                                }
                                return ret;
                            }),
                            catchError((error) =>
                                from([
                                    new CartActions.CartUpdateEntryFail({
                                        ...payload,
                                        error: normalizeHttpError(error, logger),
                                    }),
                                    new CartActions.LoadCart({
                                        cartId: payload.cartId,
                                        userId: payload.userId,
                                    }),
                                ]),
                            ),
                        );
                }),
                withdrawOn(this.contextChange$),
            ),
        );

        this.excelImport$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_EXCEL_IMPORT),
                concatMap((action: AimoExcelImport) =>
                    this.aimoCartConnector.excelImport(action.userId, action.cartId, action.data).pipe(
                        map((cart) => {
                            return new CartActions.LoadCartSuccess({
                                userId: action.userId,
                                cartId: action.cartId,
                                cart: cart,
                                extraData: { active: true },
                            });
                        }),
                    ),
                ),
                catchError((error) => {
                    openCloseInnerSpinner(false);
                    return from([
                        new CartActions.LoadCartFail({
                            error: normalizeHttpError(error, logger),
                        } as undefined),
                    ]);
                }),
            ),
        );

        this.orderTemplateImport$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_ORDER_TEMPLATE_IMPORT),
                concatMap((action: AimoOrderTemplateImport) =>
                    this.aimoCartConnector
                        .templateImport(action.userId, action.cartId, action.fromId, action.selectedDate)
                        .pipe(
                            map((cart) => {
                                return new CartActions.LoadCartSuccess({
                                    userId: action.userId,
                                    cartId: action.cartId,
                                    cart: cart,
                                    extraData: { active: true },
                                });
                            }),
                        ),
                ),
                catchError((error) => {
                    openCloseInnerSpinner(false);
                    return from([
                        new CartActions.LoadCartFail({
                            error: normalizeHttpError(error, logger),
                        } as undefined),
                    ]);
                }),
            ),
        );

        this.addMany$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_ADD_MANY_PRODUCTS),
                concatMap((action: AimoAddManyProducts) =>
                    this.aimoCartConnector.addMany(action.userId, action.cartId, action.data).pipe(
                        map((cart) => {
                            return new CartActions.LoadCartSuccess({
                                userId: action.userId,
                                cartId: action.cartId,
                                cart: cart,
                                extraData: { active: true },
                            });
                        }),
                    ),
                ),
                catchError((error) => {
                    openCloseInnerSpinner(false);
                    return from([
                        new CartActions.LoadCartFail({
                            error: normalizeHttpError(error, logger),
                        } as undefined),
                    ]);
                }),
            ),
        );

        this.refreshFromHeaderUpdate$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_CART_UPDATE_HEADER_SUCCESS),
                map((action: AimoUpdateCartHeaderSuccess) => action.payload),
                map((payload) => {
                    this.userAccountConnector.refreshUser$.next();
                    return new CartActions.LoadCartSuccess({
                        userId: payload.userId,
                        cartId: payload.cartId,
                        cart: payload.cart,
                        extraData: { active: true },
                    });
                }),
                catchError((error) => {
                    openCloseInnerSpinner(false);
                    return from([
                        new CartActions.LoadCartFail({
                            error: normalizeHttpError(error, logger),
                        } as undefined),
                    ]);
                }),
            ),
        );
    }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, ProtectedRoutesService, RoutingModule } from '@spartacus/core';
import { AppRoutingModule } from '@spartacus/storefront';

import { AimoProtectedRoutesService } from './aimo-protected-routes.service';
import { aimoRoutingConfig } from './aimo-routing.config';

@NgModule({
    providers: [
        {
            provide: ProtectedRoutesService,
            useClass: AimoProtectedRoutesService,
        },
    ],
    imports: [
        AppRoutingModule,
        RouterModule.forRoot([], {
            anchorScrolling: 'enabled',
        }),
        ConfigModule.withConfig({
            routing: aimoRoutingConfig.routing,
        }),
    ],
})
export class AimoRoutingModule extends RoutingModule {}

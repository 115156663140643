import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoCart } from '../../../model/cart.model';
import { GTMCalendarSource } from '../../../service/gtm/aimo-gtm.model';

import { AimoOrderHistorySwitchDateComponent } from './aimo-switch-cart-date.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        SWITCH_CART_DATE = 'SWITCH_CART_DATE',
    }
}

export const switchDateHistoryLayout: LayoutConfig = {
    launch: {
        SWITCH_CART_DATE: {
            inlineRoot: true,
            component: AimoOrderHistorySwitchDateComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AimoSwitchDateHistoryDialogData {
    cart: AimoCart;
    allowPastDates: boolean;
    orderTemplateCode?: string;
    createClaim?: boolean;
    calendarSource: GTMCalendarSource;
}

import { NgModule } from '@angular/core';

import { AimoFilterPipe } from './aimo-filter-pipe';
import { SafeHtmlPipe, SafeUrlPipe } from './aimo-pipes';

@NgModule({
    imports: [],
    declarations: [SafeHtmlPipe, SafeUrlPipe, AimoFilterPipe],
    exports: [SafeHtmlPipe, SafeUrlPipe, AimoFilterPipe],
    providers: [SafeHtmlPipe, SafeUrlPipe, AimoFilterPipe],
})
export class AimoPipesModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AimoCMSBulletinComponent } from '../../model/cms.model';
import { AimoBulletin } from '../../model/user.model';
import { AimoUserService } from '../../service/user/aimo-user.service';

@Component({
    selector: 'aimo-bulletin-component',
    templateUrl: './aimo-bulletin.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBulletinComponent implements OnInit {
    bulletin$: Observable<AimoBulletin>;
    bulletinId$: Observable<string>;
    breadcrumbs$: Observable<any[]>;

    constructor(
        protected componentData: CmsComponentData<AimoCMSBulletinComponent>,
        protected userService: AimoUserService,
        protected activatedRoute: ActivatedRoute,
        protected cdr: ChangeDetectorRef,
        private lTranslation: TranslationService,
    ) {}

    ngOnInit(): void {
        this.bulletinId$ = this.componentData.data$.pipe(map((d) => d?.code));

        // eslint-disable-next-line
        this.breadcrumbs$ = this.lTranslation.translate('aimo.bulletin.title').pipe(
            filter(Boolean),
            map((translation) => [{ label: translation, link: '/announcements' }]),
        );
        if (
            this.activatedRoute.snapshot.url.length > 0 &&
            this.activatedRoute.snapshot.url[0]?.path === 'announcement'
        ) {
            this.init(this.activatedRoute.snapshot.url[1].path);
        } else {
            this.bulletinId$.forEach((code) => {
                this.init(code);
            });
        }
    }

    init(id: string): void {
        this.bulletin$ = this.userService
            .getActiveBulletinList(undefined, id)
            .pipe(map((l) => l.bulletins.find((b) => b.id === id)));
    }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AuthActions,
    ProductActions,
    ProductSearchConnector,
    SiteContextActions,
    withdrawOn,
    LoggerService,
    normalizeHttpError,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AimoProductSearchPage } from '../../model/product.model';

import {
    AIMO_CLOSE_QUICK_SEARCH,
    AIMO_QUICK_SEARCH_PRODUCTS,
    AIMO_SEARCH_PRODUCTS,
    AimoSearchProducts,
    AimoSearchProductsSuccess,
} from './search/aimo-product-search.action';

@Injectable()
export class AimoProductSearchEffects {
    private contextChangeQuickSearch$;

    private contextChangeSearch$;

    quickSearchProducts$: Observable<AimoSearchProductsSuccess | ProductActions.SearchProductsFail>;

    searchProducts$: Observable<AimoSearchProductsSuccess | ProductActions.SearchProductsFail>;

    constructor(
        protected actions$: Actions,
        protected productSearchConnector: ProductSearchConnector,
        @Inject(DOCUMENT) private document: Document,
        protected router: Router,
        protected logger: LoggerService,
    ) {
        this.contextChangeQuickSearch$ = this.actions$.pipe(
            ofType(
                SiteContextActions.CURRENCY_CHANGE,
                SiteContextActions.LANGUAGE_CHANGE,
                AuthActions.LOGOUT,
                AIMO_CLOSE_QUICK_SEARCH,
            ),
        );

        this.contextChangeSearch$ = this.actions$.pipe(
            ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE, AuthActions.LOGOUT),
        );

        this.quickSearchProducts$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_QUICK_SEARCH_PRODUCTS),
                switchMap((action: AimoSearchProducts) => this.doSearch(action)),
                withdrawOn(this.contextChangeQuickSearch$),
            ),
        );

        this.searchProducts$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_SEARCH_PRODUCTS),
                switchMap((action: AimoSearchProducts) => this.doSearch(action)),
                withdrawOn(this.contextChangeSearch$),
            ),
        );
    }

    private doSearch(
        action: AimoSearchProducts,
    ): Observable<AimoSearchProductsSuccess | ProductActions.SearchProductsFail> {
        return this.productSearchConnector.search(action.payload.queryText, action.payload.searchConfig).pipe(
            withdrawOn(this.actions$.pipe(ofType(AIMO_SEARCH_PRODUCTS))),
            map((data) => {
                if (data.keywordRedirectUrl !== undefined && action.payload.searchConfig?.followRedirects) {
                    this.router
                        .navigate([], {
                            queryParams: { followRedirects: false },
                            queryParamsHandling: 'merge',
                        })
                        .then(() => {
                            if (data.keywordRedirectUrl.startsWith('http')) {
                                this.document.location.href = data.keywordRedirectUrl;
                            } else {
                                const split = data.keywordRedirectUrl.split('?');
                                this.router.navigate([split[0]], {
                                    queryParams:
                                        split.length > 1
                                            ? JSON.parse(
                                                  '{"' +
                                                      decodeURI(split[1]).replace(/&/g, '","').replace(/=/g, '":"') +
                                                      '"}',
                                              )
                                            : {},
                                });
                            }
                        });
                }
                return new AimoSearchProductsSuccess(
                    {
                        ...data,
                    } as AimoProductSearchPage,
                    action.auxiliary,
                    action.origin,
                );
            }),
            catchError((error) =>
                of(new ProductActions.SearchProductsFail(normalizeHttpError(error, this.logger), action.auxiliary)),
            ),
        );
    }
}

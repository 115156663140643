import { Injectable } from '@angular/core';
import { CartEntryConnector } from '@spartacus/cart/base/core';
import { CartModification } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AimoBaseOrder,
    AimoBaseOrderList,
    AimoCart,
    AimoCartList,
    AimoOrderEntry,
    AimoRouteCalendar,
} from '../../model/cart.model';
import { FileExportWrapper } from '../../model/misc.model';
import { AimoOrderHistoryRequest } from '../../model/order.model';
import { KlevuParams } from '../gtm/aimo-gtm.model';
import { AimoRecentProductsService } from '../product/aimo-recent-proucts.service';

import { AimoOccCartEntryAdapter } from './aimo-occ-cart-entry.adapter';

@Injectable({
    providedIn: 'root',
})
export class AimoCartConnector extends CartEntryConnector {
    constructor(
        protected cartEntryAdapter: AimoOccCartEntryAdapter,
        protected recentProductsService: AimoRecentProductsService,
    ) {
        super(cartEntryAdapter);
    }

    getRouteCalendar(userId: string, cartId: string, claimsMode?: boolean): Observable<AimoRouteCalendar> {
        return this.cartEntryAdapter.getRouteCalendar(userId, cartId, claimsMode);
    }

    getDeliveryDate(userId: string, cartId: string): Observable<string> {
        return this.cartEntryAdapter.getDeliveryDate(userId, cartId);
    }

    updateHeader(userId: string, cartId: string, cart: AimoCart, resetExternalItems: boolean): Observable<AimoCart> {
        return this.cartEntryAdapter.updateHeader(userId, cartId, cart, resetExternalItems);
    }

    addAimoEntries(
        userId: string,
        cartId: string,
        entries: AimoOrderEntry[],
        categoryCode: string,
        searchTerm: string,
        klevuParams: KlevuParams,
    ): Observable<CartModification[]> {
        this.recentProductsService.add(entries.map((e) => e.product.code));
        return this.cartEntryAdapter.addAimoEntries(userId, cartId, entries, categoryCode, searchTerm, klevuParams);
    }

    updateAimo(
        userId: string,
        cartId: string,
        entry: AimoOrderEntry,
        searchTerm: string,
    ): Observable<CartModification> {
        this.recentProductsService.add([entry.product.code]);
        return this.cartEntryAdapter.updateAimo(userId, cartId, entry, searchTerm);
    }

    public exportCartToExcel(userId: string, cartId: string, historyDay: string): Observable<FileExportWrapper> {
        return this.cartEntryAdapter
            .exportCartToExcel(userId, cartId, historyDay)
            .pipe(map((buffer) => ({ name: cartId + '.xlsx', data: buffer } as FileExportWrapper)));
    }

    excelImport(userId: string, cartId: string, data: string): Observable<AimoCart> {
        return this.cartEntryAdapter.excelImport(userId, cartId, data);
    }

    addMany(userId: string, cartId: string, data: AimoCart): Observable<AimoCart> {
        return this.cartEntryAdapter.addMany(userId, cartId, data);
    }

    getOrderTemplates(
        userId: string,
        visibility: string,
        search: string,
        onlyEditable: boolean,
    ): Observable<AimoCartList> {
        return this.cartEntryAdapter.getOrderTemplates(userId, visibility, search, onlyEditable);
    }

    getOrderTemplate(userId: string, code: string, requestedDeliveryDate: string): Observable<AimoCart> {
        return this.cartEntryAdapter.getOrderTemplate(userId, code, requestedDeliveryDate);
    }

    createOrderTemplate(
        userId: string,
        visibility: string,
        name: string,
        oldCartId?: string,
        orderHistoryDay?: string,
    ): Observable<AimoCart> {
        return this.cartEntryAdapter.createOrderTemplate(userId, visibility, name, oldCartId, orderHistoryDay);
    }

    templateImport(userId: string, cartId: string, fromId: string, selectedDate?: string): Observable<AimoCart> {
        return this.cartEntryAdapter.templateImport(userId, cartId, fromId, selectedDate);
    }

    createClaim(
        userId: string,
        date: string,
        remark: string,
        contactType: string,
        entries: AimoOrderEntry[],
    ): Observable<AimoCart> {
        return this.cartEntryAdapter.createClaim(userId, date, remark, contactType, entries);
    }

    getClaims(userId: string, startDate?: string, endDate?: string, detailsView?: boolean): Observable<AimoCartList> {
        return this.cartEntryAdapter.getClaims(userId, startDate, endDate, detailsView);
    }

    getPurchaseReport(userId: string, params: AimoOrderHistoryRequest): Observable<AimoCart> {
        return this.cartEntryAdapter.getPurchaseReport(userId, params);
    }

    getPurchaseReportExcel(
        name: string,
        userId: string,
        params: AimoOrderHistoryRequest,
    ): Observable<FileExportWrapper> {
        return this.cartEntryAdapter
            .getPurchaseReportExcel(userId, params)
            .pipe(map((buffer) => ({ name: name + '.xlsx', data: buffer } as FileExportWrapper)));
    }

    downloadOrderDocument(
        userId: string,
        invoiceId: string,
        fileName: string,
        deliveryNote: boolean,
    ): Observable<FileExportWrapper> {
        return this.cartEntryAdapter
            .downloadOrderDocument(userId, invoiceId, deliveryNote)
            .pipe(map((buffer) => ({ name: fileName + '.pdf', data: buffer } as FileExportWrapper)));
    }

    approveBaseOrder(userId: string, baseOrderId: string, approve: boolean): Observable<void> {
        return this.cartEntryAdapter.approveBaseOrder(userId, baseOrderId, approve);
    }

    saveBaseOrder(userId: string, baseOrder: AimoBaseOrder, blockAdded: boolean): Observable<AimoBaseOrder> {
        return this.cartEntryAdapter.saveBaseOrder(userId, baseOrder, blockAdded);
    }

    getBaseOrders(userId: string, search: string): Observable<AimoBaseOrderList> {
        return this.cartEntryAdapter.getBaseOrders(userId, search);
    }

    getBaseOrder(userId: string, code: string, requestedDeliveryDate: string): Observable<AimoBaseOrder> {
        return this.cartEntryAdapter.getBaseOrder(userId, code, requestedDeliveryDate);
    }
}

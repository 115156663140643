import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CmsConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';

import { AimoPipesModule } from '../utils/safe-html/aimo-pipes.module';

import { AimoBannerComponent } from './aimo-banner.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        I18nModule,
        MediaModule,
        GenericLinkModule,
        YouTubePlayerModule,
        AimoPipesModule,
    ],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                AimoMainBannerComponent: {
                    component: AimoBannerComponent,
                },
            },
        }),
    ],
    declarations: [AimoBannerComponent],
    exports: [AimoBannerComponent],
})
export class AimoBannerModule {}

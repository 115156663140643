import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AimoClassification, AimoFeature, AimoFeatureValue, AimoProduct } from '../../../model/product.model';
import { GTMItemListId, AimoGTMProductAttributes, GTMCartType } from '../../../service/gtm/aimo-gtm.model';
import { AimoProductService } from '../../../service/product/aimo-product.service';
import { AimoProductComparisonService } from '../../../service/user/aimo-product-comparison.service';

@Component({
    selector: 'aimo-product-comparison',
    templateUrl: './aimo-product-comparison.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductComparisonComponent implements OnInit {
    MODAL_CALLER = LAUNCH_CALLER.PRODUCT_COMPARISON;

    allProducts$: Observable<AimoProduct[]>;
    products$: Observable<AimoProduct[]>;
    private carouselProducts: Observable<AimoProduct>[];
    private attributeTable: UiAttribute[];

    @ViewChild('dataTable') dataTable: ElementRef;

    constructor(
        protected productComparisonService: AimoProductComparisonService,
        protected productService: AimoProductService,
        protected launchDialogService: LaunchDialogService,
        protected cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.allProducts$ = this.productComparisonService
            .getCompareProducts(0, 9999)
            .pipe(tap(() => (this.carouselProducts = undefined)));
    }

    getCarouselProducts(products: AimoProduct[]): Observable<AimoProduct>[] {
        if (!this.carouselProducts) {
            this.carouselProducts = products.map((p) => of(p));
        }
        return this.carouselProducts;
    }

    getClassificationClass(product: AimoProduct, code: string): AimoClassification {
        return this.productService.getClassificationClass(product, code);
    }

    getNutritionalContent(product: AimoProduct, code: string): AimoFeature {
        return this.productService.getNutritionalContent(product, code);
    }

    getClassificationNameList(product: AimoProduct, code: string): string {
        return this.productService.getClassificationNameList(product, code);
    }

    getUiAttributes(products: AimoProduct[]): UiAttribute[] {
        if (!this.attributeTable) {
            this.attributeTable = [];
            products.forEach((product) => {
                this.populateUiAttribute(product, this.attributeTable, 'nutritionFact', 0);
                this.populateUiAttribute(product, this.attributeTable, 'vitamin', 1000);
                this.populateUiAttribute(product, this.attributeTable, 'mineral', 10000);
            });
            this.attributeTable = this.attributeTable.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1));
        }
        return this.attributeTable;
    }

    private populateUiAttribute(product: AimoProduct, attrs: UiAttribute[], className: string, sortKey: number): void {
        let ener: UiAttributeValue = undefined;
        this.getClassificationClass(product, className)?.features.forEach((feat) => {
            if (ener && feat.code.endsWith('ener-kjo')) {
                feat.featureValues.forEach((fv) => ener.values.push(fv));
            } else {
                let uiAttribute = attrs.find((attr) => attr.code === feat.code);
                if (!uiAttribute) {
                    uiAttribute = new UiAttribute(feat.name, feat.code, sortKey + feat.position, []);
                    attrs.push(uiAttribute);
                }
                const attrValue = new UiAttributeValue(product, feat.featureValues);
                uiAttribute.values.push(attrValue);
                if (feat.code.endsWith('ener-e14')) {
                    ener = attrValue;
                }
            }
        });
    }

    closeDialog(): void {
        this.launchDialogService.closeDialog('closed');
    }

    getValue(uiAttribute: UiAttribute, product: AimoProduct): string {
        return uiAttribute.values
            .filter((val) => val.product.code === product.code)
            .flatMap((val) =>
                val.values.map((value) => {
                    let val = value.value;
                    if (uiAttribute.code.toUpperCase().indexOf('ENER-') >= 0) {
                        val = val.replace(/\..*/, '');
                    }
                    return val + ' ' + value.unit?.name;
                }),
            )
            .join(' / ');
    }

    // eslint-disable-next-line
    getPropery(property: any): string {
        if (Array.isArray(property)) {
            return property.map((p) => p.name).join(', ');
        }
        if (typeof property === 'object') {
            return property.name;
        }
        return property;
    }

    openCloseInfo(iconNutritional: HTMLElement, nutritionalDivContent: HTMLDivElement): void {
        iconNutritional.classList.toggle('icon-rotate-180');
        nutritionalDivContent.classList.toggle('d-none');
    }

    scrollChange(scrollLeft: number, addValue?: boolean): void {
        const comparisonsData = this.dataTable.nativeElement.querySelectorAll('.comparison-data');
        comparisonsData.forEach((data) => {
            if (addValue) {
                data.scrollLeft += scrollLeft;
            } else {
                data.scrollLeft = scrollLeft;
            }
        });
    }

    createGtmAttributes(idx: number): AimoGTMProductAttributes {
        return {
            index: idx,
            item_list_id: GTMItemListId.product_comparison,
            cart_type: GTMCartType.cart,
        } as AimoGTMProductAttributes;
    }
}

class UiAttribute {
    constructor(public title: string, public code: string, public sortKey: number, public values: UiAttributeValue[]) {}
}

class UiAttributeValue {
    constructor(public product: AimoProduct, public values: AimoFeatureValue[]) {}
}

import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CmsSearchBoxComponent, ProductActions, RoutingService, WindowRef } from '@spartacus/core';
import { CmsComponentData, ICON_TYPE, SearchBoxComponent } from '@spartacus/storefront';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';
import { AimoHamburgerMenuService } from '../../../layout/hamburger-menu/aimo-hamburger-menu.service';
import { AimoCart } from '../../../model/cart.model';
import { AimoSearchBoxConfig } from '../../../model/product.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { GTMCartType, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoSearchBoxComponentService } from '../../../service/product/search/aimo-search-box-component.service';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { AimoSpinnerService } from '../../shared/spinner/aimo-spinner.service';

import { ScrollEvent } from './aimo-search-box-result.component';

const MAX_PRODUCTS = 20;

@Component({
    selector: 'aimo-cx-searchbox',
    templateUrl: './aimo-search-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoSearchBoxComponent extends SearchBoxComponent implements OnInit {
    iconTypes = ICON_TYPE;

    @Input()
    cart: AimoCart;

    currentQuery: string;
    @Input() isCartSearchBox = false;
    @Input() cartId = undefined;
    @Input() baseOrder = false;
    @Input() orderTemplate = false;
    @Input() cartSearch = false;

    @ViewChild('spinnerDiv') spinnerDiv: ElementRef;
    @HostBinding('class.opened') opened: boolean = false;
    isMobileUi = false;

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected aimoUserService: AimoUserService,
        protected searchBoxComponentService: AimoSearchBoxComponentService,
        protected componentData: CmsComponentData<CmsSearchBoxComponent>,
        protected routingService: RoutingService,
        protected eRef: ElementRef,
        protected spinnerService: AimoSpinnerService,
        protected cdr: ChangeDetectorRef,
        protected actions$: Actions,
        protected aimoRoutingService: AimoRoutingService,
        protected hamburgerMenuService: AimoHamburgerMenuService,
        protected renderer: Renderer2,
        protected winRef: WindowRef,
        @Inject(DOCUMENT) protected document: Document,
    ) {
        componentData = {
            ...componentData,
            data$: componentData.data$.pipe(filter((config) => !!config)),
        };
        super(searchBoxComponentService, componentData, winRef, routingService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.isMobileUi = this.document.defaultView.window.matchMedia('only screen and (max-width: 760px)').matches;

        this.subscriptions.add(
            this.routingService
                .getRouterState()
                .pipe(distinctUntilChanged((prev, curr) => prev.state.semanticRoute === curr.state.semanticRoute))
                .subscribe(() => {
                    if (this.searchInput) {
                        this.clear(this.searchInput.nativeElement);
                    }
                }),
        );

        if (this.cart === undefined) {
            this.subscriptions.add(this.activeCartService.getActive().subscribe((cart) => (this.cart = cart)));
        }

        this.subscriptions.add(
            this.actions$.subscribe((action) => {
                if (action.type === ProductActions.SEARCH_PRODUCTS_SUCCESS) {
                    if (this.searchInput) {
                        setTimeout(() => {
                            // this will make the execution after the above boolean has changed
                            const lastFocusedElementId = (this.config as AimoSearchBoxConfig).lastFocusedElementId;
                            if (lastFocusedElementId) {
                                const element = this.document.querySelector(
                                    '#' + lastFocusedElementId,
                                ) as HTMLInputElement;
                                if (element) {
                                    element.click();
                                }
                                this.scrollToInput(element);
                            }
                        }, 50);
                    }
                }
            }),
        );
    }

    searchMinCharacters(query: string): void {
        this.spinnerService.showInnerSpinner(this.spinnerDiv);
        if (query.length >= this.config?.minCharactersBeforeRequest && query.length >= 2) {
            this.config = {
                ...this.config,
                maxProducts: MAX_PRODUCTS,
                page: 0,
                suggestiveMode: true,
                lastFocusedElementId: undefined,
                origin: this.determineOrigin(),
                displaySuggestions: false,
            } as AimoSearchBoxConfig;
            this.currentQuery = query;
            this.search(query);
        } else {
            this.searchBoxComponentService.clearResults();
        }
    }

    determineOrigin(): GTMCartType {
        return this.baseOrder
            ? GTMCartType.baseOrder
            : this.orderTemplate
              ? GTMCartType.orderTemplate
              : this.cartSearch
                ? GTMCartType.cart
                : GTMCartType.main;
    }

    determineListId(): GTMItemListId {
        return this.baseOrder || this.orderTemplate
            ? GTMItemListId.order_template
            : this.cartSearch
              ? GTMItemListId.cart
              : GTMItemListId.suggestive_search;
    }

    clearAndClose(event: UIEvent): void {
        if (!this.isMobileUi) {
            //this.searchInput.nativeElement.classList?.add('d-none');  this will break the ux
            this.close(event, true);
            this.clear(this.searchInput.nativeElement);
        } else {
            this.searchInput.nativeElement.value = '';
            this.searchBoxComponentService.clearResults();
        }
    }

    // overridden method to not check query length before firing search
    launchSearchResult(event: UIEvent, query: string): void {
        this.close(event);
        this.searchBoxComponentService.launchSearchPage(query);
    }

    onlyClear(): void {
        this.opened = false;
        this.cdr.detectChanges();
        this.searchInput.nativeElement.value = '';
        this.searchBoxComponentService.clearResults();
        this.searchInput.nativeElement.focus();
    }

    open(): void {
        this.opened = true;
        this.cdr.detectChanges();
        if (this.searchInput) {
            this.searchInput.nativeElement.classList?.remove('d-none');
            super.open();
        }
        this.hamburgerMenuService.toggle(true);
        this.renderer.removeClass(this.document.body, 'menu-open');
    }

    @HostListener('document:click', ['$event'])
    clickOut(event): void {
        if (this.checkParent(event.target)) {
            this.opened = false;
            this.cdr.detectChanges();
            this.close(event);
        }
    }

    checkParent(target: HTMLElement): boolean {
        if (target === null || target === undefined) {
            return true;
        }
        if (target.tagName === 'AIMO-CX-SEARCHBOX' || target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
            return false;
        }
        return this.checkParent(target.parentElement);
    }

    // Focus on previous item in results list
    focusPreviousChild(event: UIEvent): void {
        event.preventDefault(); // Negate normal keyscroll
        const [results, focusedElement] = [this.getResultElements2(), this.getFocusedElement2()];
        // Focus on last index moving to first
        if (results.length) {
            if (this.searchInput?.nativeElement.id === focusedElement.id) {
                results[0].click();
            } else {
                let prevFocusedElement;
                for (let i = results.length - 1; i >= 0; --i) {
                    if (results[i].id === focusedElement.id && i > 0) {
                        prevFocusedElement = this.findPreviousNotReadOnlyElement(results, i - 1);
                        break;
                    }
                }
                if (prevFocusedElement) {
                    prevFocusedElement.click();
                    this.scrollToInput(prevFocusedElement);
                } else {
                    (
                        this.document
                            .querySelector('#' + focusedElement.id)
                            .closest('aimo-cx-searchbox')
                            .querySelector('.searchbox input') as HTMLElement
                    ).focus();
                }
            }
        }
    }

    findPreviousNotReadOnlyElement(results: HTMLInputElement[], index: number): HTMLInputElement {
        for (let i = index; i >= 0; --i) {
            if (!results[i].readOnly) {
                return results[i];
            }
        }
    }

    // Focus on next item in results list
    focusNextChild(event: UIEvent): void {
        const [results, focusedElement] = [this.getResultElements2(), this.getFocusedElement2()];
        // Check if there is any input otherwise let tab continue with next element
        // this prevent tab getting stuck in the input search
        if (results.length > 0) {
            this.open();
            event?.preventDefault(); // Negate normal keyscroll
            // Focus on first index moving to last
            if (this.searchInput?.nativeElement.id === focusedElement.id) {
                results[0].click();
            } else {
                let nextFocusedElement;
                for (let i = 0; i < results.length; ++i) {
                    if (results[i].id === focusedElement.id && i < results.length - 1) {
                        nextFocusedElement = this.findNextNotReadOnlyElement(results, i + 1);
                        break;
                    }
                }
                if (nextFocusedElement) {
                    nextFocusedElement.click();
                    this.scrollToInput(nextFocusedElement);
                }
            }
        }
    }

    findNextNotReadOnlyElement(results: HTMLInputElement[], index: number): HTMLInputElement {
        for (let i = index; i < results.length; ++i) {
            if (!results[i].readOnly) {
                return results[i];
            }
        }
    }

    scrollToInput(element: HTMLElement): void {
        this.config = { ...this.config, lastFocusedElementId: element?.id } as AimoSearchBoxConfig;
        const scrollable: HTMLElement = this.document.querySelector('.content-scroll');
        if (element && scrollable && !this.isScrolledIntoView(element, scrollable)) {
            scrollable.scrollTop =
                this.document.querySelector('#' + element.id).closest('li').offsetTop - scrollable.offsetTop;
        }
    }

    private isScrolledIntoView(el: HTMLElement, scrollable: HTMLElement): boolean {
        const elementRect = el.getBoundingClientRect();
        const scrollableRect = scrollable.getBoundingClientRect();

        const ret = elementRect.top >= scrollableRect.top && elementRect.bottom <= scrollableRect.bottom;
        if (!ret) {
            //   console.log("elemBottom: " + elemBottom + " elemTop: " + elemTop + " scrollable: " + scrollable.getBoundingClientRect().bottom)
        }
        return ret;
    }

    // Return result list as HTMLElement array
    private getResultElements2(): HTMLInputElement[] {
        return Array.from(this.document.querySelectorAll('.products > li .cx-amount .aimo-input'));
    }

    // Return focused element as HTMLElement
    private getFocusedElement2(): HTMLInputElement {
        return <HTMLInputElement>this.document.activeElement;
    }

    onScrollEvent(event: ScrollEvent): void {
        this.config = {
            ...this.config,
            page: event.toPage,
            suggestiveMode: true,
            lastFocusedElementId: event.lastFocusedElementId,
        } as AimoSearchBoxConfig;
        this.search(this.currentQuery);
    }

    showAllResults($event: UIEvent): void {
        this.config = { ...this.config, page: 0, suggestiveMode: false } as AimoSearchBoxConfig;
        this.close($event, true);
        this.launchSearchResult($event, this.searchInput.nativeElement.value);
        this.onlyClear();
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}

import { DOCUMENT } from '@angular/common';
import { Inject, OnDestroy, Injectable } from '@angular/core';
import { LanguageService } from '@spartacus/core';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AimoRoutingService implements OnDestroy {
    subscription: Subscription = new Subscription();
    lang: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        protected languageService: LanguageService,
    ) {
        this.subscription.add(
            this.languageService.getActive().subscribe((lang) => {
                this.lang = lang;
            }),
        );
    }

    isPikatukku(): boolean {
        return this.document.location.host?.indexOf('pikatukku') >= 0;
    }

    gotoPikatukku(): void {
        this.document.location.href =
            this.document.location.origin.replace('toimitustukku', 'pikatukku') + '/' + this.lang + '/';
    }

    gotoToimitustukku(): void {
        this.document.location.href =
            this.document.location.origin.replace('pikatukku', 'toimitustukku') + '/' + this.lang + '/';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}

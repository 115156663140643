import { Cart, CartItemComponentOptions, DeliveryMode, OrderEntry } from '@spartacus/cart/base/root';
import { CartModification } from '@spartacus/cart/base/root/models/cart.model';
import { Address } from '@spartacus/core';
import { Order } from '@spartacus/order/root';

import { GTMItemListId } from '../service/gtm/aimo-gtm.model';

import { AimoPrice, AimoProduct, AimoTemplateFacetValue, AimoUnitOfMeasure, AimoWarehouse } from './product.model';
import { AimoB2BUnitOption, AimoUser, B2BUnitOption } from './user.model';

export interface AimoCartList {
    carts?: AimoCart[];
    facets?: AimoTemplateFacetValue[];
}

export interface AimoCart extends Cart {
    billingAddress?: Address;
    requestedDeliveryDate?: string;
    paymentMode?: PaymentMode;
    deliveryMode?: AimoDeliveryMode;
    deliveryInstructions?: string;
    allowedDeliveryDates?: string[];
    creditLimitExceeded?: boolean;
    creditPrepaymentRequired?: boolean;
    creditBlocked?: boolean;
    salesBlocked?: boolean;
    validForOrdering?: boolean;
    messages?: AimoMessage[];
    exceededCreditAmount?: AimoPrice;
    unit?: B2BUnitOption;
    user?: AimoUser;
    dayGroupedEntries?: AimoDayGroupedEntries[];
    categoryGroupedEntries?: AimoCategoryGroupedEntries[];
    entries?: AimoOrderEntry[];
    taxes?: AimoTax[];
    created?: string;
    modified?: string;
    deposits?: AimoPrice;
    extraCharge?: AimoPrice;
    dailyTotalWithTax?: AimoPrice;
    dailyTotalWithoutTax?: AimoPrice;
    paymentAttributes?: AimoMap;
    paymentUrl?: string;
    statusDisplay?: string;
    exceptionDateSelected?: boolean;
    expressDateSelected?: boolean;
    visibility?: string;
    assignedUnits?: AimoB2BUnitOption[];
    remark?: string;
    contactType?: string;
    pickupSlotId?: string;
    pickupTime?: string;
    allowedPickupSlots?: AimoPickupSlot[];
    editable?: boolean;
}

export interface AimoPickupSlot {
    id: string;
    from: string;
    to: string;
    closingTime: string;
}

export interface AimoBaseOrderList extends AimoCartList {
    carts?: AimoBaseOrder[];
}

export interface AimoBaseOrder extends AimoCart {
    aboutToBeSalesBlocked: boolean;
    oddWeek: boolean;
    weekDays: number[];
    blocks: AimoBaseOrderBlock[];
    unApprovedChanges: boolean;
    firstPossibleDeliveryDate: string;
    deliveryDatesAhead: number;
}

export interface AimoBaseOrderBlock {
    comment?: string;
    start: string;
    end: string;
    sendNotification: boolean;
}

export interface AimoMap {
    entry: AimoMapEntry[];
}

export interface AimoMapEntry {
    key: string;
    value: undefined;
}

export interface AimoTax {
    rate?: number;
    value?: AimoPrice;
}

export interface AimoDayGroupedEntries {
    date?: string;
    entries?: AimoOrderEntry[];
    text?: string;
    active?: boolean;
    alreadyOrdered?: AlreadyOrderedEntries[];
    modifiedEntries?: number;
}

export interface AimoCategoryGroupedEntries {
    entries?: AimoOrderEntry[];
    title: string;
}

export interface AlreadyOrderedEntries {
    purchaseOrderNumber?: string;
    orderNumber?: string;
    entries?: AimoOrderEntry[];
    totalPrice?: AimoPrice;
    priceWithTax?: AimoPrice;
    taxes?: AimoTax[];
}

export interface AimoOrder extends Order {
    paymentMode?: PaymentMode;
    billingAddress?: Address;
    requestedDeliveryDate?: string;
    deliveryMode?: AimoDeliveryMode;
    deliveryInstructions?: string;
    validForOrdering?: boolean;
    paymentAttributes?: undefined;
    paymentUrl?: string;
    remark?: string;
    contactType?: string;
    entries?: AimoOrderEntry[];
    pickupTime?: string;
    cdpOrderCode: string;
}

export interface AimoDeliveryMode extends DeliveryMode {
    pickup?: boolean;
    disabled?: boolean;
}

export interface PaymentMode {
    code: string;
    name?: string;
    credit?: boolean;
}

export interface AimoOrderEntry extends OrderEntry {
    pk?: string;
    product?: AimoProduct;
    deliveryInstructions?: string;
    messages?: AimoMessage[];
    requestedDeliveryDate?: string;
    status?: string;
    taxAmount?: AimoPrice;
    taxPercentage?: number;
    unit?: AimoUnitOfMeasure;
    warehouse?: AimoWarehouse;
    comparisonPrice?: AimoPrice;
    comparisonUnit?: AimoUnitOfMeasure;
    editable?: boolean;
    deletable?: boolean;
    basePriceWithTax?: AimoPrice;
    totalPriceWithTax?: AimoPrice;
    lotSizeInfo?: string;
    lotSize?: number;
    sizeInBaseUnits?: number;
    strikeThroughPrice?: AimoPrice;
    strikeThroughComparisonPrice?: AimoPrice;
    tax?: AimoTax;
    closingStatus?: string;
    closingTime?: Date;
    closingTimeWarningDate?: Date;
    external?: boolean;
    messageToCustomer?: string;
    messageToPickup?: string;
    deposits?: AimoDeposit;
    deliveredQuantity?: number;
    deliveredTotalPrice?: AimoPrice;
    deliveredTotalPriceWithTax?: AimoPrice;
    discount?: AimoPrice;
    invoiceDocumentNumber?: string;
    deliveryDocumentNumber?: string;
    deliveryUnit?: AimoUnitOfMeasure;
    reason?: string;
    dateOrBatch?: string;
    modified?: boolean;
    externalOrderNumber?: string;
    previousQuantity?: number;
    productExists?: boolean;
    gtmItemListId?: GTMItemListId;
}

export interface AimoDeposit {
    totalPriceWithTax?: AimoPrice;
    taxes?: AimoTax[];
    entries?: AimoOrderEntry[];
}

export interface AimoRouteCalendar {
    months?: AimoRouteCalendarMonth[];
    activeMonth?: number;
    activeCarts?: number;
    selectedDate?: string;
    routeWeekDays?: number[];
}

export interface AimoRouteCalendarMonth {
    month?: number;
    year?: number;
    weeks?: AimoRouteCalendarWeek[];
}

export interface AimoRouteCalendarWeek {
    weekNumber?: number;
    days?: AimoRouteCalendarDay[];
}

export interface AimoRouteCalendarDay {
    deliveryDate?: boolean;
    disabled?: boolean;
    today?: boolean;
    selected?: boolean;
    openCart?: boolean;
    incomingOrder?: boolean;
    date?: string;
    dayOfMonth?: number;
}

export interface AimoMessage {
    messageKey: string;
    message: string;
    severity: AimoMessageSeverity;
    action: string;
    actionParams: string[];
    similarProducts: boolean;
    position: string;
}

export interface AimoCartModification extends CartModification {
    addedQuantityPrice: AimoPrice;
    addedQuantityDiscount: AimoPrice;
    adjustedBasePrice: AimoPrice;
}

export enum AimoMessageSeverity {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export enum AimoMessagePosition {
    CART_CONTENT = 'CART_CONTENT',
    CART_FOOTER = 'CART_FOOTER',
    CART_HEADER = 'CART_HEADER',
}

export interface AimoCartItemComponentOptions extends CartItemComponentOptions {
    iconAddToCart?: string;
}

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { RoutingService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AimoCart } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { GTMCalendarSource } from '../../../service/gtm/aimo-gtm.model';
import { DateUtils } from '../../../shared/util/date-utils';
import { AimoSwitchDateHistoryDialogData } from '../../cart-details/switch-date-modal/aimo-switch-cart-date-layout.config';
import { openCloseSpinner, toggleFunctions } from '../../shared/utils/spinner/aimo-spinner-utils';
import {
    AimoOrderTemplateDeleteDialogData,
    AimoOrderTemplateDialogData,
} from '../order-template-modal/aimo-order-template-layout.config';

@Component({
    selector: 'aimo-order-template-details',
    templateUrl: './aimo-order-template-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderTemplateDetailsComponent implements OnInit, OnDestroy {
    subscription = new Subscription();

    @Input()
    orderTemplateId: string;

    orderTemplate$: Observable<AimoCart>;

    cart$: Observable<AimoCart>;

    printViewWithTable: boolean = false;

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected windowRef: WindowRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected launchDialogService: LaunchDialogService,
        protected cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    ngOnInit(): void {
        openCloseSpinner(this.document, true);
        this.loadTemplate();
        this.cart$ = this.activeCartService.getActive();

        this.subscription.add(
            this.launchDialogService.dialogClose.subscribe((next) => {
                // Fetch template on dialog close
                if (next) {
                    this.loadTemplate();
                    this.cdr.detectChanges();
                }
            }),
        );

        this.subscription.add(
            this.actions$.subscribe((action) => {
                if (action.type === CartActions.LOAD_CART_SUCCESS) {
                    const cart = (action as CartActions.LoadCartSuccess).payload.cart as AimoCart;
                    if (cart.code === this.orderTemplateId) {
                        this.initTemplate(of(cart));
                        this.cdr.detectChanges();
                    }
                } else if (action.type === CartActions.DELETE_CART_SUCCESS) {
                    this.routingService.go('ordertemplates');
                }
            }),
        );
    }

    private loadTemplate(): void {
        this.initTemplate(this.activeCartService.getOrderTemplate(this.orderTemplateId));
    }

    private initTemplate(template: Observable<AimoCart>): void {
        this.orderTemplate$ = template.pipe(
            tap(() => openCloseSpinner(this.document, false)),
            map((ot) => ({
                ...ot,
                categoryGroupedEntries: ot.categoryGroupedEntries.map((cge) => ({
                    ...cge,
                    entries: cge.entries.map((e) => ({ ...e })),
                })),
            })),
        );
    }

    convertDate(d: string): Date {
        const dd = DateUtils.convertDate(d);
        return dd;
    }

    copy(): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, {
            oldCartId: this.orderTemplateId,
            createNew: true,
        } as AimoOrderTemplateDialogData);
    }

    edit(orderTemplate: AimoCart, sourceId: string): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, {
            oldCartId: this.orderTemplateId,
            edit: orderTemplate,
            focusIdAfterClose: sourceId,
        } as AimoOrderTemplateDialogData);
    }

    exportCartToExcel(): void {
        this.activeCartService.exportCartToExcel(undefined, this.orderTemplateId);
    }

    print(): void {
        this.printViewWithTable = false;
        setTimeout(() => this.document.defaultView.window.print(), 100); // Small delay to be sure boolean related style classes have changed
    }

    printWithTable(): void {
        this.printViewWithTable = true;
        setTimeout(() => this.document.defaultView.window.print(), 100); // Small delay to be sure boolean related style classes have changed
    }

    delete(template: AimoCart): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE_DELETE, undefined, {
            template: template,
        } as AimoOrderTemplateDeleteDialogData);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    addAllToCart(orderTemplate: AimoCart): void {
        this.launchDialogService.closeDialog('closed');
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SWITCH_CART_DATE, undefined, {
            cart: orderTemplate,
            allowPastDates: false,
            orderTemplateCode: orderTemplate.code,
            calendarSource: GTMCalendarSource.orderTemplate,
        } as AimoSwitchDateHistoryDialogData);
    }

    showMoreFunctions(showHide: HTMLElement): void {
        toggleFunctions(showHide, '.cart-functions', '.btn-link');
    }

    // eslint-disable-next-line
    getBreadcrumbs(orderTemplate: AimoCart, orderTemplatesPage?: string): any[] {
        const breadcrumbs = [];
        breadcrumbs.push({ label: orderTemplatesPage, link: '/ordertemplates' });
        breadcrumbs.push({ label: orderTemplate.name, link: orderTemplate.code });

        return breadcrumbs;
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

import { AimoSavedFacets } from '../../model/product.model';
import { AimoInputComponent } from '../input/aimo-input.component';

@Component({
    selector: 'aimo-cx-input-select',
    templateUrl: './aimo-input-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoInputSelectComponent extends AimoInputComponent implements AfterViewInit {
    @Input() bindLabel: string;
    @Input() bindValue: string;
    @Input() cssClass: string;
    // eslint-disable-next-line
    @Input() items: any[];
    @Input() clearable = false;
    @Input() translateByCode = false;
    @Input() savedFilters = false;
    @Input() showTemplateIcon = false;
    // eslint-disable-next-line
    @Output() afterClick = new EventEmitter<any>();
    // eslint-disable-next-line
    @Input() selectedValue: any;

    @ViewChild('selectElement') selectElement: NgSelectComponent;

    // eslint-disable-next-line
    onClick($event: any, savedFacet: AimoSavedFacets): void {
        $event.stopPropagation();
        this.afterClick.emit(savedFacet);
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            setTimeout(() => {
                this.selectElement.focus();
            }, 100);
        }
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, RoutingService, WindowRef } from '@spartacus/core';
import {
    CurrentProductService,
    LAUNCH_CALLER,
    LaunchDialogService,
    ProductGridItemComponent,
    ProductListItemContext,
    ProductListItemContextSource,
} from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';

import { AimoRoutingService } from '../../../../cms-structure/routing/aimo-routing.service';
import { AimoProduct } from '../../../../model/product.model';
import { AimoUser } from '../../../../model/user.model';
import { AimoGTMProductAttributes } from '../../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../../service/product/aimo-current-product.service';
import { AimoProductComparisonService } from '../../../../service/user/aimo-product-comparison.service';
import { AimoUserService } from '../../../../service/user/aimo-user.service';
import { DateUtils } from '../../../../shared/util/date-utils';
import { AimoOrderTemplateDialogData } from '../../../order-template/order-template-modal/aimo-order-template-layout.config';

@Component({
    selector: 'aimo-product-grid-item',
    templateUrl: './aimo-product-grid-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ProductListItemContextSource,
        {
            provide: ProductListItemContext,
            useExisting: ProductListItemContextSource,
        },
    ],
})
export class AimoProductGridItemComponent extends ProductGridItemComponent {
    @Input() openProductPageFromLink = false;
    @Input() isComparisonProduct = false;
    @Input() gtmProductAttributes?: AimoGTMProductAttributes;
    @Input() openModalAfterClose?: LAUNCH_CALLER;
    @Input() idSuffix: string;
    protected isStepperVisible = new BehaviorSubject(false);

    addToCartCallBack: BehaviorSubject<number> = new BehaviorSubject(undefined);
    loggedUser$: Observable<boolean>;
    user$: Observable<AimoUser>;
    isStepperVisible$ = this.isStepperVisible.asObservable();
    showQuantity = false;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected currentProductService: CurrentProductService,
        protected aimoUserService: AimoUserService,
        protected routingService: RoutingService,
        protected router: Router,
        protected productListItemContextSource: ProductListItemContextSource,
        protected productComparisonService: AimoProductComparisonService,
        protected cdr: ChangeDetectorRef,
        protected aimoRoutingService: AimoRoutingService,
        protected auth: AuthService,
        protected winRef: WindowRef,
    ) {
        super(productListItemContextSource);
        this.user$ = this.aimoUserService.get();
        this.loggedUser$ = this.auth.isUserLoggedIn();
    }

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    toggleFavorite(): void {
        this.aimoUserService.toggleFavorite(this.product);
        this.product = { ...this.product, favorite: !this.product.favorite };
    }

    // eslint-disable-next-line
    openProductModal(event$: any, sourceId: string, scrollToId?: string): void {
        event$?.preventDefault();
        if (this.openProductPageFromLink) {
            this.router.navigate([this.product.url], {});
        } else {
            (this.currentProductService as AimoCurrentProductService).openProductModal(
                this.product.code,
                this.gtmProductAttributes,
                scrollToId,
                this.addToCartCallBack,
                this.openModalAfterClose,
                sourceId,
            );
        }
    }

    removeFromComparisonList(productCode: string): void {
        this.productComparisonService.removeProductFromComparison(productCode);
    }

    // eslint-disable-next-line
    autoCloseId: any;

    showHideStepper(event: boolean): void {
        this.isStepperVisible.next(event);
        setTimeout(() => {
            this.showQuantity = event;
            this.cdr.detectChanges();
            if (this.autoCloseId) {
                clearTimeout(this.autoCloseId);
            }
            this.autoCloseId = setTimeout(() => {
                this.hideAddToCart();
            }, 5000);
        }, 250);
    }

    hideAddToCart(): void {
        if (this.showQuantity) {
            setTimeout(() => {
                this.isStepperVisible.next(false);
                this.showQuantity = false;
                this.cdr.detectChanges();
            }, 1000);
        }
    }

    addToComparison(product: AimoProduct): void {
        this.productComparisonService.addProductToComparison(product.code);
    }

    addToOrderTemplate(sourceId: string): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, {
            addToTemplateProduct: this.product,
            createNew: false,
            focusIdAfterClose: sourceId,
        } as AimoOrderTemplateDialogData);
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    @ViewChild('productId') productId: ElementRef<HTMLElement>;

    doNotOpen($event: MouseEvent): boolean {
        $event.preventDefault();
        $event.stopPropagation();
        return false;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { AnalyticsConfig } from './aimo-environment.objects';

@Injectable({
    providedIn: 'root',
})
export class AimoOccEnvironmentAdapter {
    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
    ) {}

    getAnalyticsConfig(): Observable<AnalyticsConfig> {
        return this.http.get<AnalyticsConfig>(this.occEndpoints.buildUrl('analyticsConfig'));
    }
}

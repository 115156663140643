import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { GlobalMessageComponent, ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoActiveCartService, ClosingTimeMessage } from '../../../service/cart/aimo-active-cart.service';

@Component({
    selector: 'aimo-cx-global-message',
    templateUrl: './aimo-global-message.component.html',
})
export class AimoGlobalMessageComponent extends GlobalMessageComponent {
    iconTypes = ICON_TYPE;

    messageType: typeof GlobalMessageType = GlobalMessageType;

    closingTimeMessage$: Observable<ClosingTimeMessage>;

    constructor(
        protected globalMessageService: GlobalMessageService,
        protected router: Router,
        protected cartService: AimoActiveCartService,
    ) {
        super(globalMessageService);
        this.closingTimeMessage$ = cartService.closingTimeMessage$.asObservable();
    }

    @HostListener('document:click', ['$event'])
    public handleClick(event: Event): void {
        if (event.target instanceof HTMLAnchorElement) {
            const element = event.target as HTMLAnchorElement;
            if (element.className === 'routerlink') {
                event.preventDefault();
                const route = element?.getAttribute('href');
                if (route) {
                    this.router.navigate([`/${route}`]);
                }
            }
        }
    }
}

import { Injectable } from '@angular/core';
import {
    BasePageMetaResolver,
    BreadcrumbMeta,
    isNotUndefined,
    PageLinkService,
    ProductPageMetaResolver,
    RoutingService,
    TranslationService,
} from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AimoRoutingService } from '../../cms-structure/routing/aimo-routing.service';
import { AimoCategory } from '../../model/product.model';

import { AimoProductService } from './aimo-product.service';

@Injectable({
    providedIn: 'root',
})
export class AimoProductPageMetaResolver extends ProductPageMetaResolver {
    constructor(
        protected routingService: RoutingService,
        protected productService: AimoProductService,
        protected translation: TranslationService,
        protected basePageMetaResolver: BasePageMetaResolver,
        protected pageLinkService: PageLinkService,
        protected aimoRoutingService: AimoRoutingService,
    ) {
        super(routingService, productService, translation, basePageMetaResolver, pageLinkService);
        this.product$ = this.routingService.getRouterState().pipe(
            map((state) => state.state.params['productCode']),
            filter((code) => !!code),
            switchMap((code) => this.productService.getAimoProduct(code)),
            filter(isNotUndefined),
        );
    }

    resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
        return combineLatest([this.product$.pipe(), this.translation.translate('common.home')]).pipe(
            map(([product, label]) => {
                const breadcrumbs = [];
                breadcrumbs.push({ label, link: '/' });
                product.categories
                    ?.filter((cat) => !(cat as AimoCategory).dummyCategory)
                    .forEach((cat) => {
                        breadcrumbs.push({
                            label: cat.name || cat.code,
                            link: cat.url,
                        } as BreadcrumbMeta);
                    });
                return breadcrumbs;
            }),
        );
    }

    resolveTitle(): Observable<string> {
        return this.product$.pipe(
            switchMap((product) => {
                let title = product.name;
                return this.translation.translate(
                    'pageMetaResolver.product.title.' + (this.isPikatukku() ? 'pt' : 'tt'),
                    {
                        title: title,
                    },
                );
            }),
        );
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}
